<template>
    <div>
        <!--Modal-->
        <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-40">
            <div
                class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 top-0 left-0"
                style="backdrop-filter: blur(22px); background-color: rgb(115, 115, 115)"
            ></div>

            <div
                class="modal-container bg-white w-11/12 md:max-w-md rounded shadow-lg z-50 overflow-y-auto animate-transform"
                :class="{ flipped: cardFlipped }"
            >
                <!-- Add margin if you want to see some of the overlay behind the modal-->
                <div class="modal-content py-4 text-left px-6 front">
                    <!--Title-->
                    <div class="flex justify-between items-center pb-3">
                        <p class="text-2xl font-bold" v-trans="'log-in'">Inloggen</p>
                    </div>

                    <form
                        @submit.prevent="submitLoginForm"
                        accept-charset="UTF-8"
                        action=""
                        id="login-form"
                        method="post"
                    >
                        <input name="action" type="hidden" value="" />

                        <div class="form-group mb-2 mt-4">
                            <label for="email"
                                ><span v-trans="'email'">Email</span>
                                <asterisk-icon />
                            </label>
                            <input class="form-control" id="email" name="loginName" type="text" v-model="email" />
                        </div>

                        <div class="form-group mb-2 mt-4">
                            <label for="password"
                                ><span v-trans="'password'">Password</span>
                                <asterisk-icon />
                            </label>
                            <input
                                class="form-control"
                                id="password"
                                name="password"
                                type="password"
                                v-model="password"
                            />
                        </div>

                        <p class="alert--danger m-1 p-1 px-3 rounded" v-show="errorMsg">{{ errorMsg }}</p>

                        <div class="flex mt-4">
                            <div class="flex-grow">
                                <a href="#" v-trans="'forgot-password'" class="text-sm underline" @click="flipCard"
                                    >Wachtwoord vergeten?</a
                                ><br />
                                <a
                                    href="#"
                                    v-trans="'login-issues'"
                                    class="text-sm underline"
                                    @click="showCookieInfo = true"
                                    >Problemen bij het inloggen?</a
                                >
                            </div>
                            <div class="text-right">
                                <input
                                    :value="$trans.getTranslation('log-in', 'Inloggen')"
                                    class="btn btn--primary"
                                    type="submit"
                                />
                            </div>
                        </div>

                        <div class="c-preloader" v-if="isLoading"><i></i><i></i><i></i></div>

                        <CookieDeleteInfoPopup v-if="showCookieInfo" @close="showCookieInfo = false" />
                    </form>
                </div>

                <div class="modal-content py-4 text-left px-6 back">
                    <!--Title-->
                    <div class="flex justify-between items-center pb-3">
                        <p class="text-2xl font-bold" v-trans="'reset-password'">Passwoord vergeten</p>
                    </div>
                    <form
                        @submit.prevent="submitResetForm"
                        accept-charset="UTF-8"
                        action=""
                        id="reset-form"
                        method="post"
                    >
                        <input name="action" type="hidden" value="" />

                        <div class="form-group mb-2 mt-4">
                            <label for="email"
                                ><span v-trans="'email'">Email</span>
                                <asterisk-icon />
                            </label>
                            <input class="form-control" id="email2" name="email" type="text" v-model="email" />
                        </div>

                        <p class="alert--danger m-1 p-1 px-3 rounded" v-show="errorMsgReset">{{ errorMsgReset }}</p>
                        <p class="alert--success m-1 p-1 px-3 rounded" v-show="successMsg">{{ successMsg }}</p>

                        <div class="flex mt-4">
                            <div class="w-2/5 flex items-center">
                                <a href="#" v-trans="'back'" @click="flipCardBack">Terug</a>
                            </div>
                            <div class="w-3/5 text-right">
                                <input
                                    :disabled="!utils.isValidEmail(email)"
                                    :value="$trans.getTranslation('reset-password', 'Wachtwoord resetten')"
                                    class="btn btn--primary"
                                    type="submit"
                                />
                            </div>
                        </div>

                        <div class="c-preloader" v-if="isLoading"><i></i><i></i><i></i></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import * as axios from "axios";
// import axiosCookieJarSupport from "axios-cookiejar-support";
import {
    ACTION_SET_CURRENT_COMPANY,
    ACTION_SET_CURRENT_COMPANY_ADMIN,
    ACTION_SET_CURRENT_CONTRACTOR_ADMIN,
    CRAFT_USER_TYPE,
} from "../store/constants";
import AsteriskIcon from "./AsteriskIcon";
import { Utils } from "@/utils/utils";
import CookieDeleteInfoPopup from "@/components/CookieDeleteInfoPopup";
// import axios from "axios";

export default {
    name: "LoginCraftUser",
    components: {
        CookieDeleteInfoPopup,
        AsteriskIcon,
    },
    data() {
        return {
            isLoading: false,
            email: "",
            password: "",
            errorMsg: "",
            cardFlipped: false,
            errorMsgReset: "",
            successMsg: "",
            utils: Utils,
            showCookieInfo: false,
        };
    },
    mounted() {
        this.isLoading = true;

        this.$axios
            .post("logout", {
                withCredentials: true, // If true, send cookie stored in jar
            })
            .then((response) => {
                console.log(response);
                this.isLoading = false;
            })
            .catch((error) => {
                this.errorMsg = error.message;
            })
            .finally(() => {
                //this.isLoading = false;
            });
    },
    methods: {
        submitLoginForm() {
            this.errorMsg = "";

            this.isLoading = true;

            this.$axios
                .post("actions/users/login", {
                    loginName: this.email,
                    password: this.password,
                    // action: 'users/login',
                    withCredentials: true, // If true, send cookie stored in jar
                })
                .then((response) => {
                    console.log("response:", response);
                    console.log("jar:", this.$axios.defaults.jar);
                    if (response.data.errorCode) {
                        this.errorMsg = this.$trans.getTranslation(response.data.errorCode, response.data.error);
                    } else {
                        this.setUserInfo(response);
                    }
                })
                .catch((error) => {
                    console.log("CATCH submitLoginForm", error);
                    if (error?.response?.data?.errorCode) {
                        this.errorMsg = this.$trans.getTranslation(
                            error?.response?.data?.errorCode,
                            error?.response?.data?.message
                        );
                    } else {
                        this.errorMsg = error.message;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        async setUserInfo() {
            // axiosCookieJarSupport(axios);

            // const userIdentity = await axios({
            //     url: `${process.env.VUE_APP_REST_ENDPOINT}/rest/v1/session/user-identity`,
            //     method: "get",
            //     withCredentials: true,
            // });

            const response = await fetch(
                `${process.env.VUE_APP_REST_ENDPOINT}/actions/visit-registration-platform/session/user-identity`,
                {
                    method: "GET",
                    credentials: "include",
                }
            );
            const userIdentity = await response.json();
            console.log("userIdentity:", userIdentity);
            /*{
                "data": {
                    "id": 2,
                    "firstName": "Gert",
                    "lastName": "Stalpaert",
                    "email": "gerts@but.be",
                    "craftUserTypeSlug": "portaladministrator",
                    "company": {
                        "id": 19062,
                        "title": "BUT"
                    }
                },*/

            if (!userIdentity) {
                this.errorMsg = "Error: User identity data not found";
                return;
            }

            if (userIdentity?.error) {
                this.errorMsg = "Error: " + userIdentity.error;
                return;
            }

            if (!userIdentity?.company?.id) {
                this.errorMsg = "Error: User has no linked Company";
                return;
            }

            // console.log("setUserInfo", response);
            const user = {
                id: userIdentity.id,
                firstName: userIdentity.firstName,
                lastName: userIdentity.lastName,
                email: userIdentity.email,
                userType: userIdentity.craftUserTypeSlug || userIdentity.craftUserType[0].slug,
                company: [
                    {
                        id: userIdentity.company.id,
                        title: userIdentity.company.title,
                    },
                ],
            };

            this.$store.commit("setIsLoggedIn", true);

            if (
                user.userType === CRAFT_USER_TYPE.COMPANY_ADMINISTRATOR_SLUG ||
                user.userType === CRAFT_USER_TYPE.PORTAL_ADMINISTRATOR_SLUG ||
                user.userType === CRAFT_USER_TYPE.VIEWER_SLUG
            ) {
                this.$store.dispatch(ACTION_SET_CURRENT_COMPANY_ADMIN, user);
                this.$store.dispatch(ACTION_SET_CURRENT_CONTRACTOR_ADMIN, user);
                this.$store.dispatch(ACTION_SET_CURRENT_COMPANY, user.company[0]);
                this.$router.push({ name: "CompanyAdminCompaniesList" });
            } else if (user.userType === CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG) {
                this.$store.dispatch(ACTION_SET_CURRENT_CONTRACTOR_ADMIN, user);
                this.$store.dispatch(ACTION_SET_CURRENT_COMPANY, user.company[0]);
                this.$router.push({ name: "ContractorAdminContractorHolder" });
            } else if (user.userType === "contractor") {
                this.errorMsg = this.$trans.getTranslation("insufficient rights", "Je hebt onvoldoende rechten.");
                // this.$router.push({name:"onlineContractorProfile"});
            }

            this.$emit("onLoginCompleted", user);
            console.log("Gert: user:", user);
            this.isLoading = false;
            // })
            // .catch((error) => {
            //     console.log(error);
            // })
            // .finally(() => {
            //     this.isLoading = false;
            // });
        },

        flipCard() {
            this.cardFlipped = true;
        },
        flipCardBack() {
            this.cardFlipped = false;
        },
        submitResetForm() {
            this.isLoading = true;

            this.$axios
                .post("/", {
                    action: "users/send-password-reset-email",
                    loginName: this.email,
                })
                .then((response) => {
                    console.log("response:", response);
                    // console.log("jar:", this.$axios.defaults.jar);
                    if (response.data.errorCode) {
                        this.errorMsgReset = this.$trans.getTranslation(response.data.errorCode, response.data.error);
                    } else {
                        this.successMsg = this.$trans.getTranslation(
                            "password-reset-successful",
                            "Wachtwoord reset. Check uw email"
                        );
                        // this.flipCardBack();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.errorMsgReset = this.$trans.getTranslation(
                        error.response.data.error,
                        error.response.data.error
                    );
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style scoped>
.animate-transform {
    transition: all 0.2s cubic-bezier(0.47, 0.87, 0.59, 0.29);
}

.back {
    display: none;
    transform: rotateX(-180deg);
}

.flipped {
    transform: rotateX(180deg);
}

.flipped .back {
    display: block;
}

.flipped .front {
    display: none;
}
</style>
