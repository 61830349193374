<template>
    <div>
        <h2 class="h2 text-primary-normal text-center" v-trans="'registration-successful-title'">
            Registratie succesvol
        </h2>

        <div
            class="flex m-auto p-8"
            style="width: 30rem"
            v-if="$route.params.violation === 'true' || $route.params.violation === true"
        >
            <div class="rounded-full bg-primary-dark w-10 h-6 animation-pulse text-center m-8 mt-12">
                <alert-icon class="text-orange-200" />
            </div>
            <div class="text-lg font-bold mt-8" v-trans="'veiligheidsinstructies-overtreden-meld-je'">
                <span class="uppercase">Belangrijke mededeling:</span> Meld je aan voor meer info!
            </div>
        </div>

        <p class="text-2xl flex-grow text-center" v-trans="'registration-successful-info'" v-if="!isDriver">
            Vergeet niet uit te schrijven bij vertrek.
        </p>
        <p class="text-2xl flex-grow text-center" v-trans="'registration-successful-info-chauffeur'" v-if="isDriver">
            Je wordt automatisch uitgecheckt na de tweede weging.
        </p>
        <button @click="backToLanguageSelect" class="btn btn--primary btn--lg mt-5 mx-auto block" v-trans="'ok'">
            OK
        </button>
    </div>
</template>
<script>
import AlertIcon from "vue-material-design-icons/Alert.vue";

export default {
    name: "RegistrationComplete",
    components: { AlertIcon },
    data() {
        return {
            timeoutRef: 0,
        };
    },
    mounted() {
        this.timeoutRef = setTimeout(this.backToLanguageSelect, 60000);
    },
    methods: {
        backToLanguageSelect() {
            clearTimeout(this.timeoutRef);
            this.$router.push({ name: "onsite-languageSelect" });
        },
    },
    computed: {
        isContractor() {
            return this.$route.params.type === this.$store.getters.getVisitTypeIdForContractor;
        },

        isDriver() {
            return this.$route.params.type === this.$store.getters.getVisitTypeIdForTruckDriver;
        },
    },
};
</script>

<style scoped>
.animation-pulse {
    /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
    transform: scale(0.8);*/
    animation: pulse 3s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1.9);
        box-shadow: 0 0 0 0 rgb(42, 173, 199);
    }

    70% {
        transform: scale(2);
        box-shadow: 0 0 0 15px rgba(42, 173, 199, 0);
    }

    100% {
        transform: scale(1.9);
    }
}
</style>
