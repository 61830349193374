import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";

// TOOLS & COMPONENTS
import ButTranslator from "./but-translator/ButTranslator";
import "vue-material-design-icons/styles.css";
///////////////////////////

// STYLES
import "@/assets/css/tailwind.css";
import { apolloProvider } from "./graphql/apollo";
import { stringify } from "qs";
import * as axios from "axios";
import axiosCookieJarSupport from "axios-cookiejar-support";
import moment from "moment";
///////////////////////////

// LOCALISATION
const translatorOptions = {
    jsonPath: "./i18n/${locale}.json?v3",
    butFallbackLanguage: "nl",
    keyNotFoundShowConsoleWarning: true, //show a console.warning when a key is not found in the json file.
    keyNotFoundShowKeyTooltip: false, // WIP
    keyNotFoundShowAsterix: false, // Shows a ¿? in front of snippets for keys that are not found in the json.
    showLocale: false, //Prepend local code before each translated snippet. Used to know if a snippet has already a v-trans.
};

new ButTranslator(translatorOptions);
///////////////////////////

const tough = require("tough-cookie");
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_REST_ENDPOINT + "/",
    headers: {
        Accept: "application/json",
        // "X-CSRF-Token": "",
        // "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
    jar: new tough.CookieJar(),
    transformRequest: [
        function (data) {
            return stringify(data);
        },
    ],
});
axiosCookieJarSupport(axiosInstance);
axiosInstance.defaults.jar = new tough.CookieJar();

Vue.prototype.$axios = axiosInstance;

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    apolloProvider,
    render: (h) => h(App),
}).$mount("#app");

window.ap = apolloProvider;

moment.updateLocale("nl", {
    monthsShort: "jan_feb_mrt_apr_mei_juni_juli_aug_sep_okt_nov_dec".split("_"),
});

if (process.env.NODE_ENV === "production") {
    console.log = function () {};
    console.info = function () {};
}
