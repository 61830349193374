<template>
    <div>
        <h3 class="h1 text-primary-normal text-center mb-6 pb-3 border-0 border-b-2" v-trans="'check-in-here'">
            Meld je hier aan
        </h3>
        <p class="text-primary-normal text-center mb-6 md:mb-10 h3" v-trans="'language-select'">Kies uw taal</p>

        <language-list />
        <router-link
            :to="{ name: 'onsite-checkInOut' }"
            class="btn btn--primary mx-auto block p-4 px-6"
            tag="button"
            v-if="$store.state.activeLanguage"
            v-trans="'next'"
        >
            Next
        </router-link>
    </div>
</template>
<script>
import LanguageList from "../../components/LanguageList";

export default {
    name: "LanguageSelection",
    components: { LanguageList },
};
</script>
