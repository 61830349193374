<template>
    <div class="mb-6 md:mb-8">
        <div class="flex flex-wrap">
            <div
                class="w-full md:w-1/2"
                :class="{ 'pr-2': idx % 2 === 0, 'pl-2': idx % 2 !== 0 }"
                v-for="(lang, idx) in languages"
                :key="lang.iso"
            >
                <button
                    class="mb-4 py-4 btn btn--secondary block w-full"
                    @click="selectLanguage(lang.iso)"
                    :class="{ 'btn--active': $store.state.activeLanguage === lang.iso }"
                >
                    {{ lang.nativeName }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { ACTION_CHANGE_LANGUAGE } from "../store/constants";

export default {
    name: "LanguageList",
    data() {
        return {
            languages: this.$store.state.config.supportedLanguages,
        };
    },
    methods: {
        selectLanguage(iso) {
            this.$store.dispatch(ACTION_CHANGE_LANGUAGE, iso);
            //this.$router.push({params: {lang: iso}});
        },
    },
};
</script>
