<template>
    <span>
        <!-- <pre>{{ visit.visitType }}</pre> -->
        <span>{{ visit.visitType[0].title }}</span>
        <!--        <span class="text-xs"> {{ visit.id }}</span>-->
        <div v-if="isChauffeur" class="mt-2" style="min-width: 11rem">
            <label>
                <input
                    type="radio"
                    value="loading"
                    v-model="visit.loadingOrUnloading"
                    class="mr-2"
                    :disabled="!isEditable"
                />
                <span>Laden</span>
            </label>
            <label class="ml-6">
                <input
                    type="radio"
                    value="unloading"
                    v-model="visit.loadingOrUnloading"
                    class="mr-2"
                    :disabled="!isEditable"
                />
                <span>Lossen</span> </label
            ><br />
            <div v-if="!isEditable">
                <input type="checkbox" v-model="visit.needsWeighing" class="mr-2" disabled="disabled" />Met weging
            </div>
            <div v-else>
                <button
                    class="btn"
                    :class="{ 'btn--active': visit.needsWeighing }"
                    @click.prevent="visit.needsWeighing = !visit.needsWeighing"
                >
                    <span v-if="visit.needsWeighing">Met weging</span>
                    <span v-else>Stuur naar weegbrug</span>
                </button>
            </div>

            <div v-if="isEditable">
                <div>Bestelnummer:</div>
                <input class="form-control" type="text" v-model="visit.orderNumber" />
                <div class="pt-2">Contactpersoon:</div>
                <input class="form-control" type="text" v-model="visit.internalContactName" />
            </div>
            <div class="mt-2" v-if="!isEditable && visit.orderNumber">Bestelnr: {{ visit.orderNumber }}</div>
            <div class="mt-2" v-if="!isEditable && visit.internalContactName">
                Contact: {{ visit.internalContactName }}
            </div>
        </div>
        <div class="alert alert--info p-2 my-4" v-if="isSaving">
            Saving
            <div class="c-preloader ml-2" style="transform: scale(0.45); z-index: 2; display: inline-block">
                <i></i><i></i><i></i>
            </div>
        </div>
        <div class="alert alert--warning p-2 my-4" v-if="errorMsg">
            {{ errorMsg }}
        </div>
    </span>
</template>
<script>
// import { VISIT_TYPE } from "@/store/constants";
import { SAVE_VISIT_WITH_ID } from "@/graphql/queries/Visit";

export default {
    name: "VisitInfo",
    props: {
        visit: {},
        person: {},
        editable: {},
    },
    data() {
        return {
            errorMsg: "",
            saveIid: -1,
            isSaving: false,
        };
    },
    watch: {
        visit: {
            deep: true,
            handler: function (visit) {
                this.errorMsg = "";
                this.isSaving = true;
                clearTimeout(this.saveIid);
                this.saveIid = setTimeout(() => {
                    this.saveVisit(visit.id);
                }, 1000);
                // }
            },
        },
    },
    computed: {
        isChauffeur() {
            return this.visit.visitType[0].id === this.$store.getters.getVisitTypeIdForTruckDriver;
        },
        isEditable() {
            return !this.$store.getters.isViewer && this.visit.status !== "expired";
        },
    },
    methods: {
        saveVisit(id) {
            const visit = {
                visitId: parseInt(id || this.visit.id),
                // title: this.person.firstName + " " + this.person.lastName,
                person: [parseInt(this.person.id)],
                internalContactName: this.visit.internalContactName,
                visitType: [parseInt(this.visit.visitType[0].id)],
                loadingType: this.visit.loadingOrUnloading,
                needsWeighing: this.visit.needsWeighing,
                orderNumber: this.visit.orderNumber,
                expiryDate: this.visit.expiryDate,
            };

            console.log("Visit to save using deep watch:", visit);

            this.$apollo
                .mutate({
                    mutation: SAVE_VISIT_WITH_ID,
                    variables: visit,
                    //fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                })
                .then((e) => {
                    console.log("saveVisit:", e.data);
                    //this.visit = e.data.upsertVisit;
                })
                .catch((e) => {
                    console.log("e", e);
                    console.log("json e", JSON.stringify(e, null, "  "));
                    if (
                        (e &&
                            e.networkError &&
                            e.networkError.result &&
                            e.networkError.result.error.indexOf("Could not find an entry with id ") > -1) ||
                        (e &&
                            e.graphQLErrors &&
                            e.graphQLErrors.length > 0 &&
                            e.graphQLErrors[0].message.indexOf("Could not find an entry with id ") > -1)
                    ) {
                        console.log("ignore this error: user is logged out, so visit is not found anymore");
                    } else {
                        this.errorMsg = e;
                        console.log("err", e);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isSaving = false;
                    }, 1000);
                });
        },
    },
};
</script>
