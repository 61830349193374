import LanguageSelection from "../views/onsite/LanguageSelection";
import CheckInOut from "../views/onsite/CheckInOut";
import CheckIn from "../views/onsite/CheckIn";
import CheckOut from "../views/onsite/CheckOut";
import RegistrationIncomplete from "../views/onsite/RegistrationIncomplete";
import VisitTypeSelection from "../views/onsite/VisitTypeSelection";
import OnsiteHome from "../views/onsite/OnsiteHome";
import RegistrationComplete from "../views/onsite/RegistrationComplete";
import Settings from "../views/onsite/Settings";
import CheckOutComplete from "../views/onsite/CheckOutComplete";
import QRScan from "../views/onsite/QRScan";

export default [
    {
        path: "/onsite",
        name: "onsite",
        component: () => import(/* webpackChunkName: "onsite" */ "../views/Onsite.vue"),
        redirect: { name: "onsite-home" },
        children: [
            {
                name: "onsite-home",
                path: "/",
                component: OnsiteHome,
            },
            {
                path: "settings",
                name: "onsite-settings",
                component: Settings,
            },
            {
                path: "language-select",
                name: "onsite-languageSelect",
                component: LanguageSelection,
            },
            {
                path: "check-in-out",
                name: "onsite-checkInOut",
                component: CheckInOut,
            },
            {
                path: "visitor-type-selection",
                name: "onsite-visitTypeSelection",
                component: VisitTypeSelection,
            },
            {
                path: "check-in/:type/:guid",
                name: "onsite-checkIn",
                component: CheckIn,
            },
            {
                path: "check-out/:id",
                name: "onsite-checkOut",
                component: CheckOut,
            },
            {
                path: "registration-complete/:type/:violation",
                name: "onsite-registrationComplete",
                component: RegistrationComplete,
            },
            {
                path: "registration-incomplete",
                name: "onsite-registrationIncomplete",
                component: RegistrationIncomplete,
            },
            {
                path: "checkout-complete",
                name: "onsite-checkoutcomplete",
                component: CheckOutComplete,
            },
            {
                path: "scan",
                name: "onsite-scan",
                component: QRScan,
            },
        ],
    },
];
