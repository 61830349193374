<template>
    <div>
        <div class="flex justify-between">
            <h3 class="page-title">
                <template v-if="$store.state.persons.currentCompanyAdmin.id">
                    <router-link :to="{ name: 'onlineAdmin' }" v-trans="'admin'"> Admin </router-link>
                    <span class="mx-2 text-gray-500">•</span>
                </template>
                <router-link :to="{ name: 'onlineContractorAdmin' }">
                    {{ $store.state.companies.currentCompany.title }}
                </router-link>
            </h3>
            <button @click="isMenuOpen = !isMenuOpen" class="page-menu-icon md:hidden">
                <MenuIcon v-if="!isMenuOpen" />
                <CloseIcon v-if="isMenuOpen" />
            </button>
        </div>

        <div class="flex">
            <ul :class="{ 'is-open': isMenuOpen }" class="page-menu absolute md:static w-full md:w-3/12 xl:w-2/12">
                <li>
                    <router-link
                        :to="{ name: 'ContractorAdminContractorHolder' }"
                        class="sidemenu-item"
                        v-trans="'employees'"
                    >
                        Werknemers
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{ name: 'ContractorAdminCompanyProfile' }"
                        class="sidemenu-item"
                        v-trans="'company-info'"
                    >
                        Bedrijfsinformatie
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{ name: 'ContractorAdminCertificates' }"
                        class="sidemenu-item"
                        v-trans="'certificates'"
                    >
                        Certificaten
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'ContractorAdminUsers' }" class="sidemenu-item" v-trans="'admins'">
                        Admins
                    </router-link>
                </li>
                <li v-if="!$store.state.persons.currentCompanyAdmin.id">
                    <router-link
                        :to="{ name: 'ContractorAdminMyProfile' }"
                        class="sidemenu-item"
                        v-trans="'my-profile'"
                    >
                        Mijn profiel
                    </router-link>
                </li>
            </ul>
            <transition mode="out-in" name="fade-in" appear>
                <router-view class="md:ml-6 bg-gray-100 p-6 w-full md:w-9/12 xl:w-10/12 rounded" />
            </transition>
        </div>

        <login-craft-user @onLoginCompleted="onLoginCraftUserCompleted" ref="login" v-if="!isContractorAdminLoggedIn" />
    </div>
</template>

<script>
import MenuIcon from "vue-material-design-icons/Menu.vue";
import LoginCraftUser from "../../components/LoginCraftUser";
import CloseIcon from "vue-material-design-icons/Close.vue";

export default {
    name: "ContractorAdminPortal",
    data() {
        return {
            isMenuOpen: false,
        };
    },
    components: {
        LoginCraftUser,
        MenuIcon,
        CloseIcon,
    },
    watch: {
        $route() {
            this.isMenuOpen = false;
        },
    },
    computed: {
        isContractorAdminLoggedIn() {
            return this.$store.state.persons.currentContractorAdmin.id;
        },
    },
    methods: {
        toggle: function () {
            this.isMenuOpen = !this.isMenuOpen;
        },

        onLoginCraftUserCompleted(user) {
            console.log("onLoginCraftUserCompleted", user);
        },
    },
};
</script>
