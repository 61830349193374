<template>
    <div>
        <form>
            <div class="form-group mb-5">
                <label for="title"><span v-trans="'company-name'">Bedrijfsnaam</span><asterisk-icon /></label>
                <input
                    class="form-control"
                    id="title"
                    ref="title"
                    required
                    type="text"
                    :readonly="isLoading"
                    v-model="company.title"
                />
            </div>

            <div class="form-group mb-5">
                <label for="address" v-trans="'address'">Adres</label>
                <textarea
                    class="form-control"
                    id="address"
                    :readonly="isLoading"
                    v-model="company.address"
                    rows="3"
                    cols="50"
                >
                </textarea>
            </div>

            <div class="form-group mb-5">
                <label for="telephone" v-trans="'telephone'">Telefoon</label>
                <input
                    class="form-control"
                    id="telephone"
                    type="text"
                    :readonly="isLoading"
                    v-model="company.telephone"
                />
            </div>

            <div class="form-group mb-5 form-check" v-if="$store.state.persons.currentCompanyAdmin.id">
                <input
                    class="form-check-input"
                    form="isAccessAllowed"
                    id="isAccessAllowed"
                    type="checkbox"
                    v-model="company.isAccessAllowed"
                />
                <label v-trans="'has-access-info'"
                    >De medewerkers van dit bedrijf hebben toegang tot Aluminimum Duffel</label
                >
            </div>

            <div class="alert alert--warning pt-4" v-if="errorMsg">{{ errorMsg }}</div>

            <div class="mb-4"><asterisk-icon /> <span v-trans="'required-field'">Verplicht veld</span></div>

            <div class="flex justify-between" v-if="!$store.getters.isViewer">
                <button @click="cancel" class="btn" v-show="showCancelButton" v-trans="cancel">Annuleren</button>
                <!--                <button @click="deleteCompany" class="btn" v-show="this.company.id">-->
                <!--                    <span v-trans="'delete'">Wissen</span>-->
                <!--                </button>-->
                <button @click.prevent="saveCompany" class="btn btn--primary btn--icon" type="submit">
                    <span v-trans="'save'">Opslaan</span>
                    <arrow-right-icon />
                </button>
            </div>
        </form>
        <div class="c-preloader mt-4 c-preloader--fixed" v-if="isLoading"><i></i><i></i><i></i></div>
    </div>
</template>

<script>
import { SAVE_COMPANY } from "../graphql/queries/Company";
import ArrowRightIcon from "vue-material-design-icons/ArrowRight.vue";
import { ACTION_SET_CURRENT_COMPANY } from "../store/constants";
import AsteriskIcon from "./AsteriskIcon";
// import axios from "axios";

export default {
    name: "CompanyEdit",
    components: {
        ArrowRightIcon,
        AsteriskIcon,
    },
    props: {
        company: {
            type: Object,
        },
        showCancelButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            errorMsg: "",
        };
    },

    mounted() {
        this.$refs.title.focus();
    },

    methods: {
        async saveCompany() {
            this.errorMsg = "";
            this.isLoading = true;
            await this.$apollo
                .mutate({
                    mutation: SAVE_COMPANY,
                    variables: {
                        id: this.company.id,
                        title: this.company.title,
                        address: this.company.address,
                        telephone: this.company.telephone,
                        isAccessAllowed: this.company.isAccessAllowed,
                        companyTypeIds: [parseInt(this.company.companyType[0].id)],
                    },
                })
                .then((e) => {
                    this.$emit("saveSucceeded", e.data.save_company_company_Entry);
                    this.$store.dispatch(ACTION_SET_CURRENT_COMPANY, this.company);
                })
                .catch((e) => {
                    let err;
                    if (e.networkError && e.networkError.result && e.networkError.result.error) {
                        err = e.networkError.result.error.split("\r\n").join("");
                    } else if (
                        e.networkError &&
                        e.networkError.result &&
                        e.networkError.result.errors &&
                        e.networkError.result.errors[0] &&
                        e.networkError.result.errors[0].message
                    ) {
                        err = e.networkError.result.errors[0].message;
                    } else {
                        err = e.message;
                    }
                    this.errorMsg = this.$trans.getTranslation(err, err);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        cancel() {
            this.$emit("cancel");
        },
        // deleteCompany() {
        //     this.isLoading = true;
        //     axios({
        //         url: `${process.env.VUE_APP_REST_ENDPOINT}/rest/v1/user/delete-user`,
        //         method: "post",
        //         data: {
        //             userId: this.company.id,
        //         },
        //     })
        //         .then((response) => {
        //             this.$emit("saveSucceeded", response);
        //             this.isLoading = false;
        //         })
        //         .catch((reject) => {
        //             this.errorMsg = JSON.stringify(reject);
        //             this.isLoading = false;
        //         });
        // },
    },
};
</script>
