<template>
    <div class="form-wrap">
        <back-button></back-button>

        <div class="inner-form">
            <div class="mb-4 form-group">
                <label class="form-label" for="visit" v-trans="'name-visitor-helper'">
                    Geef de eerste 3 letters van je naam of voornaam in en selecteer jezelf uit de lijst
                </label>
                <vue-suggestion
                    :itemTemplate="dropdownItemTemplate"
                    :items="filteredVisits"
                    :setLabel="setVisitLabel"
                    @onInputChange="visitInputChange"
                    id="visit"
                    ref="search"
                    v-model="visit"
                >
                </vue-suggestion>
            </div>
            <div v-if="registrationNotFound">
                <p v-trans="'registration-not-found'">We kunnen uw gegevens niet terugvinden.</p>
            </div>
            <div class="flex justify-between">
                <button
                    @click="checkoutPerson"
                    class="mobile-button block btn btn--icon p-4"
                    :class="{ 'btn--primary': visit != null, 'btn--secondary opacity-50': visit == null }"
                >
                    <span v-trans="'check-out'">Check out</span>
                    <ArrowRightIcon />
                </button>
                <button @click="checkoutPersonWithBadge" class="mobile-button block btn btn--icon p-4">
                    <span v-trans="'check-out-met-qr-code'">Afmelden met QR-code</span>
                    <q-r-code-icon />
                </button>
            </div>
            <div class="bg-red-400 mt-8 p-4 rounded" v-if="errorMsg">
                {{ errorMsg }}
            </div>
        </div>
    </div>
</template>
<script>
import { CHECKOUT_VISIT, GET_ACTIVE_VISITS } from "../../graphql/queries/Visit";
import VueSuggestion from "../../components/SuggestionBox";
import BackButton from "../../components/BackButton";
import dropdownItemTemplate from "../../components/DropdownItemTemplate";
import ArrowRightIcon from "vue-material-design-icons/ArrowRight.vue";
import QRCodeIcon from "vue-material-design-icons/Qrcode";

export default {
    name: "CheckOut",
    data() {
        return {
            dropdownItemTemplate,
            visitsAreLoading: false,
            visit: null,
            filteredVisits: [],
            personQuery: "",
            errorMsg: "",
        };
    },
    components: {
        ArrowRightIcon,
        VueSuggestion,
        BackButton,
        QRCodeIcon,
    },
    mounted() {
        this.$apollo.queries.visits.refetch();

        setTimeout(() => {
            this.$refs.search.focusField();
            // this.$el.getElementsByClassName("vue-suggestion-input")[0].focus();
        }, 200);
    },
    computed: {
        registrationNotFound() {
            return this.personQuery.length >= 3 && this.filteredVisits.length === 0;
        },
    },
    apollo: {
        visits() {
            return {
                query: GET_ACTIVE_VISITS,
                update(data) {
                    if (data.entries) {
                        if (!isNaN(this.$route.params.id)) {
                            this.visit = data.entries.find((entry) => entry.id === parseInt(this.$route.params.id));
                            // console.log("Gert: check out:", this.visit);
                            this.checkoutPerson();
                        }

                        return data.entries;
                    } else {
                        return [];
                    }
                },
                watchLoading(isLoading) {
                    this.visitsAreLoading = isLoading;
                },
                error(error) {
                    if (error.gqlError !== null) {
                        if (error.message === "GraphQL error: Not authorized") {
                            this.$router.push({ name: "onsite-home" });
                        }
                    }
                },
            };
        },
    },
    methods: {
        // INTERNAL CONTACT SEARCH AUTOCOMPLETE FUNCTIONS
        setVisitLabel(item) {
            if (typeof item === "object" && item.person && item.person.length > 0)
                return item.person[0].firstName + " " + item.person[0].lastName;
            else return item;
        },
        // internalContactItemSelected(item) {
        //     this.internalContactName = item.title;
        // },
        visitInputChange(text) {
            this.personQuery = text;

            // Reset
            if (text.length < 3) {
                this.filteredVisits = [];
                this.visit = null;
                return;
            }
            this.filteredVisits = this.visits.filter((item) => {
                if (item.person.length > 0) {
                    return (
                        item.person[0].firstName.toLowerCase().includes(text.toLowerCase()) ||
                        item.person[0].lastName.toLowerCase().includes(text.toLowerCase())
                    );
                } else {
                    return false;
                }
            });
            if (this.filteredVisits.length === 0) this.visit = null;
        },
        checkoutPerson() {
            if (this.visit == null) return;

            this.visit.manualCheckout = true;
            // this.visit.expiryDate = new Date();
            this.visit.expiryDate = new Date(new Date().getTime() - 1000 * 60); //check out a minute ago, to make sure the logout works right away
            if (this.visit.dateCreated && this.visit.expiryDate < this.visit.dateCreated) {
                //always make sure the post date is later than the expiry
                this.visit.expiryDate = this.visit.dateCreated;
            }

            this.$apollo
                .mutate({
                    mutation: CHECKOUT_VISIT,
                    variables: this.visit,
                })
                .then(
                    (e) => {
                        console.log(e);
                        if (e.data.errors) {
                            console.log("Gert: error:", e.data.errors);
                        } else {
                            this.$router.replace({ name: "onsite-checkoutcomplete" });
                        }
                    },
                    (err) => {
                        console.log("Gert: error:", JSON.stringify(err));
                        if (err.graphQLErrors && err.graphQLErrors.length > 0 && err.graphQLErrors[0].message) {
                            this.errorMsg = err.graphQLErrors[0].message;
                            if (this.errorMsg === 'Post Date must be less than "Expiry Date".') {
                                this.errorMsg = "Je kan pas na 1 minuut terug afmelden. Probeer het later opnieuw.";
                            }
                        } else {
                            this.errorMsg = JSON.stringify(err);
                        }
                    }
                );
        },
        checkoutPersonWithBadge() {
            this.$router.push({ name: "onsite-scan" });
        },
    },
};
</script>
