<template>
    <div>
        <checkbox
            :label="$trans.getTranslation('Toon oudere data', 'Toon oudere data')"
            class="text-sm"
            v-if="
                editable &&
                person.learningTrackResults &&
                filteredLearningTrackResults.length !== person.learningTrackResults.length
            "
        >
            <input type="checkbox" v-model="showAllResults" />
        </checkbox>
        <!--        <pre>-->
        <!--            {{ normalizedLearningTrackResults }}-->
        <!--        </pre>-->
        <ul>
            <li
                :class="getClass(result)"
                :key="result.id"
                :title="getTitle(result)"
                class="inline-block relative m-1 shadow-md rounded-md"
                v-for="result in learningTrackResultsToShow"
            >
                <!--            <pre>{{ result }}</pre>-->
                <!--            <div>slug: {{ result.learningTrack ? result.learningTrack[0].slug : "" }}</div>-->
                <div class="flex gap-2 mx-2" :class="{ 'mr-4': editable }">
                    <!--  icon-->
                    <div class="pill-icons text-lg">
                        <IconContractor v-if="result.learningTrack && result.learningTrack[0].slug === 'contractors'" />
                        <IconVisitor
                            v-if="result.learningTrack && result.learningTrack[0].slug === 'visitors-factory'"
                        />
                    </div>

                    <!-- status text-->
                    <div v-trans="getStatusSlug(result)" class="" v-if="!smallPills">{{ getStatusTitle(result) }}</div>
                    <!-- date text-->
                    <div
                        :contenteditable="isContentEditableItem(result)"
                        :id="'date-' + result.id"
                        class=""
                        :class="{
                            'inline-block first:w-auto px-2': isContentEditableItem(result),
                            'first:w-auto': isContentEditableItem(result),
                        }"
                        @input="(event) => onEditDate(event, result)"
                        v-html="getDate(result, isContentEditableItem(result))"
                        v-show="getDate(result)"
                    ></div>
                    <!--edit actions btns -->
                    <div v-if="isEditableItem(result)" class="actions">
                        <button
                            @click.prevent="saveDate(learningTrackresultToEdit)"
                            class="btn py-0"
                            v-if="learningTrackresultToEdit.learningTrackResultStatus[0].slug === 'passed'"
                        >
                            Opslaan
                        </button>
                        <button
                            @click.prevent="addLearningTrackResult(learningTrackresultToEdit)"
                            class="btn py-0"
                            v-if="learningTrackresultToEdit.learningTrackResultStatus[0].slug === 'expired'"
                        >
                            Geslaagd
                        </button>
                        <button
                            @click.prevent="addLearningTrackResult(learningTrackresultToEdit)"
                            class="btn py-0"
                            v-if="learningTrackresultToEdit.learningTrackResultStatus[0].slug === 'not-attempted'"
                        >
                            Geslaagd
                        </button>
                        <button
                            @click.prevent="deleteTrack(learningTrackresultToEdit)"
                            class="btn py-0"
                            v-if="learningTrackresultToEdit.learningTrackResultStatus[0].slug === 'not-attempted'"
                        >
                            Wissen
                        </button>
                        <button
                            @click.prevent="addLearningTrackResult(learningTrackresultToEdit)"
                            class="btn py-0"
                            v-if="learningTrackresultToEdit.learningTrackResultStatus[0].slug === 'in-progress'"
                        >
                            Geslaagd
                        </button>
                        <button
                            @click.prevent="deleteTrack(learningTrackresultToEdit)"
                            class="btn py-0"
                            v-if="learningTrackresultToEdit.learningTrackResultStatus[0].slug === 'in-progress'"
                        >
                            Wissen
                        </button>
                        <button
                            @click.prevent="addLearningTrackResult(learningTrackresultToEdit)"
                            class="btn py-0"
                            v-if="learningTrackresultToEdit.learningTrackResultStatus[0].slug === 'not-invited'"
                        >
                            Geslaagd
                        </button>
                    </div>

                    <!-- edit icon btn-->
                    <a
                        :class="{ 'edit-badge': isContractorAdmin }"
                        @click.prevent="editClicked(result)"
                        :title="isEditableItem(result) ? 'Annuleren' : 'Aanpassen'"
                        href="#"
                        v-if="editable"
                    >
                        <svg height="20" v-if="isEditableItem(result)" viewBox="0 0 20 20" width="20">
                            <path
                                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                                fill="#6F6F6F"
                            ></path>
                        </svg>
                        <svg height="20" v-else viewBox="0 0 20 20" width="20">
                            <path
                                d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                                fill="#6F6F6F"
                            ></path>
                        </svg>
                    </a>
                </div>
            </li>
            <li v-if="errorMsg">
                <div class="alert alert--warning">{{ errorMsg }}</div>
            </li>
        </ul>
    </div>
</template>
<script>
import { Utils } from "../utils/utils";
import moment from "moment";
import { DELETE_LEARNING_TRACK, SAVE_LEARNING_TRACK } from "../graphql/queries/LearningTrack";
import Checkbox from "@/components/Checkbox";
import { CREATE_LEARNING_TRACK_RESULT, GET_LEARNING_TRACKS_FOR_PERSON_BY_ID } from "../graphql/queries/Person";
import { LEARNING_TRACK_STATUS } from "../store/constants";
import AccountHardHatIcon from "vue-material-design-icons/AccountHardHat";
import BriefcaseAccount from "vue-material-design-icons/BriefcaseAccount";

export default {
    name: "learning-track-pills",
    components: {
        Checkbox,
        IconContractor: AccountHardHatIcon,
        IconVisitor: BriefcaseAccount,
    },
    props: {
        person: {
            type: Object,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        smallPills: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            normalizedLearningTrackResults: [],
            startNormalizing: false,
            filteredLearningTrackResults: [],
            learningTrackresultToEdit: null,
            errorMsg: "",
            newExpiryDate: null,
            showAllResults: false,
        };
    },
    computed: {
        isContractorAdmin() {
            const r =
                this.$store &&
                this.$store.state.persons.currentContractor &&
                this.$store.state.persons.currentContractor.id;
            // console.log("isContractorAdmin", r);
            return r;
        },
        learningTrackResultsToShow() {
            return this.showAllResults ? this.normalizedLearningTrackResults : this.filteredLearningTrackResults;
        },
    },

    created() {
        if (this.person.learningTrackResults) {
            this.normalize(this.person.learningTrackResults);
        }
    },

    beforeUpdate() {
        this.normalize(this.person.learningTrackResults);
    },

    watch: {
        showAllResults() {
            this.normalize(this.person.learningTrackResults);
        },
        "person.learningTrackResults": {
            handler(to) {
                if (this.startNormalizing) {
                    this.normalize(to);
                }
            },
            deep: true,
        },
    },

    methods: {
        normalize(origLearningtrackResults) {
            // console.log("normalize");
            this.startNormalizing = true;
            this.normalizedLearningTrackResults = this.normalizeLearningTrackResults(origLearningtrackResults);
            this.filteredLearningTrackResults = this.filterLearningTrackResults();
            this.startNormalizing = false;
        },

        normalizeLearningTrackResults(origLearningtrackResults) {
            // console.log("normalizeLearningTrackResults", origLearningtrackResults);
            let learningTrackResults = [];

            //make copy
            origLearningtrackResults = JSON.parse(JSON.stringify(origLearningtrackResults));

            // const sortedLearningTrackResults = origLearningtrackResults.sort((a, b) => {
            //     const dateA = a.dateUpdated;
            //     const dateB = b.dateUpdated;
            //     return dateA - dateB;
            // });

            if (origLearningtrackResults && origLearningtrackResults.length > 0) {
                learningTrackResults = this.setExpiredSlugToExpiredLearningTrackResults(origLearningtrackResults);
            } else {
                learningTrackResults = [];
            }

            return learningTrackResults;
        },

        filterLearningTrackResults() {
            // console.log("filterLearningTrackResults:", this.normalizedLearningTrackResults);

            if (!this.normalizedLearningTrackResults) {
                return [];
            }

            // Grouping the array by learningTrack[0].slug
            const groupedResults = this.normalizedLearningTrackResults.reduce((groups, result) => {
                const learningTrackSlug = result.learningTrack[0].slug;
                if (!groups[learningTrackSlug]) {
                    groups[learningTrackSlug] = [];
                }
                groups[learningTrackSlug].push(result);
                return groups;
            }, {});

            // Filtering out duplicates based on learningTrackResultStatus[0].slug, keeping only one record per slug
            const r = Object.keys(groupedResults).reduce((filtered, slug) => {
                const group = groupedResults[slug];
                let hasPassed = false; // Flag to check if "passed" slug is available
                const uniqueSlugs = new Set();

                // First pass: check for presence of "passed" slug
                for (const result of group) {
                    const statusSlug = result.learningTrackResultStatus[0].slug;
                    if (statusSlug === "passed") {
                        hasPassed = true;
                        break;
                    }
                }

                // Second pass: filter based on "passed" slug
                for (const result of group) {
                    const statusSlug = result.learningTrackResultStatus[0].slug;
                    if (statusSlug === "expired" && hasPassed) {
                        // Skip adding "expired" slugs if "passed" slug is available
                        continue;
                    }
                    if (!uniqueSlugs.has(statusSlug)) {
                        filtered.push(result);
                        uniqueSlugs.add(statusSlug);
                    }
                }

                return filtered;
            }, []);

            return r;
        },

        setExpiredSlugToExpiredLearningTrackResults(learningTrackResults) {
            learningTrackResults.forEach((result) => {
                if (result.status === "expired") {
                    result.learningTrackResultStatus = [
                        {
                            slug: "expired",
                            title: "Expired",
                        },
                    ];
                }
            });
            return learningTrackResults;
        },

        getClass(result) {
            if (result.learningTrackResultStatus && result.learningTrackResultStatus.length > 0) {
                return "lt-status status-" + result.learningTrackResultStatus[0].slug.split(" ").join("-");
            } else {
                return "";
            }
        },
        getStatusTitle(result) {
            if (result.learningTrackResultStatus && result.learningTrackResultStatus.length > 0) {
                return result.learningTrackResultStatus[0].title;
            } else {
                return "";
            }
        },
        getStatusSlug(result) {
            if (result.learningTrackResultStatus && result.learningTrackResultStatus.length > 0) {
                return result.learningTrackResultStatus[0].slug;
            } else {
                return "";
            }
        },

        getDate(learningTrackResult, isEditable) {
            // console.log("Gert: learningTrackResult:", learningTrackResult, learningTrackResult.expiryDate > 0);
            if (learningTrackResult.expiryDate) {
                if (isEditable) {
                    return moment(learningTrackResult.expiryDate).format("DD/MM/YYYY");
                } else {
                    return Utils.toLocaleDateString(learningTrackResult.expiryDate);
                }
            } else {
                if (learningTrackResult.dateUpdated) {
                    if (isEditable) {
                        return moment(learningTrackResult.dateUpdated).format("DD/MM/YYYY");
                    } else {
                        return Utils.toLocaleDateString(learningTrackResult.dateUpdated);
                    }
                } else {
                    return "";
                }
            }
        },

        getTitle(learningTrackResult) {
            // console.log("getTitle", learningTrackResult?.learningTrack[0].title);
            const title = learningTrackResult?.learningTrack[0].title;
            const s = this.$trans.getTranslation(this.getStatusSlug(learningTrackResult));
            const date = this.getDate(learningTrackResult, true) || "";
            return title + ": " + s + " " + date;
        },

        editClicked(learningTrackResult) {
            if (this.learningTrackresultToEdit && this.learningTrackresultToEdit.id === learningTrackResult.id) {
                this.learningTrackresultToEdit = null;
            } else {
                this.learningTrackresultToEdit = learningTrackResult;
                setTimeout(() => {
                    document.getElementById("date-" + learningTrackResult.id).focus();
                }, 0);
            }
        },

        isEditableItem(result) {
            const r = this.learningTrackresultToEdit && this.learningTrackresultToEdit.id === result.id;
            // console.log("isEditableItem", r, this.learningTrackresultToEdit, result);
            return r;
        },
        isContentEditableItem(result) {
            return (
                this.learningTrackresultToEdit &&
                this.learningTrackresultToEdit.id === result.id &&
                result.learningTrackResultStatus[0].slug === "passed"
            );
        },

        onEditDate(e, result) {
            let newDate = moment(e.target.innerText, "DD/MM/YYYY");
            this.newExpiryDate = result.expiryDate;
            console.log("newDate", newDate, newDate.toDate());
            if (!isNaN(newDate.toDate())) {
                this.newExpiryDate = newDate.toDate().toISOString();
            }
        },

        deleteLearningTrackWithId(id) {
            id = parseInt(id);
            return this.$apollo
                .mutate({
                    mutation: DELETE_LEARNING_TRACK,
                    variables: {
                        id,
                    },
                })
                .then(
                    (data) => {
                        return data;
                    },
                    (error) => {
                        this.showErrorMessage(error);
                        return error;
                    }
                );
        },

        saveDate(result) {
            this.errorMsg = "";
            this.isLoading = true;
            let postDate = null;
            console.log("result", result);
            //post date should be older than expiry date
            if (this.newExpiryDate < new Date()) {
                postDate = this.newExpiryDate;
            }

            this.$apollo
                .mutate({
                    mutation: SAVE_LEARNING_TRACK,
                    variables: {
                        id: parseInt(result.id),
                        expiryDate: this.newExpiryDate,
                        postDate,
                    },
                })
                .then(
                    (data) => {
                        //update normalized data for reactivity update
                        this.person.learningTrackResults.find((i) => i.id === result.id).expiryDate =
                            data.data.save_learningTrackResult_learningTrackResult_Entry.expiryDate;
                        this.normalize(this.person.learningTrackResults);
                    },
                    (error) => {
                        this.showErrorMessage(error);
                    }
                )
                .finally(() => {
                    this.isLoading = false;
                    this.learningTrackresultToEdit = null;
                    // this.$emit('updatedLearningTrack');
                    setTimeout(() => {
                        this.updateLearningTracks();
                    }, 0);
                });
        },

        showErrorMessage(e) {
            let err;
            if (e.networkError) {
                if (e.networkError.result && e.networkError.result.error) {
                    err = e.networkError.result.error.split("\r\n").join("");
                } else if (e.networkError.bodyText) {
                    err = Utils.stripHtml(e.networkError.bodyText.split("<body>")[1]);
                } else {
                    err = JSON.stringify(e);
                }
            } else {
                err = e.message;
            }
            const error = this.$trans.getTranslation(err, err);
            this.errorMsg = error;
        },

        addLearningTrackResult(ltrToEdit) {
            //1. remove earlier that are not-attempted or in-progress
            this.person.learningTrackResults.forEach((result) => {
                if (
                    result.status === "live" &&
                    result.learningTrack[0].slug === ltrToEdit.learningTrack[0].slug &&
                    (result.learningTrackResultStatus[0].slug === "not-attempted" ||
                        result.learningTrackResultStatus[0].slug === "in-progress")
                ) {
                    try {
                        this.deleteLearningTrackWithId(result.id);
                        this.normalize(this.person.learningTrackResults);
                    } catch (e) {
                        console.log("Nothing to remove ...", e);
                    }
                }
            });

            console.log("2", [parseInt(this.person.id)]);

            //2. add new passed
            this.$apollo
                .mutate({
                    mutation: CREATE_LEARNING_TRACK_RESULT, //this also triggers the Craft plugin to attach this LT to the Person
                    variables: {
                        id: [parseInt(this.person.id)],
                        learningTrack: [parseInt(ltrToEdit.learningTrack[0].id)],
                        statusId: LEARNING_TRACK_STATUS.PASSED,
                        expiryDate: moment().add(1, "years").format(),
                    },
                    fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                })
                .then(() => {
                    this.updateLearningTracks();
                })
                .finally(() => {
                    this.learningTrackresultToEdit = null;
                });
        },

        updateLearningTracks() {
            //this.errorMsg = "";
            // this.isLoading = true;
            this.$apollo
                .query({
                    query: GET_LEARNING_TRACKS_FOR_PERSON_BY_ID,
                    variables: {
                        id: [this.person.id],
                    },
                    fetchPolicy: "network-only",
                })
                .then(
                    (data) => {
                        //update normalized data for reactivity update
                        // console.log(1, this.person.learningTrackResults);
                        this.person.learningTrackResults = [];
                        this.person.learningTrackResults.push(...data.data.entries[0].learningTrackResults);
                        // console.log(2, this.person.learningTrackResults, data.data.entries[0].learningTrackResults);
                        this.normalize(this.person.learningTrackResults);
                    },
                    (error) => {
                        this.showErrorMessage(error);
                    }
                )
                .catch((e) => {
                    this.showErrorMessage(e);
                })
                .finally(() => {
                    // this.isLoading = false;
                });
        },

        deleteTrack(result) {
            this.deleteLearningTrackWithId(result.id)
                .catch((e) => {
                    this.showErrorMessage(e);
                })
                .then(() => {
                    this.updateLearningTracks();
                });
        },
    },
};
</script>
