import gql from "graphql-tag";

export const GET_INTERNAL_CONTACTS = gql`
    query onsite_GetInternalContacts {
        entries(section: "internalContacts", orderBy: "title ASC") {
            ... on internalContacts_internalContacts_Entry {
                id
                title
            }
        }
    }
`;

export const SAVE_INTERNAL_CONTACT = gql`
    mutation onsite_SaveInternalContact($title: String) {
        save_internalContacts_internalContacts_Entry(title: $title) {
            title
            id
        }
    }
`;
