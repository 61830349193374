<template>
    <div>
        <div class="flex justify-between w-full mb-5">
            <div class="form-group">
                <input
                    :placeholder="$trans.getTranslation('filter', 'Filter')"
                    autofocus
                    class="form-control"
                    type="text"
                    v-model="searchTerm"
                />
                <div class="filter-clear">
                    <button @click.prevent="search = ''">
                        <CloseIcon />
                    </button>
                </div>
            </div>
            <div class="flex justify-end" v-if="!$store.getters.isViewer">
                <a
                    @click.prevent="addCertificate"
                    class="flex items-center btn btn btn--primary btn--icon"
                    href="#"
                    v-show="!isArchived"
                >
                    <div v-trans="'add'">Toevoegen</div>
                    <PlusIcon />
                </a>
            </div>
        </div>

        <div class="c-preloader mt-4 c-preloader--fixed" v-show="isLoading"><i></i><i></i><i></i></div>

        <table class="table">
            <thead>
                <tr>
                    <th v-trans="'file-name'">Bestandsnaam</th>
                    <th v-trans="'extension'">Extensie</th>
                    <th v-trans="'upload-date'">Uploaddatum</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr :key="certificate.id" v-for="certificate in filteredCertificates">
                    <td>
                        <a
                            :href="certificate.url"
                            target="_blank"
                            class="text-primary-normal hover:underline cursor-pointer"
                            >{{ certificate.title }}</a
                        >
                    </td>
                    <!--				<td><a :href="certificate.url" target="_blank" class="text-primary-normal hover:underline cursor-pointer">-->
                    <!--					{{certificate.extension.toUpperCase()}}</a>-->
                    <!--				</td>-->
                    <td>
                        <span
                            class="px-2 py-1 py-0 rounded text-white text-xs"
                            :style="'background-color: ' + getColorForExtension(certificate.extension)"
                            >{{ certificate.extension.toUpperCase() }}</span
                        >
                    </td>
                    <td v-html="Utils.toLocaleDateString(certificate.dateCreated)">-</td>
                    <td>
                        <span
                            @click.prevent="deleteCertificate(certificate)"
                            v-if="!$store.getters.isViewer"
                            class="text-primary-normal hover:underline cursor-pointer"
                            v-trans="'delete'"
                            >Wissen</span
                        >
                    </td>
                    <!--				<td v-html="Utils.toLocaleDateString(certificate.expiryDate)">-</td>-->
                </tr>
            </tbody>
        </table>

        <add-or-edit-certificate
            :caption="certificateModalCaption"
            :certificate="certificateToEditOrAdd"
            :is-company="isCompany"
            @cancel="onCancelPopupCertificate"
            @saveSucceeded="onSaveCertificateSucceeded"
            v-if="showAddCertificateModal"
        />
    </div>
</template>

<script>
import CloseIcon from "vue-material-design-icons/Close.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import AddOrEditCertificate from "./AddOrEditCertificate";
import { GET_COMPANY_CERTIFICATES, SAVE_COMPANY } from "../graphql/queries/Company";
import { GET_PERSON_CERTIFICATES, SAVE_PERSON2 } from "../graphql/queries/Person";
import { Utils } from "../utils/utils";

import { DELETE_CERTIFICATE } from "@/graphql/queries/Asset";

export default {
    name: "CertificatesList",
    components: {
        AddOrEditCertificate,
        CloseIcon,
        PlusIcon,
    },

    data() {
        return {
            Utils,
            certificates: [],
            searchTerm: "",
            showAddCertificateModal: false,
            isLoading: false,
            showAddFileModal: false,
            certificateToEditOrAdd: {},
            isArchived: false,
            isCompany: this.$route.path.indexOf("contractor-admin/certificates") > -1,
            isContractor: this.$route.path.indexOf("contractor/certificates") > -1,
        };
    },
    apollo: {
        certificates() {
            return {
                query: this.isCompany ? GET_COMPANY_CERTIFICATES : GET_PERSON_CERTIFICATES,
                fetchPolicy: "cache-and-network",
                variables() {
                    if (this.isCompany) {
                        return {
                            companyId: this.$store.state.companies.currentCompany.id,
                        };
                    } else {
                        return {
                            personId: this.$store.state.persons.currentContractor.id,
                        };
                    }
                },
                update(data) {
                    if (data.entry.certificates) {
                        data.entry.certificates.forEach((entry) => {
                            this.normalizeField(entry);
                        });

                        return data.entry.certificates;
                    } else {
                        return [];
                    }
                },
                watchLoading(isLoading) {
                    this.isLoading = isLoading;
                },
            };
        },
    },
    computed: {
        certificateModalCaption() {
            return this.certificateToEditOrAdd.id
                ? this.$trans.getTranslation("certificate-wijzigen", "Certificaat wijzigen")
                : this.$trans.getTranslation("certificate-toevoegen", "Certificaat toevoegen");
        },
        filteredCertificates() {
            return this.certificates
                .filter((item) => {
                    return item.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
                })
                .sort((a, b) => {
                    if (a.title.toLowerCase() > b.title.toLowerCase()) {
                        return 1;
                    }
                    if (b.title.toLowerCase() > a.title.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                });
        },
    },
    methods: {
        normalizeField(entry) {
            //entry.checked = false;
            return entry;
        },

        addCertificate() {
            this.showAddCertificateModal = true;
            this.certificateToEditOrAdd = { id: null, title: "" };
        },
        onCancelPopupCertificate() {
            this.showAddCertificateModal = false;
        },
        updateCertificate(certificate) {
            this.certificateToEditOrAdd = certificate;
            this.showAddCertificateModal = true;
        },
        onSaveCertificateSucceeded(certificate) {
            console.log("certificate:", certificate);
            this.showAddCertificateModal = false;
            this.$apollo.queries.certificates.refetch();
        },

        refresh() {
            this.$apollo.queries.certificates.refetch();
        },

        deleteCertificate(certificate) {
            this.isLoading = true;
            this.$apollo
                .mutate({
                    mutation: this.isCompany ? SAVE_COMPANY : SAVE_PERSON2,
                    variables: {
                        id: this.isCompany
                            ? parseInt(this.$store.state.companies.currentCompany.id)
                            : parseInt(this.$store.state.persons.currentContractor.id),
                        certificateIds: this.getCertificateObjects(certificate),
                        companyIds: [parseInt(this.$store.state.companies.currentCompany.id)], //required for user validation at server
                    },
                })
                .then(() => {
                    this.isLoading = true;
                    this.$apollo
                        .mutate({
                            mutation: DELETE_CERTIFICATE,
                            variables: {
                                id: parseInt(certificate.id),
                                companyId: parseInt(this.$store.state.persons.currentContractor.id), //required for user validation at server
                            },
                        })
                        .then((response) => {
                            console.log("onDeleteClicked: response:", response);
                            this.isLoading = false;
                        })
                        .catch((reject) => {
                            console.log("onDeleteClicked: reject:", reject);
                            this.error = JSON.stringify(reject);
                            this.isLoading = false;
                        });
                })
                .catch((e) => {
                    let err;
                    if (e.networkError && e.networkError.result && e.networkError.result.error) {
                        err = e.networkError.result.error.split("\r\n").join("");
                    } else {
                        err = e.message;
                    }
                    this.error = this.$trans.getTranslation(err, err);
                    this.isLoading = false;
                });
        },

        getCertificateObjects(certificateToDelete) {
            let newArray = this.certificates.map((asset) => {
                return parseInt(asset.id);
            });
            //remove asset to delete
            newArray = newArray.filter((id) => parseInt(id) !== parseInt(certificateToDelete.id));

            return newArray;
        },

        getColorForExtension(ext) {
            let color;
            console.log("ext", ext);
            switch (ext) {
                case "doc":
                case "docx":
                    color = "#185abd";
                    break;
                case "ppt":
                case "pptx":
                    color = "#c43e1c";
                    break;
                case "xls":
                case "xlsx":
                    color = "#107c41";
                    break;
                case "png":
                case "jpg":
                case "jpeg":
                case "gif":
                    color = "#f16c00";
                    break;
                case "pdf":
                    color = "#b30b00";
                    break;
                default:
                    color = "#919090";
            }
            return color;
            // 'bg-blue-700': certificate.extension === 'docx',
            //     'bg-blue-700': certificate.extension === 'doc',
            //     'bg-orange-700': certificate.extension === 'pptx',
            //     'bg-orange-700': certificate.extension === 'ppt',
            //     'bg-green-700': certificate.extension === 'xlsx',
            //     'bg-green-700': certificate.extension === 'xls',
            //     'bg-yellow-600': certificate.extension === 'png',
            //     'bg-yellow-600': certificate.extension === 'jpg',
            //     'bg-yellow-600': certificate.extension === 'jpeg',
            //     'bg-yellow-600': certificate.extension === 'gif'
        },
    },
};
</script>
