<template>
    <div>
        <back-button />
        <div class="" v-show="isCameraPresent">
            <p class="text-primary-normal text-center mb-6 md:mb-10 h3" v-trans="'qr-code-info'">
                Richt de QR-code op je badge naar dit scherm.
            </p>
        </div>

        <div v-show="!isCameraPresent" v-trans="'you-need-a-webcam'" class="bg-gray-200 p-4 rounded">
            Sorry, je hebt een web camera nodig om QR codes te scannen.
        </div>

        <div class="flex" v-show="isCameraPresent">
            <canvas class="rounded-lg m-auto scanning" id="canvasQrCode"></canvas>
        </div>

        <div class="alert alert--warning m-6 py-4" v-if="errorMsg">{{ errorMsg }}</div>
    </div>
</template>

<script>
import jsQR from "jsqr";
import BackButton from "../../components/BackButton";
import { GET_ACTIVE_GUIDS } from "../../graphql/queries/Visit";
import { Utils } from "@/utils/utils";

export default {
    name: "QRScan",
    components: {
        BackButton,
    },
    props: {
        title: String,
    },

    data() {
        return {
            isCameraPresent: true,
            canvasContext: null,
            qrVideo: null,
            canvasElement: null,
            loadingMessage: "",
            code: "",
            isCheckingCode: false,
            errorMsg: "",
        };
    },

    mounted() {
        window.a = this;
        this.qrVideo = document.createElement("video");
        this.canvasElement = document.getElementById("canvasQrCode");
        this.canvasContext = this.canvasElement.getContext("2d");
        // this.canvasContext.scale(-1,1);

        //attempt to get facing mode (user)
        navigator.mediaDevices
            .getUserMedia({ video: { facingMode: "user" } })
            .then((stream) => {
                this.qrVideo.srcObject = stream;
                this.qrVideo.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
                this.qrVideo.play();
                requestAnimationFrame(this.tick);
                this.isCameraPresent = true;
            })
            .catch((e) => {
                this.isCameraPresent = false;
                console.log("Webcam Error: ", e);
            });
    },

    methods: {
        showErrorMessage(e) {
            let err;
            if (e.networkError) {
                if (e.networkError.result && e.networkError.result.error) {
                    err = e.networkError.result.error.split("\r\n").join("");
                } else if (e.networkError.bodyText) {
                    err = Utils.stripHtml(e.networkError.bodyText.split("<body>")[1]);
                } else {
                    err = JSON.stringify(e);
                }
            } else {
                err = e.message;
            }
            const error = this.$trans.getTranslation(err, err);
            this.errorMsg = error;
        },

        drawLine(begin, end, color) {
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(begin.x, begin.y);
            this.canvasContext.lineTo(end.x, end.y);
            this.canvasContext.lineWidth = 4;
            this.canvasContext.strokeStyle = color;
            this.canvasContext.stroke();
        },

        tick() {
            if (this.qrVideo.readyState === this.qrVideo.HAVE_ENOUGH_DATA) {
                this.canvasElement.height = this.qrVideo.videoHeight;
                this.canvasElement.width = this.qrVideo.videoWidth;
                this.canvasContext.scale(-1, 1);
                this.canvasContext.drawImage(this.qrVideo, -this.canvasElement.width, 0);
                this.canvasContext.setTransform(1, 0, 0, 1, 0, 0);
                let imageData = this.canvasContext.getImageData(
                    0,
                    0,
                    this.canvasElement.width,
                    this.canvasElement.height
                );
                let code = jsQR(imageData.data, imageData.width, imageData.height, {
                    inversionAttempts: "attemptBoth",
                });
                if (code) {
                    this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#D30039");
                    this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#D30039");
                    this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#D30039");
                    this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#D30039");
                    // console.log("Gert: code.data:", code.data);
                    if (!this.isCheckingCode) {
                        if (code.data.length === 9 && code.data.substr(4, 1) === "-") {
                            this.code = code.data;
                            this.checkCode();
                        }
                    }
                } else {
                    // this.code = ""; //don!t remove later!
                }
            }
            requestAnimationFrame(this.tick);
        },

        stopVideo() {
            if (this.qrVideo && this.qrVideo.srcObject) {
                const stream = this.qrVideo.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach((track) => {
                    // stopping every track
                    track.stop();
                });
            }
        },

        checkCode() {
            this.errorMsg = "";
            this.isCheckingCode = true;
            this.$apollo
                .query({
                    query: GET_ACTIVE_GUIDS,
                    fetchPolicy: "network-only",
                })
                .then((response) => {
                    // console.log("Gert: response:", response);
                    if (response && response.data && response.data.entries) {
                        //map object to only the id and guid
                        const guidsArray = response.data.entries.map((entry) => {
                            return {
                                guid: entry.person.length > 0 ? entry.person[0].guid : "-",
                                visitId: entry.id,
                            };
                        });

                        // console.log("Gert: this.code:", this.code);
                        //list of codes has our code? [yes]
                        const foundEntry = guidsArray.find((entry) => entry.guid === this.code);
                        if (foundEntry) {
                            //route to check-out
                            this.$router.push({
                                name: "onsite-checkOut",
                                params: {
                                    type: this.$store.getters.getVisitTypeIdForContractor,
                                    id: foundEntry.visitId,
                                },
                            });
                        } else {
                            //route to check-in
                            this.$router.push({
                                name: "onsite-checkIn",
                                params: {
                                    type: this.$store.getters.getVisitTypeIdForContractor,
                                    guid: this.code,
                                },
                            });
                        }
                    } else {
                        this.isCheckingCode = false;
                    }
                })
                .catch((e) => {
                    this.isCheckingCode = false;
                    this.showErrorMessage(e);
                });
        },
    },

    beforeDestroy() {
        this.stopVideo();
    },
};
</script>
