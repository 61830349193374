<template>
    <div v-trans="'privacy-policy-body'">
        <div class="h2">Privacy Agreement</div>
        <div class="h3 mt-4">What type of information do we store</div>
        <ul>
            <li>Firstname</li>
            <li>Lastname</li>
            <li>Company</li>
            <li>Mobile phone</li>
        </ul>
        <div class="h3 mt-4">We collect this data in the context of</div>
        <ul>
            <li>Notify the host of your arrival</li>
            <li>Security and internal audits</li>
            <li>In case of emergency situations</li>
            <li>Draw up visitor statistics</li>
        </ul>
        <div class="h3 mt-4">Use of personal data</div>
        <ul>
            <li>To notify the host of your arrival</li>
            <li>To notify you in case of an emergency</li>
            <li>To invite you to a security test</li>
        </ul>
        <div class="h3 mt-4">Data storage and retention</div>
        <ul>
            <li>The data is stored at Combell data centers in Belgium</li>
        </ul>
        <div class="h3 mt-4">Disclosure to third parties</div>
        <ul>
            <li>
                We use a platform built and maintained by B.U.T Belgium for registering our visitors, with whom we have
                a data processing agreement
            </li>
            <li>We do not sell or share any of your personal information to other parties.</li>
        </ul>
        <div class="h3 mt-4">Your rights</div>
        <ul>
            <li>Right of access to personal data</li>
            <li>Right to rectification, completion or update of your personal data</li>
            <li>Right to delete your personal data (‘right to be forgotten’)</li>
            <li>Right to limit the processing of your personal data</li>
            <li>Right to transferability of your personal data</li>
            <li>Right to object to oppose the processing of your personal data</li>
            <li>
                Contact <a href="https://aluminiumduffel.com">https://aluminiumduffel.com</a> for exempting your rights.
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "privacy-notice",
};
</script>
