<template>
    <div class="w-full">
        <h1 class="text-xl" v-trans="'test-successful'">Geslaagd</h1>
        <hr />
        <p v-trans="'online-test-successful--copy'" class="mb-8">
            U heeft deze test met succes afgelegd.<br />
            Dit geeft u toegang tot de site van Aluminimum Duffel.
        </p>

        <back-button />
    </div>
</template>
<script>
import BackButton from "@/components/BackButton";

export default {
    name: "ContractorLearningPathSuccess",
    components: {
        BackButton,
    },
};
</script>
