import gql from "graphql-tag";
// example https://aluminiumduffeldigitalportal.dev.buthost.com/admin/graphiql?schemaUid=*&schemaUid=*&schemaUid=*&schemaUid=*&schemaUid=*&schemaUid=*&schemaUid=*&schemaUid=*&schemaUid=*&schemaUid=*&schemaUid=*&schemaUid=efba8db2-8eba-4a92-801b-be2491b60810
export const GET_ALL_COMPANIES_QUERY = gql`
    query onsite_GetAllCompanies($status: [String!], $companyTypes: [QueryArgument]) {
        entries(section: ["company"], relatedToCategories: [{ id: $companyTypes }], status: $status, orderBy: "title") {
            ... on company_company_Entry {
                id
                title
                address
                telephone
                numberOfEmployees
                isAccessAllowed
                companyType {
                    id
                }
                status
            }
        }
    }
`;

export const GET_ALL_COMPANIES_QUERY_WITH_FILTER = gql`
    query onsite_GetAllCompaniesWithFilter(
        $status: [String!]
        $companyTypes: [QueryArgument]
        $limit: Int
        $orderBy: String
        $search: String
    ) {
        entries(
            section: ["company"]
            relatedToCategories: [{ id: $companyTypes }]
            status: $status
            limit: $limit
            orderBy: $orderBy
            search: $search
        ) {
            ... on company_company_Entry {
                id
                title
                address
                telephone
                numberOfEmployees
                isAccessAllowed
                companyType {
                    id
                }
                status
            }
        }
    }
`;

export const GET_COMPANY_WITH_ID = gql`
    query contractoradministrator_GetCompanyWithId($entryId: [QueryArgument]!) {
        entries(section: ["company"], id: $entryId) {
            ... on company_company_Entry {
                id
                title
                address
                telephone
                numberOfEmployees
                isAccessAllowed
                companyType {
                    id
                }
                status
            }
        }
    }
`;

export const GET_COMPANY_CERTIFICATES = gql`
    query contractoradministrator_GetCompanyCertificate($companyId: [QueryArgument]!) {
        entry(id: $companyId) {
            ... on company_company_Entry {
                id
                certificates {
                    ... on certificates_Asset {
                        id
                        title
                        url
                        expiryDate
                        dateCreated
                        extension
                    }
                }
            }
        }
    }
`;

/* MUTATIONS  */

export const SAVE_COMPANY = gql`
    mutation contractoradministrator_SaveCompany(
        $id: ID
        $title: String
        $address: String
        $telephone: String
        $isAccessAllowed: Boolean
        $companyTypeIds: [Int]
        $certificateIds: [Int]
    ) {
        save_company_company_Entry(
            id: $id
            title: $title
            address: $address
            telephone: $telephone
            isAccessAllowed: $isAccessAllowed
            companyType: $companyTypeIds
            certificates: $certificateIds
        ) {
            id
            title
            address
            telephone
            numberOfEmployees
            isAccessAllowed
            companyType {
                id
            }
            certificates {
                ... on certificates_Asset {
                    id
                    title
                    url
                    expiryDate
                    dateCreated
                }
            }
            status
        }
    }
`;
