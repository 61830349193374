<template>
    <dynamic-buttonless-modal @close="cancel" escapable="false">
        <template v-slot:content>
            <!--Title-->
            <div class="flex justify-between items-center pb-3">
                <p class="text-2xl font-bold">{{ caption }}</p>
            </div>

            <person-edit :person="person" @cancel="cancel" :show-cancel-button="true" @saveSucceeded="saveSucceeded" />
        </template>
    </dynamic-buttonless-modal>
</template>

<script>
import PersonEdit from "./PersonEdit";
import DynamicButtonlessModal from "./DynamicButtonlessModal";

export default {
    name: "AddOrEditEmployee",
    components: { DynamicButtonlessModal, PersonEdit },
    props: {
        caption: {
            required: true,
        },
        person: {},
    },

    methods: {
        saveSucceeded(person) {
            this.$emit("saveSucceeded", person);
        },

        cancel() {
            this.$emit("cancel");
        },
    },
};
</script>
