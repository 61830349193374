<template>
    <div>
        <h2 class="h2 text-primary-normal text-center" v-trans="'test-successful--title'">Test geslaagd</h2>
        <div class="text-center">
            <span class="text-6xl mr-4 -mt-3"><check-mark></check-mark></span>
        </div>

        <p class="text-2xl flex-grow text-center" v-trans="'test-successful--copy'">
            U mag nu de fabriek betreden. Gelieve u weer aan te melden aan de inkom.
        </p>

        <button @click="backToLanguageSelect" class="btn btn--primary btn--lg mt-8 mx-auto block" v-trans="'ok'">
            OK
        </button>
    </div>
</template>
<script>
import CheckMark from "vue-material-design-icons/Check";

export default {
    name: "TestComplete",
    data() {
        return {
            timeoutRef: 0,
        };
    },
    components: {
        CheckMark,
    },
    mounted() {
        this.timeoutRef = setTimeout(this.backToLanguageSelect, 10000);
    },
    methods: {
        backToLanguageSelect() {
            clearTimeout(this.timeoutRef);
            this.$router.push({ name: "test-languageSelect" });
        },
    },
};
</script>
