import OnlineHome from "../views/online/OnlineHome";
import ContractorPortal from "../views/online/ContractorPortal";
import ContractorProfile from "../views/online/ContractorProfile";
import ContractorAdminPortal from "../views/online/ContractorAdminPortal";
import ContractorAdminCompanyProfile from "../views/online/ContractorAdminCompanyProfile";
import ContractorAdminContractorHolder from "../views/online/ContractorAdminContractorHolder";
import ContractorAdminContractorList from "../views/online/ContractorAdminContractorList";
import CertificatesList from "../components/CertificatesList";
import ContractorLearningPath from "../views/online/ContractorLearningPath";
import ContractorLearningPathOverview from "../views/online/ContractorLearningPathOverview";
import CompanyAdminContractorHolder from "../views/online/CompanyAdminContractorHolder";
import CompanyAdminPortal from "../views/online/CompanyAdminPortal";
import CompanyAdminCompaniesList from "../views/online/CompanyAdminCompaniesList";
import CompanyAdminContractorList from "../views/online/CompanyAdminContractorList";
import ContractorAdminUsers from "../views/online/ContractorAdminUsers";
import CompanyAdminUsers from "../views/online/CompanyAdminUsers";
import CompanyAdminProfile from "../views/online/CompanyAdminProfile";
import CompanyAdminVisitingPersons from "../views/online/CompanyAdminVisitingPersons";
import CompanyAdminVisitLogs from "../views/online/CompanyAdminVisitLogs";
import ContractorStart from "../views/online/ContractorStart";
import ContractorLearningPathSuccess from "../views/online/ContractorLearningPathSuccess";
import ContractoradminsList from "@/views/online/ContractorAdminsList.vue";
import EntryPermit from "@/components/PermitInputForm.vue";
import CraftUsersList from "@/components/CraftUsersList.vue";
import { CRAFT_USER_TYPE } from "@/store/constants";
import QRCodeHardwareScanner from "@/views/onsite/QRCodeHardwareScanner.vue";

export default [
    {
        path: "/online",
        name: "online",
        component: () => import(/* webpackChunkName: "online" */ "../views/Online.vue"),
        redirect: { name: "onlineHome" },
        children: [
            {
                name: "onlineHome",
                path: "home",
                component: OnlineHome,
            },
            {
                name: "onlineContractor",
                path: "contractor",
                component: ContractorPortal,
                redirect: { name: "onlineContractorStart" },
                children: [
                    {
                        name: "onlineContractorStart",
                        path: "start",
                        component: ContractorStart,
                    },
                    {
                        name: "onlineContractorProfile",
                        path: "profile",
                        component: ContractorProfile,
                    },
                    {
                        name: "onlineContractorCertificates",
                        path: "certificates",
                        component: CertificatesList,
                    },
                    {
                        name: "onlineContractorLearningPath",
                        path: "learning-path",
                        component: ContractorLearningPath,
                        redirect: { name: "onlineContractorLearningPathOverview" },
                        children: [
                            {
                                name: "onlineContractorLearningPathOverview",
                                path: "overview",
                                component: ContractorLearningPathOverview,
                            },
                            {
                                name: "onlineContractorLearningPathResult",
                                path: "success",
                                component: ContractorLearningPathSuccess,
                            },
                        ],
                    },
                    {
                        name: "onlineContractorEntryPermit",
                        path: "entry-permit",
                        component: EntryPermit,
                    },
                ],
            },
            {
                name: "onlineContractorAdmin",
                path: "contractor-admin",
                component: ContractorAdminPortal,
                redirect: { name: "ContractorAdminContractorHolder" },
                children: [
                    {
                        name: "ContractorAdminContractorHolder",
                        path: "contractors",
                        component: ContractorAdminContractorHolder,
                        redirect: { name: "ContractorAdminCompanyList" },
                        children: [
                            {
                                name: "ContractorAdminCompanyList",
                                path: "list",
                                component: ContractorAdminContractorList,
                            },
                        ],
                    },
                    {
                        name: "ContractorAdminCompanyProfile",
                        path: "company-profile",
                        component: ContractorAdminCompanyProfile,
                    },
                    {
                        name: "ContractorAdminCertificates",
                        path: "certificates",
                        component: CertificatesList,
                    },
                    {
                        name: "ContractorAdminUsers",
                        path: "users",
                        component: ContractorAdminUsers,
                    },

                    {
                        name: "ContractorAdminMyProfile",
                        path: "profile",
                        component: CompanyAdminProfile,
                    },
                ],
            },
            {
                name: "onlineAdmin",
                path: "company-admin",
                component: CompanyAdminPortal,
                redirect: { name: "CompanyAdminVisitingPersons" },
                children: [
                    {
                        name: "CompanyAdminCompanies",
                        path: "companies",
                        redirect: { name: "CompanyAdminCompaniesList" },
                        component: CompanyAdminContractorHolder,
                        children: [
                            {
                                name: "CompanyAdminCompaniesList",
                                path: "list",
                                component: CompanyAdminCompaniesList,
                            },
                        ],
                    },
                    {
                        name: "CompanyAdminContractors",
                        path: "contractors",
                        redirect: { name: "CompanyAdminContractorsList" },
                        component: CompanyAdminContractorHolder,
                        children: [
                            {
                                name: "CompanyAdminContractorList",
                                path: "list",
                                component: CompanyAdminContractorList,
                            },
                        ],
                    },
                    {
                        name: "CompanyAdminVisitingPersons",
                        path: "visiting-persons",
                        component: CompanyAdminVisitingPersons,
                    },
                    {
                        name: "CompanyAdminProfile",
                        path: "profile",
                        component: CompanyAdminProfile,
                    },
                    {
                        name: "CompanyAdminUsers",
                        path: "admins",
                        component: CompanyAdminUsers,
                        redirect: { name: "CompanyAdminUsersDuffel" },
                        children: [
                            {
                                name: "CompanyAdminUsersDuffel",
                                path: "alu-duffel",
                                component: CraftUsersList,
                                props: {
                                    craftUserTypeSlugs: [
                                        CRAFT_USER_TYPE.PORTAL_ADMINISTRATOR_SLUG,
                                        CRAFT_USER_TYPE.COMPANY_ADMINISTRATOR_SLUG,
                                        CRAFT_USER_TYPE.VIEWER_SLUG,
                                    ],
                                },
                            },
                            {
                                name: "CompanyAdminUsersOther",
                                path: "others",
                                component: ContractoradminsList,
                            },
                        ],
                    },
                    {
                        name: "CompanyAdminVisitLogs",
                        path: "visit-logs",
                        component: CompanyAdminVisitLogs,
                    },
                    {
                        name: "QRCodeHardwareScanner",
                        path: "qr-code-hardware-scanner",
                        component: QRCodeHardwareScanner,
                    },
                ],
            },
        ],
    },
];
