import gql from "graphql-tag";

//done
export const SAVE_VISIT = gql`
    mutation onsite_SaveVisit(
        $title: String
        $slug: String
        $person: [Int]
        $visitType: [Int]
        $loadingType: String
        $cmr: String
        $orderNumber: String
        $internalContactName: String
        $signature: [Int]
        $privacyPolicy: Boolean
        $needsWeighing: Boolean
        $expiryDate: DateTime
    ) {
        save_visit_visit_Entry(
            title: $title
            slug: $slug
            person: $person
            visitType: $visitType
            loadingOrUnloading: $loadingType
            cmr: $cmr
            orderNumber: $orderNumber
            internalContactName: $internalContactName
            signature: $signature
            privacyPolicy: $privacyPolicy
            needsWeighing: $needsWeighing
            expiryDate: $expiryDate
        ) {
            id
        }
    }
`;

//done
export const SAVE_VISIT2 = gql`
    mutation companyadministrator_SaveVisit2(
        $title: String
        $person: [Int]
        $visitType: [Int]
        $loadingType: String
        $orderNumber: String
        $internalContactName: String
        $needsWeighing: Boolean
    ) {
        save_visit_visit_Entry(
            title: $title
            person: $person
            visitType: $visitType
            loadingOrUnloading: $loadingType
            orderNumber: $orderNumber
            internalContactName: $internalContactName
            needsWeighing: $needsWeighing
        ) {
            id
        }
    }
`;

//done
export const SAVE_VISIT_WITH_ID = gql`
    mutation companyadministrator_SaveVisitWithId(
        $visitId: ID
        $person: [Int]
        $visitType: [Int]
        $loadingType: String
        $orderNumber: String
        $internalContactName: String
        $needsWeighing: Boolean
        $expiryDate: DateTime
    ) {
        save_visit_visit_Entry(
            id: $visitId
            person: $person
            visitType: $visitType
            loadingOrUnloading: $loadingType
            orderNumber: $orderNumber
            internalContactName: $internalContactName
            needsWeighing: $needsWeighing
            expiryDate: $expiryDate
        ) {
            id
        }
    }
`;

//done
export const GET_VISITS_FOR_PERSON_ID = gql`
    query contractor_visitsForPersonId($personIds: [Int]) {
        entries(
            section: [visit]
            status: ""
            relatedTo: [{ element: $personIds }]
            orderBy: "dateCreated DESC"
            limit: 10
        ) {
            ... on Visit {
                id
                title
                visitType {
                    id
                    title
                }
                internalContactName
                expiryDate
                dateCreated
                needsWeighing
                loadingOrUnloading
                status
            }
        }
    }
`;

//done
export const GET_VISITS_FOR_PERSON_ID2 = gql`
    query contractor_visitsForPersonId2($personIds: [EntryCriteriaInput]) {
        entries(section: "visit", status: null, relatedToEntries: $personIds, orderBy: "dateCreated DESC", limit: 10) {
            ... on visit_visit_Entry {
                id
                title
                visitType {
                    id
                    title
                }
                internalContactName
                expiryDate
                dateCreated
                needsWeighing
                loadingOrUnloading
                orderNumber
                status
            }
        }
    }
`;

export const GET_VISITS_FOR_PERSON_ID3 = gql`
    query contractor_visitsForPersonId3($personIds: [EntryCriteriaInput]) {
        entries(section: "visit", status: null, relatedToEntries: $personIds, orderBy: "expirydate DESC", limit: 10) {
            ... on visit_visit_Entry {
                id
                title
                visitType {
                    id
                    title
                }
                internalContactName
                expiryDate
                dateCreated
                needsWeighing
                loadingOrUnloading
                orderNumber
                status
            }
        }
    }
`;

//done
export const GET_ACTIVE_VISITS = gql`
    query onsite_GetActiveVisits {
        entries(section: "visit", status: "live") {
            ... on visit_visit_Entry {
                id
                title
                expiryDate
                manualCheckout
                person {
                    ... on person_person_Entry {
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

//done
export const GET_ACTIVE_GUIDS = gql`
    query onsite_GetActiveGuids {
        entries(section: "visit", status: "live") {
            ... on visit_visit_Entry {
                id
                person {
                    ... on person_person_Entry {
                        guid
                    }
                }
            }
        }
    }
`;

//done
export const GET_ACTIVE_VISITS_FULL2 = gql`
    query companyadministrator_GetActiveVisitsFull2($visitTypes: [QueryArgument], $search: String) {
        entries(section: "visit", status: "live", relatedTo: $visitTypes, search: $search, orderBy: "id DESC") {
            ... on visit_visit_Entry {
                id
                manualCheckout
                visitType {
                    id
                    slug
                    title
                }
                dateCreated
                person {
                    ... on person_person_Entry {
                        id
                        firstName
                        lastName
                        email
                        guid
                        company {
                            id
                            title
                        }
                        photo {
                            id
                            url @transform(width: 80, immediately: true)
                        }
                        companyName
                        company {
                            id
                            title
                        }
                        licensePlate
                        status
                        learningTrackResults(status: "", orderBy: "id") {
                            ... on learningTrackResult_learningTrackResult_Entry {
                                id
                                dateUpdated
                                expiryDate
                                status
                                learningTrack {
                                    id
                                    title
                                    slug
                                }
                                learningTrackResultStatus {
                                    id
                                    slug
                                }
                                lastAttemptDate
                                lastAttemptDate @formatDateTime(format: "y-m-d")
                            }
                        }
                        safetyViolation
                    }
                }
                internalContactName
            }
        }
    }
`;

//does not work if expiryDate is not in the future
//done
export const CHECKOUT_VISIT = gql`
    mutation onsite_CheckoutVisit($id: ID, $expiryDate: DateTime, $manualCheckout: Boolean) {
        save_visit_visit_Entry(id: $id, expiryDate: $expiryDate, manualCheckout: $manualCheckout) {
            id
        }
    }
`;

//done
export const DOUBLE_BOOKING_CHECK = gql`
    query onsite_DoubleBookingCheck($personId: [QueryArgument]) {
        entries(section: ["visit"], status: "live", relatedTo: $personId) {
            ... on visit_visit_Entry {
                id
            }
        }
    }
`;

//deprecated: can be removed in next build :)
export const GET_VISITS_BETWEEN2 = gql`
    query contractoradministrator_GetVisitsBetween2($from: [String], $to: [String]) {
        entries(section: "visit", orderBy: "id DESC", status: null, limit: 10, postDate: $from, dateCreated: $to) {
            ... on visit_visit_Entry {
                id
                title
                expiryDate
                dateCreated
                manualCheckout
                visitType {
                    id
                    title
                }
                loadingOrUnloading
                orderNumber
                internalContactName
                needsWeighing
                person {
                    ... on person_person_Entry {
                        firstName
                        lastName
                        email
                        company {
                            title
                        }
                        companyName
                        licensePlate
                        language
                        guid
                        mobilePhone
                        info
                        safetyViolation
                    }
                }
            }
        }
    }
`;

export const GET_VISITS_BETWEEN3 = gql`
    query contractoradministrator_GetVisitsBetween3($from: [String], $to: [String]) {
        entries(section: "visit", orderBy: "id DESC", status: null, limit: 9999, postDate: $from, dateCreated: $to) {
            ... on visit_visit_Entry {
                id
                title
                expiryDate
                dateCreated
                manualCheckout
                visitType {
                    id
                    title
                }
                loadingOrUnloading
                orderNumber
                internalContactName
                needsWeighing
                person {
                    ... on person_person_Entry {
                        firstName
                        lastName
                        email
                        company {
                            title
                        }
                        companyName
                        licensePlate
                        language
                        guid
                        mobilePhone
                        info
                        safetyViolation
                    }
                }
            }
        }
    }
`;

export const GET_TOP_100_OLDEST_TRUCKERS = gql`
    query onsite_top100OldestTruckers {
        entries(section: [visit], visitType: "184", status: "expired", orderBy: "expiryDate DESC", limit: 100) {
            ... on Visit {
                id
                title
                visitType {
                    id
                    title
                }
                expiryDate @date(as: "F j, Y")
                status
                person {
                    id
                    title
                }
            }
        }
    }
`;

export const GET_ALL_VISITOR_TYPES = gql`
    query public_GetAllVisitorTypes {
        categories(group: "VisitorType") {
            ... on visitorType_Category {
                id
                slug
                title
            }
        }
    }
`;
