<template>
    <div class="btn--secondary btn--icon-left btn btn--link mb-2 -mt-2 cursor-pointer" @click="$router.go(-1)">
        <chevron-left></chevron-left><span v-trans="'back'">Terug</span>
    </div>
</template>
<script>
import ChevronLeft from "vue-material-design-icons/ChevronLeft";
export default {
    name: "BackButton",
    components: {
        ChevronLeft,
    },
};
</script>
