<template>
    <div>
        <h1 class="h5 uppercase text-primary-dark mb-4">Aluminium Duffel Administrators</h1>

        <div
            class="mb-4 flex items-center alert alert--info"
            v-if="
                this.$store.state.persons.currentCompanyAdmin.id && this.$route.fullPath.indexOf('company-admin') > -1
            "
        >
            <InformationOutline class="text-2xl mr-3 mb-1" />
            <p class="text-sm">
                Hier zie je enkel de Aluminimum Duffel Admins. <br />
                Om andere Company Admins (Admins van een bepaald bedrijf) aan te passen: <br />Ga naar
                <strong>Bedrijven > [Bedrijfsnaam] > Adminstrators</strong>
            </p>
        </div>

        <div
            class="mb-4 flex items-center alert alert--info"
            v-if="
                this.$store.state.persons.currentCompanyAdmin.id &&
                this.$route.fullPath.indexOf('contractor-admin') > -1
            "
        >
            <InformationOutline class="text-2xl mr-3 mb-1" />
            <p class="text-sm">
                Hier zie je enkel de Admins voor het bedrijf {{ this.$store.state.companies.currentCompany.title }}.
                <br />
                Voor de lijst van Admins van Aluminimum Duffel: Ga naar <strong>Admin > Adminstrators</strong>
            </p>
        </div>

        <div class="flex justify-between w-full mb-5">
            <div class="form-group">
                <input
                    autofocus
                    class="form-control"
                    :placeholder="$trans.getTranslation('filter', 'Filter')"
                    type="text"
                    v-model="searchTerm"
                />
                <div class="filter-clear">
                    <button @click.prevent="searchTerm = ''">
                        <CloseIcon />
                    </button>
                </div>
            </div>
            <div
                class="flex justify-end"
                v-if="$store.getters.isPortalAdmin || $store.getters.isCompanyAdmin || $store.getters.isContractorAdmin"
            >
                <a
                    @click.prevent="addUser"
                    href="#"
                    v-show="!isArchived"
                    class="flex items-center btn btn btn--primary btn--icon"
                >
                    <div v-trans="'add'">Toevoegen</div>
                    <PlusIcon />
                </a>
            </div>
        </div>

        <div class="c-preloader mt-4 c-preloader--fixed" v-show="isLoading"><i></i><i></i><i></i></div>

        <table class="table">
            <thead>
                <tr>
                    <th v-trans="'first-name'">Voornaam</th>
                    <th><span v-trans="'last-name'">Familienaam</span><i class="arrow down"></i></th>
                    <th v-trans="'email'">Email</th>
                    <th v-trans="'profile'" v-if="$store.state.persons.currentCompanyAdmin.id">Profiel</th>
                </tr>
            </thead>
            <tbody>
                <tr :key="user.id" v-for="user in filteredUsers">
                    <td class="cursor-pointer" v-if="editableUser(user)">
                        <div
                            @click.prevent="updateUser(user)"
                            class="text-primary-normal hover:underline cursor-pointer"
                        >
                            {{ user.firstName }} <span class="helper" v-trans="'click-to-edit'">click to edit</span>
                        </div>
                    </td>
                    <td v-else>{{ user.firstName }}</td>
                    <td>{{ user.lastName }}</td>
                    <td>{{ user.email }}</td>
                    <td v-if="$store.state.persons.currentCompanyAdmin.id">
                        {{ Utils.titleOfCraftUserType(user.craftUserTypeSlug) }}
                    </td>
                </tr>
            </tbody>
        </table>

        <add-or-edit-user
            :caption="userModalCaption"
            :user="userToEditOrAdd"
            :craftUserTypeSlug="userToEditOrAdd.craftUserTypeSlug"
            @cancel="onCancelPopupUser"
            @saveSucceeded="onUserSaveSucceeded"
            v-if="showAddUserModal"
        />
    </div>
</template>

<script>
// import * as axios from "axios";
import { GET_USERS } from "../graphql/queries/User";
import CloseIcon from "vue-material-design-icons/Close.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";

import AddOrEditUser from "./AddOrEditUser";
import { ACTION_SET_USER_SEARCH_TERM, ALERIS_COMPANY_ID, CRAFT_USER_TYPE } from "../store/constants";
import InformationOutline from "vue-material-design-icons/InformationOutline.vue";

import { Utils } from "../utils/utils";

export default {
    name: "CraftUsersList",
    components: {
        AddOrEditUser,
        CloseIcon,
        PlusIcon,
        InformationOutline,
    },

    props: {
        craftUserTypeSlugs: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            users: [],
            isLoading: false,
            showAddUserModal: false,
            userToEditOrAdd: {},
            isArchived: false,
            Utils,
        };
    },
    apollo: {
        users() {
            return {
                query: GET_USERS,
                //pollInterval: 3000,
                fetchPolicy: "cache-and-network",
                variables() {
                    return {
                        userIds: null,
                        craftUserTypeSlugs: this.craftUserTypeSlugs,
                        companyId: this.craftUserTypeSlugs.includes(CRAFT_USER_TYPE.COMPANY_ADMINISTRATOR_SLUG)
                            ? null
                            : [this.$store.state.companies.currentCompany.id],
                        search: `*${this.searchTerm}*`,
                    };
                },
                update(data) {
                    if (data.users) {
                        data.users.forEach((entry) => {
                            this.normalizeField(entry);
                        });

                        // console.log(
                        //     "this.craftUserTypeSlugs:",
                        //     this.craftUserTypeSlugs,
                        //     this.$store.state.companies.currentCompany.id
                        // );

                        data.users = data.users.sort(function (a, b) {
                            //console.log("a:", a.company[0]?.title);
                            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                                return 1;
                            }
                            if (b.lastName.toLowerCase() > a.lastName.toLowerCase()) {
                                return -1;
                            }
                            return 0;
                        });

                        return data.users;
                    } else {
                        return [];
                    }
                },
                watchLoading(isLoading) {
                    this.isLoading = isLoading;
                },
            };
        },
    },
    computed: {
        searchTerm: {
            set(value) {
                this.$store.dispatch(ACTION_SET_USER_SEARCH_TERM, value);
            },
            get() {
                return this.$store.state.userSearchTerm;
            },
        },

        numberOfRowsChecked() {
            return this.users ? this.users.some((p) => p.checked) : false;
        },
        userModalCaption() {
            return this.userToEditOrAdd.id
                ? this.$trans.getTranslation("admin-wijzigen", "Administator wijzigen")
                : this.$trans.getTranslation("admin-toevoegen", "Administator toevoegen");
        },
        filteredUsers() {
            return this.users.filter(() => {
                // if own record, don't show
                //if (user.id === this.$store.getters.currentUserId) return false;

                //only show users with the right craftUserType
                //Not a security issue to filter locally, because only CompanyAdmins will see all.
                //ContractorsAdmins will already get a subset from the server
                // console.log("user:", user);
                return true;

                // let restrictedUsers = null;
                // if (user.craftUserTypeSlug) {
                //     restrictedUsers = user.craftUserTypeSlug === "onsite" || user.craftUserTypeSlug === "test";
                // } else {
                //     return false;
                // }
                // if (restrictedUsers) return false;
                // const filterCompany = this.craftUserTypeSlugs.includes(CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG)
                //     ? true
                //     : parseInt(user.company[0].id) === parseInt(this.$store.state.companies.currentCompany.id);
                //
                // // console.log(this.craftUserTypeSlugs, user.firstName, user.craftUserTypeSlug, filterCompany, this.craftUserTypeSlugs.includes(user.craftUserTypeSlug))
                //
                // return this.userRecordHasUserTypeSlug(user) && this.userRecordHasCompany(user) && filterCompany;
            });
        },
    },
    methods: {
        // userRecordHasUserTypeSlug(user) {
        //     if (this.craftUserTypeSlugs.includes(CRAFT_USER_TYPE.VIEWER_SLUG)) {
        //         return this.craftUserTypeSlugs.includes(user.craftUserTypeSlug);
        //     } else {
        //         return this.craftUserTypeSlugs.includes(user.craftUserTypeSlug);
        //     }
        // },
        // userRecordHasCompany(user) {
        //     return user.company && user.company.length > 0 && user.company[0].id;
        // },
        editableUser(user) {
            // console.log("user:", user);
            if (
                (this.$store.getters.isPortalAdmin &&
                    user.id.toString() === this.$store.state.persons.currentCompanyAdmin.id) ||
                (this.$store.getters.isCompanyAdmin &&
                    user.id.toString() === this.$store.state.persons.currentCompanyAdmin.id) ||
                (this.$store.getters.isContractorAdmin &&
                    user.id.toString() === this.$store.state.persons.currentContractorAdmin.id)
            ) {
                return false;
            } else {
                // console.log(
                //     "isPortalAdmin:",
                //     this.$store.getters.isPortalAdmin,
                //     "isContractorAdmin:",
                //     this.$store.getters.isContractorAdmin
                // );
                return (
                    this.$store.getters.isPortalAdmin ||
                    this.$store.getters.isContractorAdmin ||
                    this.$store.getters.isCompanyAdmin
                );
            }
        },

        normalizeField(entry) {
            entry.checked = false;
            if (!entry.company) {
                entry.company = [];
            }
            if (entry.company.length === 0) {
                entry.company.push({ id: null, title: "" });
            }
            if (entry.firstName === null) {
                entry.firstName = "";
            }
            if (entry.lastName === null) {
                entry.lastName = "";
            }
            return entry;
        },

        // sortOn(fieldName) {
        // 	this.users = this.users.sort(function (a, b) {
        // 		if (a[fieldName] > b[fieldName]) {
        // 			return 1;
        // 		}
        // 		if (b[fieldName] > a[fieldName]) {
        // 			return -1;
        // 		}
        // 		return 0;
        // 	});
        // },
        // onHeaderCheckboxChanged(e) {
        // 	this.users.forEach(user => {
        // 		user.checked = e.target.checked;
        // 	});
        // 	//trigger reactivity
        // 	this.users = [].concat(this.users);
        // },

        addUser() {
            this.showAddUserModal = true;
            if (this.craftUserTypeSlugs.includes(CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG)) {
                this.userToEditOrAdd = {
                    company: [{ id: this.$store.state.companies.currentCompany.id }],
                    craftUserTypeSlug: CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG,
                };
            } else {
                this.userToEditOrAdd = {
                    company: [{ id: ALERIS_COMPANY_ID }],
                    craftUserTypeSlug: CRAFT_USER_TYPE.VIEWER_SLUG,
                };
            }
        },
        onCancelPopupUser() {
            this.showAddUserModal = false;
        },
        updateUser(user) {
            this.userToEditOrAdd = user;
            this.showAddUserModal = true;
            // this.$store.dispatch(ACTION_SET_CURRENT_COMPANY, user.company[0]);
            // this.$store.dispatch(ACTION_SET_CURRENT_CONTRACTOR, user);
            // this.$router.push({name: 'onlineContractorProfile'});
        },
        onUserSaveSucceeded(user) {
            console.log("user:", user);
            this.showAddUserModal = false;
            // this.isLoading = true;

            //this.users.push(user);

            this.$apollo.queries.users.refetch();
        },

        refresh() {
            this.$apollo.queries.users.refetch();
        },
        // userCompany(user) {
        // 	if (!user.company || user.company.length === 0) {
        // 		return "";
        // 	}
        // 	return user.company[0].title;
        // },
    },
};
</script>
