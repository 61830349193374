<template>
    <asterisk class="text-xs mx-1 text-primary-dark mb-1" />
</template>
<script>
import Asterisk from "vue-material-design-icons/Asterisk";

export default {
    name: "asterisk-icon",
    components: { Asterisk },
};
</script>
