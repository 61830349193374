<template>
    <div>
        <h3 class="h1 text-primary-normal text-center mb-6 pb-3 border-0 border-b-2" v-trans="'test-here'">
            Test platform
        </h3>
        <p class="text-primary-normal text-center mb-6 md:mb-10" v-trans="'language-select'">Kies uw taal</p>

        <language-list />
        <router-link
            :to="{ name: 'test-overview' }"
            class="btn btn--primary mx-auto block"
            tag="button"
            v-if="$store.state.activeLanguage"
            v-trans="'next'"
        >
            Next
        </router-link>
    </div>
</template>
<script>
import LanguageList from "../../components/LanguageList";

export default {
    name: "LanguageSelection",
    components: { LanguageList },
};
</script>
