<template>
    <div>
        <span>{{ item.title }}</span> <span class="m-4 text-sm text-gray-500">{{ companyName }}</span>
    </div>
</template>

<script>
export default {
    props: {
        item: { required: true },
    },
    computed: {
        companyName() {
            if (this.item.companyName) {
                return this.item.companyName;
            } else if (this.item && this.item.company && this.item.company[0] && this.item.company[0].title) {
                return this.item.company[0].title;
            } else {
                return "";
            }
        },
    },
};
</script>
