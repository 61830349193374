<template>
    <div>
        <!--	Texts
		-------------------------------------------------------------------------- -->
        <section>
            <h1 class="h1">h1. Sample heading</h1>
            <h2 class="h2">h2. Sample heading</h2>
            <h3 class="h3 text-primary-normal">h3. Sample heading</h3>
            <h4 class="h4 text-secondary-normal">h4. Sample heading</h4>
            <h5 class="h5">h5. Sample heading</h5>

            <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquam corporis, ipsum iure nihil numquam,
                pariatur possimus, praesentium reiciendis rem tempora unde? Accusamus ea ex illum maiores nisi repellat
                repudiandae? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquam corporis, ipsum iure
                nihil numquam, pariatur possimus, praesentium reiciendis rem tempora unde? Accusamus ea ex illum maiores
                nisi repellat repudiandae?
            </p>
        </section>

        <!--	Buttons
		-------------------------------------------------------------------------- -->
        <section>
            <router-link tag="button" class="btn" :to="{ name: 'onsite' }">Default</router-link>
            <br />
            <router-link tag="button" class="btn btn--primary" :to="{ name: 'onsite' }">Primary</router-link>
            <router-link tag="button" class="btn btn--icon btn--primary" :to="{ name: 'onsite' }"
                >Primary with icon<ArrowRightIcon
            /></router-link>
            <br />
            <router-link tag="button" class="btn btn--secondary" :to="{ name: 'onsite' }">Secondary</router-link>
            <router-link tag="button" class="btn btn--icon btn--secondary" :to="{ name: 'onsite' }"
                >Secondary with icon<ArrowRightIcon
            /></router-link>
            <br />
            <router-link tag="button" class="btn btn--active" :to="{ name: 'onsite' }">Active</router-link>
            <router-link tag="button" class="btn btn--icon btn--active" :to="{ name: 'onsite' }"
                >Active with icon<ArrowRightIcon
            /></router-link>
            <br />
            <router-link tag="button" class="btn btn--primary btn--lg" :to="{ name: 'onsite' }">Large</router-link>
            <router-link tag="button" class="btn btn--icon btn--primary btn--lg" :to="{ name: 'onsite' }"
                >Large with icon<ArrowRightIcon
            /></router-link>

            <br />
            <router-link tag="button" class="btn btn--card" :to="{ name: 'onsite' }">
                <div>
                    <h4 class="h4">CARD STYLE BUTTON</h4>
                    <ul class="text-black normal-case">
                        <li>Lorem ipsum dolor</li>
                        <li>sit amet, consectetur</li>
                        <li>adipisicing elit</li>
                    </ul>
                </div>
                <ArrowRightIcon />
            </router-link>

            <router-link tag="button" class="btn btn--primary btn--icon w-full mb-5" :to="{ name: 'onsite' }">
                <span>Home</span>
                <ArrowRightIcon />
            </router-link>
        </section>

        <!--	Forms
		-------------------------------------------------------------------------- -->
        <section>
            <form>
                <div class="form-group mb-5">
                    <label>Email address</label>
                    <input type="email" class="form-control" placeholder="Enter email" />
                    <small class="form-text">We'll never share your email with anyone else.</small>
                </div>
                <div class="form-group mb-5">
                    <label>Password</label>
                    <input type="password" class="form-control" placeholder="Password" />
                </div>

                <div class="form-group mb-5">
                    <label>Example select</label>
                    <select class="form-control">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </select>
                </div>

                <div class="flex">
                    <div class="form-group mb-5 w-full mr-5">
                        <label>Email address</label>
                        <input type="email" class="form-control" placeholder="Enter email" />
                        <small class="form-text">We'll never share your email with anyone else.</small>
                    </div>
                    <div class="form-group mb-5 w-full">
                        <label>Password</label>
                        <input type="password" class="form-control" placeholder="Password" />
                    </div>
                </div>

                <div class="flex">
                    <div class="form-group mb-5 w-full mr-5">
                        <label>Email address</label>
                        <input type="email" class="form-control" placeholder="Enter email" />
                        <small class="form-text">We'll never share your email with anyone else.</small>
                    </div>
                    <div class="form-group mb-5 w-full mr-5">
                        <label>Email address</label>
                        <input type="email" class="form-control" placeholder="Enter email" />
                    </div>
                    <div class="form-group mb-5 w-full">
                        <label>Password</label>
                        <input type="password" class="form-control" placeholder="Password" />
                    </div>
                </div>

                <div class="form-check mb-5">
                    <input type="checkbox" class="form-check-input" id="test" />
                    <label class="form-check-label" for="test">Check me out</label>
                </div>
                <button type="submit" class="btn btn--primary btn--icon">Submit<ArrowRightIcon /></button>
            </form>
        </section>

        <!--	Tables
		-------------------------------------------------------------------------- -->

        <section>
            <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>First</th>
                        <th>Last</th>
                        <th>Handle</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>1</th>
                        <td>Value</td>
                        <td>Value</td>
                        <td>Value</td>
                    </tr>
                    <tr>
                        <th>2</th>
                        <td>Value</td>
                        <td>Value</td>
                        <td>Value</td>
                    </tr>
                    <tr>
                        <th>3</th>
                        <td><ArrowRightIcon /></td>
                        <td><dots-horizontal-icon /></td>
                        <td>Value</td>
                    </tr>
                </tbody>
            </table>
        </section>

        <!--	Componenets
		-------------------------------------------------------------------------- -->
        <section>
            <div class="alert alert--danger">This is a primary alert - check it out!</div>
            <div class="alert alert--warning">This is a primary alert - check it out!</div>
            <div class="alert alert--success">This is a primary alert - check it out!</div>
        </section>

        <section>
            <p>Loading animation</p>
            <div class="c-preloader"><i></i><i></i><i></i></div>
        </section>

        <!--	Icons
		-------------------------------------------------------------------------- -->
        <p>
            Look for icons at <br /><a
                class="no-underline hover:underline text-blue-500 text-lg"
                target="_blank"
                href="https://materialdesignicons.com/"
                >https://materialdesignicons.com/</a
            >
        </p>

        <ul class="flex md-icons">
            <li><ArrowRightIcon /></li>
            <li><dots-horizontal-icon /></li>
            <li><star-circle-icon /></li>
        </ul>
    </div>
</template>

<script>
import ArrowRightIcon from "vue-material-design-icons/ArrowRight.vue";
import DotsHorizontalIcon from "vue-material-design-icons/DotsHorizontal.vue";
import StarCircleIcon from "vue-material-design-icons/StarCircle.vue";

export default {
    name: "UiSystem",
    components: {
        ArrowRightIcon,
        DotsHorizontalIcon,
        StarCircleIcon,
    },
};
</script>

<style scoped>
.btn {
    margin-right: 1rem;
    margin-bottom: 1rem;
}

section {
    border-bottom: 1px dashed #bdbdbd;
    padding-bottom: 4rem;
    margin-bottom: 4rem;
}
.md-icons {
    font-size: 5rem;
}
</style>
