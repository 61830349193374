<template>
    <div getr>
        <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
            <label
                >Naam
                <span class="pl-4 underline text-sm text-primary-dark"
                    ><router-link :to="{ name: 'onlineContractorProfile' }">Hier aanpassen</router-link>
                </span></label
            >
            <input class="form-control" type="text" :value="permit.contractor" disabled />
        </div>
        <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
            <label
                >Telefoon
                <span class="pl-4 underline text-sm text-primary-dark"
                    ><router-link :to="{ name: 'onlineContractorProfile' }">Hier aanpassen</router-link>
                </span></label
            >
            <input class="form-control" type="tel" v-model.trim="permit.mobilePhone" disabled />
        </div>
        <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
            <label
                >Nummerplaat
                <span class="pl-4 underline text-sm text-primary-dark"
                    ><router-link :to="{ name: 'onlineContractorProfile' }">Hier aanpassen</router-link>
                </span></label
            >
            <input class="form-control" type="tel" v-model.trim="permit.licensePlate" disabled />
        </div>
        <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
            <label
                >Bedrijf
                <span class="pl-4 underline text-sm text-primary-dark"
                    ><router-link :to="{ name: 'onlineContractorProfile' }">Hier aanpassen</router-link>
                </span></label
            >
            <input class="form-control" type="text" :value="permit.company" disabled />
        </div>
        <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
            <label>Onderaanneming</label>
            <input class="form-control" type="text" v-model.trim="permit.subContractor" />
        </div>
        <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
            <label>Start werken</label>
            <div class="flex" style="gap: 0.5rem">
                <input
                    class="form-control"
                    style="width: 4rem"
                    type="number"
                    v-model="permit.startDate.day"
                    :min="1"
                    :max="31"
                />
                <input
                    class="form-control"
                    style="width: 4rem"
                    type="number"
                    v-model="permit.startDate.month"
                    :min="1"
                    :max="12"
                />
                <input
                    class="form-control"
                    style="width: 5rem"
                    type="number"
                    v-model="permit.startDate.year"
                    :min="2000"
                    :max="2040"
                />
            </div>
        </div>
        <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
            <label>Geldig tot</label>
            <div class="flex" style="gap: 0.5rem">
                <input
                    class="form-control"
                    style="width: 4rem"
                    type="number"
                    v-model="permit.expiryDate.day"
                    :min="1"
                    :max="31"
                />
                <input
                    class="form-control"
                    style="width: 4rem"
                    type="number"
                    v-model="permit.expiryDate.month"
                    :min="1"
                    :max="12"
                />
                <input
                    class="form-control"
                    style="width: 5rem"
                    type="number"
                    v-model="permit.expiryDate.year"
                    :min="2000"
                    :max="2040"
                />
            </div>
        </div>

        <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
            <div class="flex" style="gap: 0.5rem">
                <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
                    <label>Werkleider</label>
                    <input class="form-control" type="text" v-model.trim="permit.workLeader1" />
                </div>
                <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
                    <label>Telefoon</label>
                    <input class="form-control" type="text" v-model.trim="permit.workLeader1Tel" />
                </div>
            </div>
        </div>

        <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
            <div class="flex" style="gap: 0.5rem">
                <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
                    <label>Vervangend werkleider</label>
                    <input class="form-control" type="text" v-model.trim="permit.workLeader2" />
                </div>
                <div class="form-group mb-5 w-full xl:w-1/2 md:mr-5">
                    <label>Telefoon</label>
                    <input class="form-control" type="text" v-model.trim="permit.workLeader2Tel" />
                </div>
            </div>
        </div>

        <div class="form-group flex flex-1 justify-end">
            <button class="btn mr-3 btn--icon h-12" @click="printPermit">
                <span v-trans="'print'">Afdrukken</span>
                <Printer />
            </button>
        </div>

        <permit-to-print :permit="permit" />
    </div>
</template>

<script>
import Printer from "vue-material-design-icons/Printer.vue";
import PermitToPrint from "@/components/PermitToPrint.vue";
import { GET_PERSON_BY_GUID2 } from "@/graphql/queries/Person";

export default {
    name: "EntryPermit",
    components: { PermitToPrint, Printer },
    data() {
        return {
            permit: {
                contractor: "",
                mobilePhone: "",
                licensePlate: "",
                company: "",
                subContractor: "",
                startDate: {
                    day: 1,
                    month: 1,
                    year: 2023,
                },
                expiryDate: {
                    day: 1,
                    month: 1,
                    year: 2023,
                },
                workLeader1: "",
                workLeader1Tel: "",
                workLeader2: "",
                workLeader2Tel: "",
            },
        };
    },
    computed: {},
    // mounted() {
    //     console.log("this.$store.state.persons.currentContractor", this.$store.state.persons.currentContractor);
    // },
    apollo: {
        contractor: {
            query: GET_PERSON_BY_GUID2,
            fetchPolicy: "cache-first",
            variables() {
                return {
                    guid: this.$store.state.persons.currentContractor.guid,
                };
            },
            update(data) {
                console.log("data", data);
                if (data.entries) {
                    const currentContractor = data.entries[0];
                    const today = new Date();
                    let company = currentContractor.companyName;
                    if (currentContractor.company && currentContractor.company.length > 0) {
                        company = currentContractor.company[0].title;
                    }
                    this.permit.contractor = currentContractor.firstName + " " + currentContractor.lastName;
                    this.permit.company = company;
                    this.permit.mobilePhone = currentContractor.mobilePhone;
                    this.permit.licensePlate = currentContractor.licensePlate;

                    this.permit.startDate.day = today.getDate();
                    this.permit.startDate.month = today.getMonth() + 1;
                    this.permit.startDate.year = today.getFullYear();

                    this.permit.expiryDate.day = today.getDate();
                    this.permit.expiryDate.month = today.getMonth() + 1;
                    this.permit.expiryDate.year = today.getFullYear();
                } else {
                    return [];
                }
            },
            watchLoading() {
                //
            },
            skip() {
                return this.$store.state.persons.currentCompanyAdmin.id === null;
            },
        },
    },
    methods: {
        printPermit() {
            let printwindow = window.open("", "PRINT", "height=700,width=800");

            printwindow.document.write("<html><head><title>Inrijvergunning</title>");

            //for production mode
            // document.getElementsByTagName("link").forEach((link) => {
            //     if (link.as === "style") {
            //         const head = printwindow.document.head;
            //         const linkEl = printwindow.document.createElement("link");
            //         linkEl.type = "text/css";
            //         linkEl.rel = "stylesheet";
            //         linkEl.href = link.href;
            //         head.appendChild(linkEl);
            //     }
            // });

            Array.from(document.getElementsByTagName("link")).forEach((link) => {
                if (link.as === "style") {
                    const head = printwindow.document.head;
                    const linkEl = printwindow.document.createElement("link");
                    linkEl.type = "text/css";
                    linkEl.rel = "stylesheet";
                    linkEl.href = link.href;
                    head.appendChild(linkEl);
                }
            });

            //for dev mode
            Array.from(document.getElementsByTagName("style")).forEach((style) => {
                printwindow.document.writeln("<style>" + style.innerText + "</style>");
            });

            // document.getElementsByTagName("link").forEach((el) => console.log(el.as));

            printwindow.document.write('</head><body style="width: auto; height: auto">');
            printwindow.document.write(document.getElementById("print-permit").innerHTML);
            printwindow.document.write("</body></html>");

            printwindow.document.close(); // necessary for IE >= 10
            printwindow.focus(); // necessary for IE >= 10*/

            setTimeout(() => {
                printwindow.print();
                // printwindow.addEventListener("focus", function() {
                // 	alert("Gert: focus:", printwindow);
                // 	printwindow.close();
                // });
            }, 500);

            return true;
        },
    },
};
</script>

<style lang="css"></style>
