<template>
    <div>
        <div class="c-preloader mt-4 c-preloader--fixed-visits" v-show="isLoading"><i></i><i></i><i></i></div>

        <div class="flex w-full mb-4 items-center">
            <div class="" style="flex-grow: 1">
                <h1 class="h5 uppercase text-primary-dark">Admins van andere bedrijven</h1>
            </div>

            <div class="mr-4">
                <button @click="saveExcel" class="flex items-center btn btn btn--primary btn--icon" href="#">
                    <div>Export XLS</div>
                    <ExcelIcon />
                </button>
            </div>

            <div class="">
                <button @click="exportEmails" class="flex items-center btn btn btn--primary btn--icon" href="#">
                    <div>Export Email lijst</div>
                    <MailIcon />
                </button>
            </div>
        </div>

        <div
            class="mb-4 flex items-center alert alert--info"
            v-if="
                this.$store.state.persons.currentCompanyAdmin.id && this.$route.fullPath.indexOf('company-admin') > -1
            "
        >
            <InformationOutline class="text-2xl mr-3 mb-1" />
            <p class="text-sm">
                Om de Company Admins aan te passen: <br />Ga naar
                <strong>Bedrijven > [Bedrijfsnaam] > Adminstrators</strong>
            </p>
        </div>

        <table id="but-data-table" v-if="admins.length > 0">
            <thead>
                <tr class="w-full">
                    <th>Bedrijf</th>
                    <th>Naam</th>
                    <th>Email</th>
                    <th>Taal</th>
                </tr>
            </thead>
            <tr v-for="user in admins" :key="user.id">
                <td>{{ user.companyName }}</td>
                <td>{{ user.firstName }} {{ user.lastName }}</td>
                <td class="text-sm">{{ user.email }}</td>
                <td>{{ user.languageCode.toUpperCase() }}</td>
            </tr>
        </table>

        <div v-else>
            <p>Even geduld ...</p>
        </div>

        <div class="alert alert--warning mt-8" v-show="errorMsg">{{ errorMsg }}</div>

        <dynamic-buttonless-modal @close="message = ''" escapable="false" v-if="message" header-text="Export">
            <template v-slot:content>
                <!--Title-->
                <div class="">
                    <p v-html="message"></p>
                </div>
            </template>
        </dynamic-buttonless-modal>
    </div>
</template>

<script>
import { GET_USERS } from "@/graphql/queries/User";
import { Utils } from "@/utils/utils";
import ExcelIcon from "vue-material-design-icons/FileExcelOutline";
import MailIcon from "vue-material-design-icons/EmailOutline.vue";
import InformationOutline from "vue-material-design-icons/InformationOutline.vue";
import XLSX from "xlsx";
import moment from "moment";
import DynamicButtonlessModal from "@/components/DynamicButtonlessModal.vue";
import { CRAFT_USER_TYPE } from "@/store/constants";

export default {
    name: "ContractoradminsList",
    components: { DynamicButtonlessModal, ExcelIcon, MailIcon, InformationOutline },
    data() {
        return {
            isLoading: false,
            errorMsg: "",
            admins: [],
            message: "",
        };
    },

    mounted() {},
    apollo: {
        admins() {
            return {
                query: GET_USERS,
                fetchPolicy: "cache-and-network",
                variables() {
                    return {
                        userIds: null,
                        companyId: null,
                        craftUserTypeSlugs: [CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG],
                    };
                },
                update(data) {
                    if (data.users) {
                        // console.log("users", data.users);
                        let r = data.users; //.filter((u) => u.company && u.company.length > 0); //filter out empty companies (because company is archived)

                        r.forEach((u) => (u.companyName = u.company && u.company.length > 0 ? u.company[0].title : ""));
                        r = Utils.sortArrayOfObjects(r, ["companyName", "lastName", "firstName"]);
                        return r;
                    } else {
                        return [];
                    }
                },
                watchLoading(isLoading) {
                    this.isLoading = isLoading;
                },
                error(e) {
                    if (e.networkError && parseInt(e.networkError.response.status) === 401) {
                        this.errorMsg = this.$trans.getTranslation("not-authorized-relogin");
                    } else {
                        this.errorMsg = JSON.stringify(e);
                    }
                },
            };
        },
    },

    methods: {
        saveExcel() {
            const elTable = document.getElementById("but-data-table");
            const workbook = XLSX.utils.table_to_book(elTable, { sheet: "Export Contractor Admins" });
            XLSX.writeFile(workbook, `Contractor Admins ${moment().format("DD-MM-YYYY hhumm")}.xlsx`);
            this.message = "De Excel werd opgelagen in je downloads folder.";
        },
        exportEmails() {
            const emailList = this.admins.map((u) => u.email).join(",");
            navigator.clipboard.writeText(emailList).then(
                () => {
                    console.log("Content copied to clipboard", emailList);
                    // alert("Alle emails werden naar het clipboard gekopieerd.");
                    this.message = `<p class="mb-4 font-bold">Alle emails werden naar het clipboard gekopieerd.</p><p class="text-sm">${emailList
                        .split(",")
                        .join(", ")}</p>`;
                },
                () => {
                    this.message = "Failed to copy";
                    /* Rejected - text failed to copy to the clipboard */
                }
            );
        },
    },
};
</script>
