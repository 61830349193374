<template>
    <div class="mx-auto md:w-1/2 m-4">
        <form v-if="requireLogin" @submit.prevent="login()">
            <div class="alert alert--danger" v-show="error !== ''">{{ error }}</div>
            <p class="text-xl mb-4 text-primary-normal">
                Contacteer een Aluminimum Duffel verantwoordelijke om deze tool op te starten.
            </p>
            <div class="form-group">
                <label>Login naam</label>
                <input class="form-control" type="text" v-model="loginName" />
            </div>
            <div class="form-group mb-4">
                <label>Password</label>
                <input class="form-control" type="password" v-model="password" />
            </div>

            <button v-if="!isLoading" class="mobile-button block w-full btn btn--icon btn--secondary">
                <span v-trans="'log-in'">Log in</span>
                <LockOpenOutline />
            </button>
        </form>
        <div v-if="isLoading" class="c-preloader w-full text-center" style="left: 15%"><i></i><i></i><i></i></div>
    </div>
</template>
<script>
import LockOpenOutline from "vue-material-design-icons/LockOpenOutline.vue";
import { ACTION_SET_ANONYMOUS_LOGIN_INFO, ACTION_SET_ONSITE_SELECTION } from "../../store/constants";
import { Utils } from "@/utils/utils";

export default {
    name: "TestHome",
    components: {
        LockOpenOutline,
    },
    data() {
        return {
            error: "",
            isLoading: false,
            loginName: "",
            password: "",

            requireLogin: false,
        };
    },
    mounted() {
        // Check if some login info is set. If so, auto-login
        if (this.$store.state.visit.anonymousLoginInfo !== null) {
            this.executeLogin();
        } else {
            this.requireLogin = true;
        }
    },
    methods: {
        login() {
            this.isLoading = true;
            this.$store.commit(ACTION_SET_ANONYMOUS_LOGIN_INFO, {
                loginName: this.loginName,
                password: this.password,
            });
            localStorage.onsitelogin = Utils.hideIt(this.$store.state.visit.anonymousLoginInfo);
            this.executeLogin();
        },
        executeLogin() {
            this.$axios
                .post("/", {
                    ...Utils.revealIt(this.$store.state.visit.anonymousLoginInfo),
                    action: "users/login",
                    withCredentials: true, // If true, send cookie stored in jar
                })
                .then((response) => {
                    if (response.data.error) {
                        this.error = response.data.error;
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                        this.setupComplete();
                    }
                })
                .catch((error) => {
                    // console.log(error);
                    this.error = error.message;
                    this.isLoading = false;
                });
        },
        savePreferences() {
            this.$store.commit(ACTION_SET_ONSITE_SELECTION, this.preferences);
            localStorage.onsiteselectionV3 = JSON.stringify(this.$store.state.visit.onsiteSelection);
            this.setupComplete();
        },
        setupComplete() {
            this.$router.push({ name: "test-languageSelect" });
        },
    },
};
</script>
