<template>
    <!--WARNING-->
    <!--this component is used at several places!-->

    <!--Modal-->
    <div
        class="modal fixed overflow-auto w-full h-full top-0 left-0 flex items-center justify-center z-50"
        :class="{ 'limited-height': limitHeight }"
    >
        <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 left-0 top-0"></div>

        <div class="modal-container bg-white w-11/12 max-w-2xl rounded shadow-lg z-50 flex flex-col">
            <div
                class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-sm z-50"
                :class="{ 'text-white': headerText != '' }"
                @click="cancel()"
                v-show="escapable"
            >
                <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <path
                        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                    ></path>
                </svg>
                <!--                <span class="text-sm">(Esc)</span>-->
            </div>

            <!--Title-->
            <div class="modal-header" v-if="headerText !== ''">{{ headerText }}</div>

            <!-- Add margin if you want to see some of the overlay behind the modal-->
            <div class="modal-content py-4 text-left px-6">
                <!--Title-->

                <div class="pb-3">
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DynamicButtonlessModal",

    props: {
        escapable: {
            default: true,
        },
        headerText: {
            default: "",
        },
        limitHeight: {
            default: false,
        },
    },

    methods: {
        confirm() {
            this.$emit("confirm");
        },
        cancel() {
            if (this.escapable) this.$emit("close");
        },
    },
};
</script>
