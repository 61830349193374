<template>
    <ContractorList :for-contractor-company="false" />
</template>

<script>
import ContractorList from "../../components/ContractorList";
export default {
    name: "CompanyAdminContractorList",
    components: { ContractorList },
};
</script>
