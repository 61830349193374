<template>
    <div class="mx-auto md:w-1/2 m-4">
        <div v-if="requireLogin">
            <div class="alert alert--danger" v-show="error !== ''">{{ error }}</div>
            <p class="text-xl mb-4 text-primary-normal">
                Contacteer een Aluminimum Duffel verantwoordelijke om deze tool op te starten.
            </p>
            <div class="form-group">
                <label>Login naam</label>
                <input class="form-control" type="text" v-model="loginName" />
            </div>
            <div class="form-group mb-4">
                <label>Password</label>
                <input class="form-control" type="password" v-model="password" />
            </div>

            <button @click="login()" class="mobile-button block w-full btn btn--icon btn--secondary" v-if="!isLoading">
                <span v-trans="'log-in'">Log in</span>
                <LockOpenOutline />
            </button>
        </div>
        <div class="c-preloader w-full text-center" style="left: 15%" v-if="isLoading"><i></i><i></i><i></i></div>

        <div v-if="requirePreferences">
            <p class="text-xl mb-2">Welk type van bezoekers moet op dit toestel inchecken ?</p>
            <div class="form-group mb-2" v-for="profile in preferences" :key="profile.slug">
                <label class="checkbox">
                    <div>
                        <input type="checkbox" v-model="profile.value" value="1" />
                        <svg viewBox="0 0 172 172">
                            <g
                                fill="none"
                                font-family="none"
                                font-size="none"
                                font-weight="none"
                                stroke-miterlimit="10"
                                stroke-width="none"
                                style="mix-blend-mode: normal"
                                text-anchor="none"
                            >
                                <path d="M0 172V0h172v172z" />
                                <path
                                    d="M145.433 37.933L64.5 118.7558 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                                    fill="currentColor"
                                    stroke-width="1"
                                />
                            </g>
                        </svg>
                    </div>
                    <span v-trans="'visit-type-' + profile.slug">Visitor</span>
                </label>
            </div>

            <div class="text-sm my-1">Eens deze keuze gemaakt is wordt dit opgeslagen voor dit toestel.</div>
            <div class="text-sm my-1">Je kan dit steeds ongedaan maken door de cookies te wissen.</div>

            <button @click="savePreferences()" class="mobile-button block w-full btn btn--secondary mt-4">
                <span>Opslaan</span>
            </button>
        </div>
    </div>
</template>
<script>
import LockOpenOutline from "vue-material-design-icons/LockOpenOutline.vue";
import { ACTION_SET_ANONYMOUS_LOGIN_INFO, ACTION_SET_ONSITE_SELECTION } from "../../store/constants";
import { GET_ALL_VISITOR_TYPES } from "@/graphql/queries/Visit";
import { Utils } from "@/utils/utils";

export default {
    name: "OnsiteHome",
    components: {
        LockOpenOutline,
    },
    data() {
        return {
            error: "",
            isLoading: false,
            loginName: "",
            password: "",

            requireLogin: false,
            requirePreferences: false,
        };
    },
    computed: {
        preferences() {
            return this.visitTypes.map((type) => {
                return {
                    slug: type.slug,
                    value: false,
                };
            });
        },
    },
    apollo: {
        visitTypes() {
            return {
                query: GET_ALL_VISITOR_TYPES,
                fetchPolicy: "cache-and-network",
                variables() {
                    return {};
                },
                update(data) {
                    /**
                     * example: {
                     *     "id": "114",
                     *     "slug": "visitor-office",
                     *     "title": "Visitor Office",
                     * }
                     */
                    return data.categories || [];
                },
                watchLoading(isLoading) {
                    this.isLoading = isLoading;
                },
                error(e) {
                    if (e.networkError && parseInt(e.networkError.response.status) === 401) {
                        this.errorMsg = this.$trans.getTranslation("not-authorized-relogin");
                    } else {
                        this.errorMsg = JSON.stringify(e);
                    }
                },
            };
        },
    },
    async mounted() {
        // Check if some login info is set and we are on v3. If so, auto-login
        if (this.$store.state.visit.anonymousLoginInfo && localStorage.onsiteselectionV3) {
            this.executeLogin();
        } else {
            this.requireLogin = true;
        }
    },
    methods: {
        login() {
            this.$store.commit(
                ACTION_SET_ANONYMOUS_LOGIN_INFO,
                Utils.hideIt({
                    loginName: this.loginName,
                    password: this.password,
                })
            );
            localStorage.onsitelogin = this.$store.state.visit.anonymousLoginInfo;
            this.executeLogin();
        },
        executeLogin() {
            console.log("executeLogin");
            this.isLoading = true;
            this.$axios
                .post("/", {
                    ...Utils.revealIt(this.$store.state.visit.anonymousLoginInfo),
                    action: "users/login",
                    withCredentials: true, // If true, send cookie stored in jar
                })
                .then((response) => {
                    if (response.data.error) {
                        this.error = response.data.error;
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                        this.requireLogin = false;
                        if (this.$store.getters.getPreferences !== false) {
                            this.setupComplete();
                        } else {
                            this.requirePreferences = true;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.error = error.message;
                    this.isLoading = false;
                });
        },
        savePreferences() {
            this.$store.commit(ACTION_SET_ONSITE_SELECTION, this.preferences);
            localStorage.onsiteselectionV3 = JSON.stringify(this.$store.state.visit.onsiteSelection);
            this.setupComplete();
        },
        setupComplete() {
            this.$router.push({ name: "onsite-languageSelect" });
        },
    },
};
</script>
