<template>
    <ContractorList :for-contractor-company="true" />
</template>

<script>
import ContractorList from "../../components/ContractorList";

export default {
    name: "ContractorAdminContractorList",
    components: { ContractorList },
};
</script>
