import gql from "graphql-tag";

//done
export const GET_PERSONS2 = gql`
    query companyadministrator_GetPersons2($limit: Int, $status: [String], $search: String, $orderBy: String) {
        entries(section: "person", limit: $limit, status: $status, search: $search, orderBy: $orderBy) {
            ... on person_person_Entry {
                id
                firstName
                lastName
                email
                guid
                company {
                    id
                    title
                }
                companyName
                dateCreated
                status
                learningTrackResults(status: "", orderBy: "id") {
                    ... on learningTrackResult_learningTrackResult_Entry {
                        id
                        expiryDate
                        dateUpdated
                        status
                        learningTrack {
                            slug
                            title
                        }
                        learningTrackResultStatus {
                            id
                            slug
                        }
                        lastAttemptDate @formatDateTime(format: "y-m-d")
                    }
                }
                safetyViolation
            }
        }
    }
`;

//done
export const GET_PERSONS_FROM_COMPANY2 = gql`
    query contractoradministrator_PersonsFromCompany2(
        $companyId: [QueryArgument]
        $status: String
        $search: String
        $orderBy: String
    ) {
        entries(section: ["person"], relatedTo: $companyId, status: [$status], search: $search, orderBy: $orderBy) {
            dateCreated
            ... on person_person_Entry {
                id
                firstName
                lastName
                email
                guid
                company {
                    id
                    title
                }
                dateCreated
                status
                safetyViolation
                learningTrackResults(status: "", orderBy: "id") {
                    ... on learningTrackResult_learningTrackResult_Entry {
                        id
                        expiryDate
                        dateUpdated
                        status
                        learningTrack {
                            slug
                            title
                        }
                        learningTrackResultStatus {
                            id
                            slug
                        }
                        lastAttemptDate @formatDateTime(format: "y-m-d")
                    }
                }
            }
        }
    }
`;

//done
export const GET_PERSON_BY_GUID2 = gql`
    query contractor_GetPersonByGuid2($guid: [QueryArgument]) {
        entries(section: "person", limit: 1, guid: $guid) {
            ... on person_person_Entry {
                id
                firstName
                lastName
                email
                guid
                licensePlate
                companyName
                mobilePhone
                info
                company {
                    id
                    title
                }
                languageCategorie {
                    id
                    title
                }
                photo {
                    id
                    url
                }
                safetyViolation
                learningTrackResults(status: "", orderBy: "id") {
                    ... on learningTrackResult_learningTrackResult_Entry {
                        id
                        expiryDate
                        dateUpdated
                        status
                        learningTrack {
                            id
                            title
                            slug
                        }
                        learningTrackResultStatus {
                            id
                            slug
                        }
                        lastAttemptDate @formatDateTime(format: "y-m-d")
                    }
                }
            }
        }
    }
`;

//done
export const GET_LEARNING_TRACKS_FOR_PERSON_BY_ID = gql`
    query contractor_GetLearningTracksForPersonById($id: [QueryArgument]) {
        entries(section: ["person"], limit: 1, id: $id) {
            ... on person_person_Entry {
                id
                learningTrackResults(status: "", orderBy: "id") {
                    ... on learningTrackResult_learningTrackResult_Entry {
                        id
                        expiryDate
                        dateUpdated
                        status
                        learningTrack {
                            id
                            title
                            slug
                        }
                        learningTrackResultStatus {
                            id
                            slug
                        }
                        lastAttemptDate @formatDateTime(format: "y-m-d")
                    }
                }
            }
        }
    }
`;

//done
export const GET_PERSON_BY_NAME2 = gql`
    query onsite_GetPersonByName2($search: String) {
        entries(section: ["person"], search: $search) {
            ... on person_person_Entry {
                id
                title
                firstName
                lastName
                company {
                    id
                    title
                }
                companyName
                licensePlate
                mobilePhone
                languageCategorie {
                    id
                    title
                }
                photo {
                    id
                    url
                }
                safetyViolation
            }
        }
    }
`;

//done
export const GET_INDIVIDUAL_PERSON_RESULT_STATUS = gql`
    query onsite_GetPersonLTR($id: [QueryArgument]) {
        entries(section: ["person"], id: $id, orderBy: "lastAttemptDate") {
            ... on person_person_Entry {
                learningTrackResults(status: "", orderBy: "id") {
                    ... on learningTrackResult_learningTrackResult_Entry {
                        id
                        dateUpdated
                        expiryDate
                        lastAttemptDate
                        status
                        learningTrack {
                            id
                            title
                            slug
                        }
                        learningTrackResultStatus {
                            id
                            slug
                        }
                    }
                }
            }
        }
    }
`;

//done
export const GET_PERSON_LEARNING_TRACKS = gql`
    query contractor_GetLearningTrackForPerson($id: [QueryArgument]) {
        entries(section: "Person", limit: 1, id: $id) {
            ... on person_person_Entry {
                id
                firstName
                lastName
                guid
                learningTrackResults(status: "", orderBy: "id DESC") {
                    ... on learningTrackResult_learningTrackResult_Entry {
                        id
                        lastAttemptDate
                        expiryDate
                        dateUpdated
                        status
                        learningTrackResultStatus {
                            id
                            slug
                            title
                        }
                        learningTrack {
                            id
                            slug
                            title
                            ... on learningTrack_learningTrack_Entry {
                                id
                                description
                                sco {
                                    ... on simpleCourseObject_simpleCourseObject_Entry {
                                        id
                                        title
                                        coursePath
                                    }
                                }
                            }
                        }
                        scoResults {
                            ... on scoResult_scoResult_Entry {
                                id
                                scoStatus {
                                    slug
                                }
                                attempts
                                suspendData
                                sco {
                                    ... on simpleCourseObject_simpleCourseObject_Entry {
                                        id
                                        title
                                        coursePath
                                    }
                                }
                                score
                            }
                        }
                    }
                }
                company {
                    id
                    title
                }
            }
        }
    }
`;

//done
export const SAVE_PERSON2 = gql`
    mutation contractor_SavePerson2(
        $id: ID
        $email: String
        $firstName: String
        $lastName: String
        $companyIds: [Int]
        $languageIds: [Int]
        $photoIds: [Int]
        $companyName: String
        $licensePlate: String
        $mobilePhone: String
        $info: String
        $certificateIds: [Int]
        $safetyViolation: Boolean
    ) {
        save_person_person_Entry(
            id: $id
            email: $email
            firstName: $firstName
            lastName: $lastName
            company: $companyIds
            languageCategorie: $languageIds
            companyName: $companyName
            licensePlate: $licensePlate
            photo: $photoIds
            mobilePhone: $mobilePhone
            info: $info
            certificates: $certificateIds
            safetyViolation: $safetyViolation
        ) {
            id
            firstName
            lastName
            email
            licensePlate
            guid
            company {
                id
                title
            }
            languageCategorie {
                id
                title
            }
            photo {
                id
                url
            }
            certificates {
                ... on certificates_Asset {
                    id
                    title
                    url
                    expiryDate
                    dateCreated
                }
            }
            mobilePhone
            info
            status
            safetyViolation
        }
    }
`;

//done
export const CREATE_LEARNING_TRACK_RESULT = gql`
    mutation contractoradministrator_UpsertLearningTrackResult(
        $id: [Int]
        $learningTrack: [Int]
        $statusId: Int
        $expiryDate: DateTime
    ) {
        save_learningTrackResult_learningTrackResult_Entry(
            person: $id
            learningTrack: $learningTrack
            learningTrackResultStatus: [$statusId]
            expiryDate: $expiryDate
        ) {
            id
            expiryDate
            dateUpdated
            status
            learningTrack {
                id
                slug
            }
            learningTrackResultStatus {
                id
                slug
            }
            lastAttemptDate @formatDateTime(format: "y-m-d")
        }
    }
`;

//done
//hardcoded values are used because, if we use the constants, we can not auto-generate the GraphQL whitelist :(
export const CREATE_LEARNING_TRACK_RESULT_INVITE = gql`
    mutation contractor_UpsertLearningTrackResultInvite($id: [Int]) {
        save_learningTrackResult_learningTrackResult_Entry(
            person: $id
            learningTrack: 28
            learningTrackResultStatus: [194]
        ) {
            id
            expiryDate
            dateUpdated
            status
            learningTrack {
                id
                title
                slug
            }
            learningTrackResultStatus {
                id
                slug
            }
            lastAttemptDate @formatDateTime(format: "y-m-d")
        }
    }
`;

export const CREATE_LEARNING_TRACK_RESULT_INVITE2 = gql`
    mutation contractor_UpsertLearningTrackResultInvite2($id: [Int], $learningTrackId: [Int]) {
        save_learningTrackResult_learningTrackResult_Entry(
            person: $id
            learningTrack: $learningTrackId
            learningTrackResultStatus: [194]
        ) {
            id
            expiryDate
            dateUpdated
            status
            learningTrack {
                id
                title
                slug
            }
            learningTrackResultStatus {
                id
                slug
            }
            lastAttemptDate @formatDateTime(format: "y-m-d")
        }
    }
`;

//done
export const GET_PERSON_CERTIFICATES = gql`
    query contractor_GetPersonCertificate($personId: [QueryArgument]) {
        entry(id: $personId) {
            ... on person_person_Entry {
                id
                certificates {
                    ... on certificates_Asset {
                        id
                        title
                        url
                        expiryDate
                        dateCreated
                        extension
                    }
                }
            }
        }
    }
`;
