<template>
    <div>
        <h3 class="h3 text-center mb-3 md:mb-5"><span v-trans="'welcome'">Welkom</span>!</h3>

        <p class="text-center mb-6 md:mb-10" v-trans="'kind-of-user-info'">
            Als welk type gebruiker wil je verder gaan?
        </p>

        <router-link
            :to="{ name: 'Admin' }"
            class="absolute right-0 top-0 text-gray-500 border hover:bg-gray-200 p-1 rounded-sm"
            >Admin</router-link
        >

        <div class="flex flex-col md:flex-row mb-8">
            <router-link
                :to="{ name: 'onlineContractor' }"
                class="mx-4 btn btn--card md:w-1/2 text-left mb-8 md:mb-0"
                tag="button"
            >
                <div>
                    <h4 class="h4" v-trans="'type-user-contractor'">Contractor medewerker</h4>
                    <ul class="text-black normal-case">
                        <li v-trans="'contractor-task1'">Voer veiligheidstesten uit</li>
                        <li v-trans="'contractor-task2'">Beheer uw gegevens</li>
                        <li v-trans="'contractor-task3'">Beheer certificaten</li>
                    </ul>
                </div>
                <arrow-right-icon />
            </router-link>
            <router-link
                :to="{ name: 'onlineContractorAdmin' }"
                class="mx-4 btn btn--card md:w-1/2 text-left"
                tag="button"
            >
                <div>
                    <h4 class="h4" v-trans="'type-user-contractor-admin'">Contractor beheerder</h4>
                    <ul class="text-black normal-case">
                        <li v-trans="'contractor-admin-task1'">Beheer de toegang voor uw werknemers</li>
                        <li v-trans="'contractor-admin-task2'">Beheer certificaten</li>
                    </ul>
                </div>
                <arrow-right-icon />
            </router-link>
        </div>

        <div class="flex flex-col md:flex-row mb-5">
            <router-link
                :to="{ name: 'onlineAdmin' }"
                class="mx-4 btn btn--card md:w-full text-left mb-8 md:mb-0"
                tag="button"
            >
                <div>
                    <h4 class="h4" v-trans="'type-user-admin'">Aluminimum Duffel Administrator</h4>
                    <ul class="text-black normal-case">
                        <li v-trans="'admin-task1'">Raadplegen van alle gebruikers en administrators</li>
                        <li v-trans="'admin-task2'">
                            Beheer van alle gebruikers en administrators (indien je rechten dit toelaten.)
                        </li>
                        <li v-trans="'admin-task3'">Alle andere administratieve taken</li>
                    </ul>
                </div>
                <arrow-right-icon />
            </router-link>
        </div>
    </div>
</template>

<script>
import ArrowRightIcon from "vue-material-design-icons/ArrowRight.vue";

export default {
    name: "OnlineHome",
    components: { ArrowRightIcon },
};
</script>
