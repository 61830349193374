<template>
    <!--Modal-->
    <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 left-0 top-0" @click="cancel()"></div>

        <div class="modal-container bg-white w-10/12 lg:max-w-xl rounded shadow-lg z-50 overflow-y-auto">
            <div
                class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-sm z-50"
                @click="cancel()"
                v-show="escapable"
            >
                <svg
                    class="fill-current text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                >
                    <path
                        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                    ></path>
                </svg>
                <!--                <span class="text-sm">(Esc)</span>-->
            </div>
            <!--Title-->
            <div class="modal-header" v-trans="'signature'">Handtekening</div>
            <p class="text-center pt-4" v-trans="'signature-info'">
                Teken op het scherm om je handtekening te plaatsen
            </p>
            <!-- Add margin if you want to see some of the overlay behind the modal-->
            <div class="modal-content text-left p-4">
                <!--Title-->

                <div class="flex justify-between items-center text-2xl">
                    <vue-signature-pad
                        width="100%"
                        height="400px"
                        ref="signaturePad"
                        :options="options"
                        class="border"
                    ></vue-signature-pad>
                </div>
            </div>

            <div class="modal-actions">
                <button class="btn btn--secondary" @click="undo">
                    <undo-icon /> <span v-trans="'retry'">Opnieuw</span>
                </button>
                <button
                    class="btn"
                    @click="save"
                    :class="{ 'opacity-50': !saveEnabled, 'btn--secondary': !saveEnabled, 'btn--primary': saveEnabled }"
                >
                    <check-icon :size="40" /> <span v-trans="'confirm'">Bevestigen</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import VueSignaturePad from "vue-signature-pad";
// import ClearIcon from 'vue-material-design-icons/Close';
import CheckIcon from "vue-material-design-icons/Check";
import UndoIcon from "vue-material-design-icons/Undo";

export default {
    name: "Autograph",
    components: {
        VueSignaturePad,
        CheckIcon,
        UndoIcon,
    },
    data() {
        return {
            /*
                DEFAULT_OPTIONS
                  dotSize: (0.5 + 2.5) / 2,
                  minWidth: 0.5,
                  maxWidth: 2.5,
                  throttle: 16,
                  minDistance: 5,
                  backgroundColor: 'rgba(0,0,0,0)',
                  penColor: 'black',
                  velocityFilterWeight: 0.7,
                  onBegin: () => {},
                  onEnd: () => {}
                 */
            saveEnabled: false,
            options: {
                minWidth: 1,
                maxWidth: 3,
                // color: '0xffce00',
                onEnd: () => {
                    this.saveEnabled = true;
                },
            },
        };
    },
    props: {
        escapable: {
            default: true,
        },
    },
    methods: {
        save() {
            if (!this.saveEnabled) return;
            // Upload asset
            // then
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if (!isEmpty) this.$emit("confirm", data);
        },
        undo() {
            // check docs signaturepad to clear drawing
            this.$refs.signaturePad.clearSignature();
            this.saveEnabled = false;
        },
        cancel() {
            this.$emit("cancel");
        },
    },
};
</script>
