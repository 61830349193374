<template>
    <dynamic-buttonless-modal @close="cancel" escapable="false">
        <template v-slot:content>
            <!--Title-->
            <div class="flex justify-between items-center pb-3">
                <p class="text-2xl font-bold">{{ caption }}</p>
            </div>

            <!--Body-->
            <certificate-edit
                :certificate="certificate"
                :show-cancel-button="true"
                @cancel="cancel"
                :is-new="!certificate.id"
                @saveSucceeded="saveSucceeded"
                :is-company="isCompany"
            />
        </template>
    </dynamic-buttonless-modal>
</template>

<script>
import DynamicButtonlessModal from "./DynamicButtonlessModal";
import CertificateEdit from "./CertificateEdit";

export default {
    name: "AddOrEditCertificate",
    components: { CertificateEdit, DynamicButtonlessModal },
    props: {
        caption: {
            required: true,
        },
        certificate: {
            required: true,
        },
        isCompany: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            error: "",
        };
    },

    methods: {
        saveSucceeded(person) {
            this.$emit("saveSucceeded", person);
        },

        cancel() {
            this.$emit("cancel");
        },
    },
};
</script>
