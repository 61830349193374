<template>
    <craft-user-edit
        :craft-user-type-slug="userTypeSlug"
        :user="user"
        :show-cancel-button="false"
        :profile-is-editable="false"
        @saveSucceeded="onEditUserSucceeded(user)"
    />
</template>

<script>
import CraftUserEdit from "../../components/CraftUserEdit";
import {
    ACTION_SET_CURRENT_COMPANY_ADMIN,
    ACTION_SET_CURRENT_CONTRACTOR_ADMIN,
    CRAFT_USER_TYPE,
} from "../../store/constants";

export default {
    name: "CompanyAdminProfile",
    components: { CraftUserEdit },
    data() {
        return {
            user: {},
            storeSubscription: null,
            userTypeSlug: this.$store.state.persons.currentCompanyAdmin.id
                ? CRAFT_USER_TYPE.COMPANY_ADMINISTRATOR_SLUG
                : CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG,
        };
    },

    mounted() {
        this.user = { ...this.$store.state.persons.currentContractorAdmin };
        this.storeSubscription = this.$store.subscribe((mutation) => {
            if (mutation.type === ACTION_SET_CURRENT_COMPANY_ADMIN && mutation.payload !== null) {
                this.user = { ...this.$store.state.persons.currentContractorAdmin };
            }
        });
    },

    beforeDestroy() {
        // remove the subscription
        this.storeSubscription();
    },

    methods: {
        onEditUserSucceeded(user) {
            if (this.userTypeSlug === CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG) {
                this.$store.dispatch(ACTION_SET_CURRENT_CONTRACTOR_ADMIN, user);
            } else {
                console.log("onEditUserSucceeded", user);
                this.$store.dispatch(ACTION_SET_CURRENT_COMPANY_ADMIN, user);
            }
            this.$emit("saveSucceeded", user);
        },
    },
};
</script>
