<template>
    <div class="form-wrap">
        <back-button></back-button>

        <div v-show="this.person === null">
            <div class="inner-form">
                <div class="mb-4 form-group">
                    <div class="form-label font-bold text-center mb-3" v-trans="'name-visitor-helper'">
                        Voer de eerste 3 letters van je familienaam of voornaam in.
                    </div>
                    <div class="w-2/3 xl:w-1/2 mx-auto">
                        <vue-suggestion
                            :itemTemplate="dropdownItemTemplate"
                            :items="filteredPersons"
                            :setLabel="setPersonLabel"
                            @onInputChange="personInputChange"
                            @onItemSelected="personItemSelected"
                            ref="searchField"
                            v-model="person"
                        >
                        </vue-suggestion>
                    </div>
                    <div class="mt-3 text-center" v-if="registrationNotFound">
                        <p v-trans="'registration-not-found'">We kunnen uw gegevens niet terugvinden.</p>
                        <p v-trans="'registration-contractor-not-found'">
                            Gelieve de baliemedewerker te contacteren om uw gegevens aan het systeem toe te voegen.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="this.person !== null" class="flex">
            <div class="w-1/3 xl:w-1/4 pr-8">
                <div class="border p-4 rounded-lg">
                    <div class="mb-2 font-bold" v-trans="'first-name'">Voornaam</div>
                    <div class="mb-4">{{ firstName }}</div>

                    <div class="mb-2 font-bold" v-trans="'last-name'">Achternaam</div>
                    <div class="mb-4">{{ lastName }}</div>

                    <div class="mb-2 font-bold" v-trans="'company'">Bedrijf</div>
                    <div class="mb-4">{{ companyName }}</div>

                    <!--						<div class="mb-2 font-bold" v-trans="'code'">Persoonlijke code</div>-->
                    <!--						<div class="mb-6">{{guid}}</div>-->

                    <div class="border-t-2 pt-4 mt-2 opacity-75 mt-3 text-center">
                        <span class="text-sm" v-trans="'not-your-data'">Dit zijn niet uw gegevens?</span>
                        <button
                            class="btn mt-2 text-sm"
                            v-if="this.person !== null"
                            @click="resetSearch"
                            v-trans="'search-again'"
                        >
                            Opnieuw zoeken
                        </button>
                    </div>
                </div>
            </div>
            <div class="w-2/3 xl:w-3/4">
                <ContractorLearningPathOverview v-on:ended="courseFinishedHandler"></ContractorLearningPathOverview>
            </div>
        </div>
    </div>
</template>
<script>
import VueSuggestion from "../../components/SuggestionBox";
import dropdownItemTemplate from "../../components/DropdownItemTemplate";
import { NOT_ATTEMPTED_lEARNING_TRACKS } from "../../graphql/queries/LearningTrack";
import BackButton from "../../components/BackButton";
import { ACTION_SET_CURRENT_CONTRACTOR } from "../../store/constants";
import ContractorLearningPathOverview from "../online/ContractorLearningPathOverview";

export default {
    name: "Overview",
    components: {
        ContractorLearningPathOverview,
        VueSuggestion,
        BackButton,
    },
    created() {
        // this.$store.dispatch(ACTION_SET_CURRENT_CONTRACTOR, {});
    },
    mounted() {
        this.getUserData();

        // console.log("Gert: Mounted");

        setTimeout(() => {
            if (this.$refs.searchField) {
                this.$refs.searchField.$refs.searchField.focus();
            }
        }, 400);
        //
        // setTimeout(() => {
        // 	this.$el.getElementsByClassName("vue-suggestion-input")[0].focus();
        // }, 200);
    },
    data() {
        return {
            person: null, // REFERENCE TO PERSON DB OBJECT,
            firstName: "",
            lastName: "",
            companyName: "",
            guid: "",
            showPersonDetailFields: false,

            personQuery: "",
            dropdownItemTemplate,
            persons: [],
        };
    },

    computed: {
        registrationNotFound() {
            return this.personQuery.length >= 3 && this.filteredPersons.length === 0;
        },
        filteredPersons() {
            if (this.personQuery.length < 3) return [];
            return this.persons.filter((person) => {
                return (
                    person !== undefined &&
                    (person.firstName.toLowerCase().match(this.personQuery.toLowerCase()) ||
                        person.lastName.toLowerCase().match(this.personQuery.toLowerCase()))
                );
            });
        },
    },

    methods: {
        // REGISTERED PERSON SEARCH AUTOCOMPLETE FUNCTIONS
        setPersonLabel(item) {
            return item.title;
        },
        getUserData() {
            // console.log("Gert: getting data");
            this.$apollo
                .query({
                    query: NOT_ATTEMPTED_lEARNING_TRACKS,
                    fetchPolicy: "no-cache",
                })
                .then(
                    (result) => {
                        console.log("result.data", result.data);
                        const persons = [];
                        // Loop over entries
                        const entries = result.data.entries;
                        for (let i = 0, l = entries.length; i < l; i++) {
                            if (entries[i].person.length > 0) {
                                entries[i].person.forEach((person) => {
                                    //only push if not already in array
                                    if (!persons.some((p) => p.guid === person.guid)) {
                                        persons.push(person);
                                    }
                                });
                            }
                        }

                        this.persons.splice(0, 0, ...persons);
                        // this.unatemptedLearningTracks
                    },
                    (error) => {
                        if (error.gqlError !== null) {
                            if (error.message === "GraphQL error: Not authorized") {
                                this.$router.push({ name: "test-home" });
                            }
                        }
                        if (error.networkError && error.networkError.statusCode === 401) {
                            // console.log("Gert: :");
                            this.$router.push({ name: "test-home" });
                        }
                    }
                );
        },
        personItemSelected(item) {
            this.person = item;

            this.showPersonDetailFields = true;

            this.firstName = item.firstName;
            this.lastName = item.lastName;

            this.guid = item.guid;

            if (item.company.length > 0) {
                this.companyName = item.company[0].title;
            } else {
                this.companyName = item.companyName;
            }

            this.$store.dispatch(ACTION_SET_CURRENT_CONTRACTOR, this.person);
            //  this.personQuery = item.title;
        },
        personInputChange(text) {
            this.personQuery = text;
        },

        resetSearch() {
            // this.person = null;
            // this.personQuery = '';
            // // this.persons = [];
            // this.showPersonDetailFields = false;
            //
            // this.$refs['searchField'].resetAndFocus();
            this.$router.push({ name: "test-languageSelect" });
        },
        courseFinishedHandler() {
            this.$router.push({ name: "test-complete" });
        },
    },
};
</script>
