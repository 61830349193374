import Vue from "vue";
import Vuex from "vuex";
import supportedLanguages from "./config/supportedLanguages";
import {
    ACTION_SET_CRAFTQL_ERROR,
    ACTION_CHANGE_LANGUAGE,
    ACTION_SET_CURRENT_CONTRACTOR_ADMIN,
    ACTION_SET_CURRENT_CONTRACTOR,
    ACTION_SET_CURRENT_COMPANY_ADMIN,
    ACTION_SET_CURRENT_COMPANY,
    ACTION_SET_USER_SEARCH_TERM,
} from "./constants";
import createPersistedState from "vuex-persistedstate";
import persons from "./modules/persons";
import companies from "./modules/companies";
// import * as Cookies from "js-cookie";
import visit from "./modules/visit";
import qrCodeScanners from "./modules/qrCodeScanners";
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    plugins: [
        createPersistedState({
            key: "vuex-contractor-portal-v4",
            paths: [
                "activeLanguage",
                "persons.currentContractor",
                "persons.currentContractorAdmin",
                "persons.currentCompanyAdmin",
                "persons.personSearchTerm",
                "visit.autoRefresh",
                "visit.visitorTypesToSelect",
                "companies.currentCompany.id",
                "companies.currentCompany.title",
                "companies.companyTypesToSelect",
                "userSearchTerm",
                "qrCodeScanners.scannedCodes",
                "qrCodeScanners.scannerVisitType",
            ],
            //  storage: {
            // 	getItem: key => Cookies.get(key),
            // 	// Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
            // 	setItem: (key, value) => {
            // 		Cookies.set(key, value, { expires: 1 /*days*/, secure: false })
            // 	},
            // 	removeItem: key => Cookies.remove(key)
            // },
        }),
    ],
    state: {
        config: {
            supportedLanguages,
        },
        activeLanguage: "nl",
        craftQLErrorMessage: "",
        userSearchTerm: "",
    },
    actions: {
        [ACTION_CHANGE_LANGUAGE]({ commit }, iso) {
            window.ButTranslator.loadLocale(iso)
                .then(() => {
                    Vue.nextTick(() => {
                        commit(ACTION_CHANGE_LANGUAGE, iso);
                    });
                })
                .catch((e) => {
                    let detail = "";
                    if (e.response && e.response.data) {
                        detail = e.response.data;
                    }
                    console.error("Error loading locale:", e, detail);
                });
        },

        [ACTION_SET_CRAFTQL_ERROR]({ commit, dispatch, state }, error) {
            let errorMessage = "Undefined";
            // console.log("GST", error);
            if (error) {
                errorMessage = window.ButTranslator.getTranslation(error, error);
                if (error.includes("Not authorized")) {
                    if (state.persons.currentContractor.id) {
                        dispatch(ACTION_SET_CURRENT_CONTRACTOR, null);
                        if (!state.persons.currentCompanyAdmin.id && !state.persons.currentContractorAdmin.id) {
                            Vue.router.push({ name: "onlineHome" });
                        }
                    }

                    if (state.persons.currentContractorAdmin.id) {
                        dispatch(ACTION_SET_CURRENT_CONTRACTOR_ADMIN, null);
                        dispatch(ACTION_SET_CURRENT_CONTRACTOR, null);
                        if (!state.persons.currentCompanyAdmin.id) {
                            Vue.router.push({ name: "ContractorAdminCompanyList" });
                        }
                    }

                    if (state.persons.currentCompanyAdmin.id) {
                        dispatch(ACTION_SET_CURRENT_COMPANY_ADMIN, null);
                        dispatch(ACTION_SET_CURRENT_CONTRACTOR_ADMIN, null);
                        dispatch(ACTION_SET_CURRENT_CONTRACTOR, null);
                        Vue.router.push({ name: "CompanyAdminCompaniesList" });
                    }

                    if (state.companies.currentCompany.id) {
                        dispatch(ACTION_SET_CURRENT_COMPANY, null);
                    }
                }
            }

            commit(ACTION_SET_CRAFTQL_ERROR, errorMessage);
        },

        [ACTION_SET_USER_SEARCH_TERM]({ commit }, searchTerm) {
            commit(ACTION_SET_USER_SEARCH_TERM, searchTerm);
        },
    },
    mutations: {
        [ACTION_CHANGE_LANGUAGE](state, iso) {
            moment.locale(iso);
            state.activeLanguage = iso;
        },

        [ACTION_SET_CRAFTQL_ERROR](state, error) {
            state.craftQLErrorMessage = error;
        },

        [ACTION_SET_USER_SEARCH_TERM](state, searchTerm) {
            state.userSearchTerm = searchTerm;
        },
    },

    modules: {
        persons,
        companies,
        visit,
        qrCodeScanners,
    },
});
