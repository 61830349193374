<template>
    <div>
        <!--Modal-->
        <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-40">
            <div
                class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 top-0 left-0"
                style="backdrop-filter: blur(22px); background-color: rgb(115, 115, 115)"
            ></div>

            <div
                class="modal-container bg-white w-11/12 md:max-w-3xl rounded shadow-lg z-50 overflow-y-auto animate-transform p-6"
            >
                <p class="text-2xl font-bold mb-4">Problemen bij het inloggen?</p>
                <p>Meestal is dit omdat er nog een vorige sessie van de vorige gebruiker actief is.</p>
                <p>Dit kan je oplossen door de cookies voor de huidige site te wissen.</p>

                <ol>
                    <li>
                        Druk op het knopje links naast de URL van de site (🔒 of 🎚️, naast
                        {{ document.location.origin }})
                    </li>
                    <li>Druk op 'Cookies and site data'</li>
                    <li v-if="!isEdge">Druk op 'Manage on-device site data'</li>
                    <li v-if="isEdge">Druk op 'Cookies (X cookies in use)'</li>
                    <li v-if="!isEdge">
                        Je ziet nu een lijst van cookies. Achter elk Cookie staat er telkens een vuilnisbakje.
                    </li>
                    <li v-if="!isEdge">Wis alle Cookies door op de vuilnisbakjes te drukken.</li>
                    <li v-if="isEdge">
                        Selecteer het domain waarvan je de Cookies wil wissen. Vb: 'cms.{{ document.location.host }}'.
                    </li>
                    <li v-if="isEdge">Druk op "Remove".</li>
                    <li>Sluit het venster. (Done)</li>
                    <li>Ververs de pagina (Reload 🔄️) en probeer opnieuw in te loggen.</li>
                </ol>
                <p class="text-sm mt-4">
                    <strong>Opmerking</strong>: Dit heeft geen enkele invloed op andere sites in je browser.
                </p>
                <div class="flex justify-end">
                    <button class="btn btn--primary" @click="$emit('close')">Terug</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CookieDeleteInfoPopup",
    components: {},
    data() {
        return {
            document,
            isEdge: navigator.userAgent.toLowerCase().indexOf("edg") > -1,
        };
    },
};
</script>

<style scoped>
li {
    @apply list-disc list-inside;
}
p {
    @apply mb-3;
}
</style>
