import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import OnsiteRoutes from "./onsite";
import OnlineRoutes from "./online";
import TestRoutes from "./test";
import UiSystem from "../views/UiSystem";
import Admin from "@/views/Admin";

Vue.use(VueRouter);

const baseRoutes = [
    {
        path: "/",
        name: "home",
        // component: Home,
        redirect: { name: "onlineHome" },
    },
    {
        name: "Admin",
        path: "/admin",
        component: Admin,
    },
    {
        name: "UiSystem",
        path: "/ui-system",
        component: UiSystem,
    },
];

const routes = baseRoutes.concat(OnsiteRoutes, OnlineRoutes, TestRoutes, [{ path: "*", redirect: { name: "home" } }]);

const router = new VueRouter({
    routes,
});
Vue.router = router;

export default router;
