<template>
    <dynamic-buttonless-modal escapable="false" @close="cancel">
        <template v-slot:content>
            <!--Title-->
            <div class="flex justify-between items-center pb-3">
                <p class="text-2xl font-bold">{{ caption }}</p>
            </div>

            <!--Body-->
            <company-edit
                :company="company"
                @cancel="cancel"
                :show-cancel-button="true"
                @saveSucceeded="saveSucceeded"
            />
        </template>
    </dynamic-buttonless-modal>
</template>

<script>
import CompanyEdit from "./CompanyEdit";
import DynamicButtonlessModal from "./DynamicButtonlessModal";

export default {
    name: "AddOrEditCompany",
    components: { DynamicButtonlessModal, CompanyEdit },
    props: {
        caption: {
            required: true,
        },
        company: {
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            error: "",
        };
    },

    methods: {
        saveSucceeded(person) {
            this.$emit("saveSucceeded", person);
        },

        cancel() {
            this.$emit("cancel");
        },
    },
};
</script>
