<template>
    <div>
        <back-button />

        <div class="container-50">
            <router-link
                tag="button"
                class="btn btn--primary btn--lg btn--icon w-full mb-5"
                :to="{ name: 'onsite-visitTypeSelection', foo: $store.state.activeLanguage }"
            >
                <LocationEnterIcon class="left-0 ml-4" />
                <span v-trans="'check-in'">Check in</span>
            </router-link>
            <router-link
                tag="button"
                class="btn btn--primary btn--lg btn--icon w-full mb-5"
                :to="{ name: 'onsite-checkOut', params: { id: -1 } }"
            >
                <LocationExitIcon class="left-0 ml-4" />
                <span v-trans="'check-out'">Check out</span>
            </router-link>

            <router-link
                tag="button"
                class="btn btn--primary btn--lg btn--icon w-full mb-5"
                :to="{ name: 'onsite-scan' }"
            >
                <q-r-code-icon class="left-0 ml-4" />
                <span v-trans="'check-in-out-with qr-code'">Badge met qr-code</span>
            </router-link>
        </div>
    </div>
</template>
<script>
import LocationEnterIcon from "vue-material-design-icons/LocationEnter.vue";
import LocationExitIcon from "vue-material-design-icons/LocationExit.vue";
import BackButton from "../../components/BackButton";
import QRCodeIcon from "vue-material-design-icons/Qrcode";

export default {
    name: "CheckInOut",
    components: {
        LocationEnterIcon,
        LocationExitIcon,
        BackButton,
        QRCodeIcon,
    },
};
</script>
