const supportedLanguages = [
    {
        iso: "en",
        nativeName: "English",
    },
    {
        iso: "nl",
        nativeName: "Nederlands",
    },
    {
        iso: "fr",
        nativeName: "Français",
    },
    {
        iso: "de",
        nativeName: "Deutsch",
    },
];
export default supportedLanguages;
