import { apolloProvider } from "../../graphql/apollo";
import {
    ACTION_GET_INTERNAL_CONTACTS,
    ACTION_SET_ANONYMOUS_LOGIN_INFO,
    ACTION_SET_ONSITE_SELECTION,
    ACTION_VISIT_SET_ALL_VISITOR_TYPES,
    ACTION_VISIT_SET_AUTO_REFRESH,
    ACTION_VISIT_SET_VISITOR_TYPES_TO_SELECT,
    SET_INTERNAL_CONTACTS,
    STORE_VISIT_SESSION,
} from "../constants";
import { GET_INTERNAL_CONTACTS } from "../../graphql/queries/InternalContact";

export default {
    state: {
        anonymousLoginInfo: null,
        anonymousUserRole: 0,
        onsiteSelection: null,
        companies: [],
        internalContacts: [],
        filteredUsers: [],
        person: null,
        visit: null,
        autoRefresh: true,
        allVisitorTypes: [], //example: [{"id":"114","slug":"visitor-office","title":"Visitor Office","__typename":"visitorType_Category"}, {...}]
        visitorTypesToSelect: [],
    },

    getters: {
        getPreferences: (state) => {
            if (state.onsiteSelection === null) return false;
            return state.onsiteSelection;
        },
        getVisitTypeIdForVisitorOffice: (state) => {
            return state.allVisitorTypes.find((visitorType) => visitorType.slug === "visitor-office").id;
        },
        getVisitTypeIdForVisitorFactory: (state) => {
            return state.allVisitorTypes.find((visitorType) => visitorType.slug === "visitor-factory").id;
        },
        getVisitTypeIdForContractor: (state) => {
            return state.allVisitorTypes.find((visitorType) => visitorType.slug === "contractor").id;
        },
        getVisitTypeIdForTruckDriver: (state) => {
            return state.allVisitorTypes.find((visitorType) => visitorType.slug === "truck-driver").id;
        },
    },

    actions: {
        async [ACTION_GET_INTERNAL_CONTACTS]({ commit }) {
            const response = await apolloProvider.defaultClient.query({
                query: GET_INTERNAL_CONTACTS,
                fetchPolicy: "network-only",
            });

            if (response.data && response.data.entries.length > 0) {
                commit(SET_INTERNAL_CONTACTS, response.data.entries);
            } else {
                throw new Error("No internal contacts found");
            }
        },

        [ACTION_VISIT_SET_AUTO_REFRESH]({ commit }, payload) {
            commit(ACTION_VISIT_SET_AUTO_REFRESH, payload);
        },
        [ACTION_VISIT_SET_VISITOR_TYPES_TO_SELECT]({ commit }, payload) {
            commit(ACTION_VISIT_SET_VISITOR_TYPES_TO_SELECT, payload);
        },
    },
    mutations: {
        [SET_INTERNAL_CONTACTS](state, payload) {
            state.internalContacts = payload;
        },
        [STORE_VISIT_SESSION](state, payload) {
            state.visit = payload.visit;
            state.person = payload.person;
        },
        [ACTION_SET_ANONYMOUS_LOGIN_INFO](state, payload) {
            state.anonymousLoginInfo = payload;
        },
        [ACTION_VISIT_SET_AUTO_REFRESH](state, payload) {
            state.autoRefresh = payload;
        },
        [ACTION_VISIT_SET_VISITOR_TYPES_TO_SELECT](state, payload) {
            state.visitorTypesToSelect = payload;
        },
        [ACTION_SET_ONSITE_SELECTION](state, payload) {
            state.onsiteSelection = payload;
        },
        [ACTION_VISIT_SET_ALL_VISITOR_TYPES](state, payload) {
            state.allVisitorTypes = payload;
        },
    },
};
