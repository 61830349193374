import { Utils } from "../../utils/utils";
import {
    ACTION_SET_COMPANY_SEARCH_TERM,
    ACTION_SET_COMPANY_TYPES_TO_SELECT,
    ACTION_SET_CURRENT_COMPANY,
    ACTION_SET_PERSON_SEARCH_TERM,
    COMPANY_TYPE,
} from "../constants";

export default {
    state: {
        currentCompany: { id: null, title: "", address: "", telephone: "", companyType: [{ id: 119 }] }, //contractor
        companyTypesToSelect: [COMPANY_TYPE.CONTRACTOR, COMPANY_TYPE.TRANSPORT],
        companySearchTerm: "",
    },

    getters: {},

    actions: {
        [ACTION_SET_CURRENT_COMPANY]({ commit, state }, payload) {
            if (!payload || payload.id !== state.currentCompany.id) {
                commit(ACTION_SET_PERSON_SEARCH_TERM, "", { root: true });
            }
            commit(ACTION_SET_CURRENT_COMPANY, payload);
        },

        [ACTION_SET_COMPANY_TYPES_TO_SELECT]({ commit }, payload) {
            commit(ACTION_SET_COMPANY_TYPES_TO_SELECT, payload);
        },

        [ACTION_SET_COMPANY_SEARCH_TERM]({ commit }, searchTerm) {
            commit(ACTION_SET_COMPANY_SEARCH_TERM, searchTerm);
        },
    },

    mutations: {
        [ACTION_SET_CURRENT_COMPANY](state, payload) {
            payload = Utils.initCompany(payload);
            state.currentCompany = { ...payload };
        },
        [ACTION_SET_COMPANY_TYPES_TO_SELECT](state, payload) {
            state.companyTypesToSelect = payload;
        },
        [ACTION_SET_COMPANY_SEARCH_TERM](state, searchTerm) {
            state.companySearchTerm = searchTerm;
        },
    },
};
