<template>
    <div id="print-permit" v-show="false">
        <div class="print text-real-black m-4">
            <div class="flex flex-1 justify-center mb-16 border-2 p-2 border-gray-500 rounded" style="gap: 5rem">
                <img class="w-32" src="img/css/logo.svg?3" />
                <h1 class="text-2xl uppercase">Inrijvergunning</h1>
            </div>

            <div class="flex flex-1">
                <div class="form-group mb-2 w-full md:w-1/3 md:mr-5">
                    <h4>Naam</h4>
                    <h3 class="text-xl">{{ permit.contractor }}</h3>
                </div>
                <div class="form-group mb-2 w-full md:w-1/3 md:mr-5" v-if="permit.mobilePhone">
                    <h4>Telefoon</h4>
                    <h3>{{ permit.mobilePhone }}</h3>
                </div>
                <div class="form-group mb-2 w-full md:w-1/3 md:mr-5" v-if="permit.licensePlate">
                    <h4>Nummerplaat</h4>
                    <h3>{{ permit.licensePlate }}</h3>
                </div>
            </div>
            <div class="flex flex-1">
                <div class="form-group mb-2 w-full md:w-1/2 md:mr-5">
                    <h4>Bedrijf</h4>
                    <h3>{{ permit.company }}</h3>
                </div>
                <div class="form-group mb-2 w-full md:w-1/2 md:mr-5" v-if="permit.subContractor">
                    <h4>Onderaanneming</h4>
                    <h3>{{ permit.subContractor }}</h3>
                </div>
            </div>

            <div class="flex flex-1">
                <div class="form-group mb-2 w-full md:w-1/2 md:mr-5">
                    <h4>Start werken</h4>
                    <h3>{{ startDate }}</h3>
                </div>
                <div class="form-group mb-2 w-full md:w-1/2 md:mr-5">
                    <h4>Geldig tot</h4>
                    <h3>{{ expiryDate }}</h3>
                </div>
            </div>

            <div class="flex flex-1">
                <div class="form-group mb-2 w-full md:w-1/2 md:mr-5" v-if="permit.workLeader1">
                    <h4>Werkleider</h4>
                    <h3 style="margin-bottom: 0">{{ permit.workLeader1 }}</h3>
                    <h3>{{ permit.workLeader1Tel }}</h3>
                </div>
                <div class="form-group mb-2 w-full md:w-1/2 md:mr-5" v-if="permit.workLeader2">
                    <h4>Vervangend werkleider</h4>
                    <h3 style="margin-bottom: 0">{{ permit.workLeader2 }}</h3>
                    <h3>{{ permit.workLeader2Tel }}</h3>
                </div>
            </div>

            <table class="but-table text-2xl py-6 border-2 p-2 border-gray-500 rounded px-24 w-full">
                <tr>
                    <td>
                        <img class="w-12" src="@/assets/permit/speedlimit.svg" alt="max 10 km per uur" />
                    </td>
                    <td>
                        <p class="">Maximaal 10 km/h</p>
                    </td>
                    <td>
                        <img class="w-12" src="@/assets/permit/fire.svg" alt="telefoon bij brand" />
                    </td>
                    <td><p class="">Security: +32 479 95 18 17</p></td>
                </tr>
                <tr>
                    <td>
                        <img class="w-12" src="@/assets/permit/no-cans.svg" alt="geen blikjes" />
                    </td>
                    <td><p class="">Blikjes zijn verboden</p></td>
                    <td>
                        <img class="w-12" src="@/assets/permit/rescue.svg" alt="telefoon voor eerste hulp" />
                    </td>
                    <td><p class="">Eerste hulp: +32 477 29 15 06</p></td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    name: "permitToPrint",
    props: {
        permit: {},
    },
    data() {
        return {};
    },
    computed: {
        startDate() {
            return new Date(
                this.permit.startDate.year,
                this.permit.startDate.month - 1,
                this.permit.startDate.day
            ).toLocaleDateString("nl-NL", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
            });
        },
        expiryDate() {
            console.log("this.permit.expiryDate", this.permit.expiryDate);
            return new Date(
                this.permit.expiryDate.year,
                this.permit.expiryDate.month - 1,
                this.permit.expiryDate.day
            ).toLocaleDateString("nl-NL", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
            });
        },
    },
};
</script>

<style>
.print h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.but-table td {
    border-top-width: 0px;
}
</style>
