import { ACTION_INIT_QR_CODE_SCANNERS, ACTION_SET_SCANNER_VISIT_TYPE } from "@/store/constants";
import { CHECKOUT_VISIT, GET_VISITS_FOR_PERSON_ID3, SAVE_VISIT2 } from "@/graphql/queries/Visit";
import { GET_PERSON_BY_GUID2 } from "@/graphql/queries/Person";
import { Utils } from "@/utils/utils";

export default {
    state: {
        //the USB-COM devices paired by the browser
        devices: [],

        //the last 20 qr-codes that were scanned on this pc (persistant)
        scannedCodes: [],

        //the default visit type that will be used to check in a user (persistant)
        scannerVisitType: null,

        //An array with codes that are scanned but not yet checked-in or out.
        //This is required to skip a scan that is still in progress. Otherwise, we would check in twice since we do not know the actual state yet.
        codesWithScanningInProgress: [],
    },

    getters: {
        isInvalidBrowser() {
            return !("serial" in navigator);
        },
    },

    actions: {
        async [ACTION_INIT_QR_CODE_SCANNERS]({ dispatch, commit }) {
            //reading Ports that the user already gave permission for and that are attached to the PC
            const foundActivePorts = await navigator.serial.getPorts();
            if (foundActivePorts !== null && Array.isArray(foundActivePorts) && foundActivePorts.length > 0) {
                const devices = [];
                foundActivePorts.forEach((port, index) => {
                    const name = port.getInfo().usbProductId ? "Serial Device " + (index + 1) : "Unknown device";
                    const device = { port, name, error: "", code: Math.random() };
                    setTimeout(() => {
                        dispatch("listenToSerialPort", device);
                    }, 1000);
                    devices.push(device);
                });
                commit("setDevices", devices);

                navigator.serial.addEventListener("connect", (event) => {
                    // TODO: Automatically open event.target or warn user a port is available.
                    console.log("connect", event);
                    setTimeout(() => {
                        // this.$router.go();
                        document.location.reload();
                    }, 500);
                });

                navigator.serial.addEventListener("disconnect", (event) => {
                    // TODO: Remove |event.target| from the UI.
                    console.log("disconnect", event);
                    setTimeout(() => {
                        // this.$router.go();
                        document.location.reload();
                    }, 500);
                });
            }
        },

        async listenToSerialPort({ state, dispatch, commit }, device) {
            try {
                await device.port.close();
            } catch (e) {
                //console.log(e)
            }
            console.log("device", device.name, typeof device.port.readable);

            try {
                await device.port.open({ baudRate: 9600 });
            } catch (e) {
                // return;
                console.log("Error for", device.name, e);
                commit("updateDeviceError", { code: device.code, error: e.toString() });

                // device.code = Math.random(); //update code to redraw
                console.log("devices", state.devices);
            }

            let buffer = [];

            //loop scanner read
            while (device.port.readable) {
                let reader;
                try {
                    reader = device.port.readable.getReader();
                } catch {
                    return;
                }

                try {
                    let condition = true;
                    while (condition) {
                        const { value, done } = await reader.read();
                        // console.log('value:', value, "done:", done, "string:", String.fromCharCode(...value));

                        //loop all incoming values
                        value.forEach((char) => {
                            //only validate if we have an ENTER sign (13)
                            if (char === 13) {
                                const code = String.fromCharCode(...buffer);
                                if (!state.codesWithScanningInProgress.includes(code)) {
                                    dispatch("validateCode", { code, device });
                                } else {
                                    console.warn(`:-: Scan is SKIPPED: code ${code} still in progress`);
                                    commit("pushToScannedCodes", {
                                        device,
                                        code,
                                        name: "",
                                        company: "",
                                        info: `Fetching info for code ${code} is still busy: No further action taken.`,
                                        isError: true,
                                    });
                                }
                                //clear earlier buffer
                                buffer = [];
                            } else {
                                if (char !== 10) {
                                    buffer.push(char);
                                }
                            }
                        });

                        if (done) {
                            // Allow the serial port to be closed later.
                            // console.log(`scanner ${device.name} disconnected`);
                            reader.releaseLock();
                            break;
                        }
                    }
                } catch (error) {
                    console.log("Error for", device.name, error);
                }
            }
        },

        async validateCode({ commit, dispatch }, { code, device }) {
            // console.log("validateCode", code, "on device", device.name);
            // commit("pushToScannedCodes", {code, device});

            commit("addToScanningInProgress", code);

            const isValidCode = code.split("-").length === 2 && code.length === 9;
            if (isValidCode) {
                window.ap.defaultClient
                    .query({
                        query: GET_PERSON_BY_GUID2,
                        variables: { guid: code },
                    })
                    .then(async (r) => {
                        // console.log("validateCode", r);
                        const person = r.data.entries ? r.data.entries[0] : false;
                        if (person) {
                            const lastVisits = await dispatch("getLastVisits", person);
                            const lastVisit = lastVisits.length > 0 ? lastVisits[0] : null;

                            if (lastVisit) {
                                //not logged in or out within the last 10 secs?
                                //if expired: check expirydate < 10 + 60 sec: because we added a minute when we logged out
                                // if logged in: check dateCreated < 10 sec
                                if (
                                    lastVisit.status === "live" &&
                                    new Date(lastVisit.dateCreated).getTime() + 10000 < new Date().getTime()
                                ) {
                                    console.log("log out");
                                    //logout
                                    lastVisits
                                        .filter((v) => v.status === "live")
                                        .forEach((liveVisit) => {
                                            dispatch("logoutVisit", { visit: liveVisit, person, device });
                                        });
                                } else if (
                                    lastVisit.status === "expired" &&
                                    new Date(lastVisit.expiryDate).getTime() + 70000 < new Date().getTime()
                                ) {
                                    //login
                                    console.log("log in");
                                    dispatch("loginVisit", { person, device });
                                } else {
                                    console.log("logged in our out less than 60 seconds ago", person);
                                    commit("pushToScannedCodes", {
                                        device,
                                        code,
                                        name: person.firstName + " " + person.lastName,
                                        company: Utils.getCompanyName(person),
                                        info: "De code werd opnieuw gescand binnen 60 seconden: Geen verdere actie ondernomen.",
                                        isError: true,
                                    });
                                    commit("removeFromScanningInProgress", code);
                                }
                            } else {
                                //login
                                // console.log("log in");
                                dispatch("loginVisit", { person, device });
                            }
                        } else {
                            console.log("No person found with code", code);
                            commit("pushToScannedCodes", {
                                device,
                                code,
                                info: "De code komt niet overeen met een bestaande persoon: Geen verdere actie ondernomen.",
                                isError: true,
                            });
                            commit("removeFromScanningInProgress", code);
                        }
                    })
                    .catch((e) => {
                        console.error("Error GET_PERSON_BY_GUID", e);
                    });
            } else {
                commit("pushToScannedCodes", {
                    device,
                    code,
                    info: "De code voldoet niet aan de verwachte structuur XXXX-XXXX",
                    isError: true,
                });
                commit("removeFromScanningInProgress", code);
            }
        },

        loginVisit({ commit, state, rootState }, { person, device }) {
            let expiryDate = new Date();
            //IMPORTANT: expiry date is overwritten and set on the backend because we don't want a client date to set this!

            const visit = {
                title: `QR Code Scan: ${person.firstName} ${person.lastName}`,
                person: [parseInt(person.id)],
                // visitType: [{ id: VISIT_TYPE.CHAUFFEUR }],
                visitType: [parseInt(state.scannerVisitType)],
                signature: [],
                privacyPolicy: false,
                needsWeighing: false,
                expiryDate: expiryDate,
                loadingType: "undefined",
                orderNumber: "",
            };

            window.ap.defaultClient
                .mutate({
                    mutation: SAVE_VISIT2,
                    variables: visit,
                    //fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                })
                .then((r) => {
                    commit("removeFromScanningInProgress", person.guid);
                    if (r.error) {
                        console.log("e.error", r);
                    } else {
                        console.log("allVisitorTypes", rootState.visit.allVisitorTypes, "visitType", visit.visitType);
                        // console.log("save visit result", r.data, "visit:", visit, "person:", person);
                        const visitType = rootState.visit.allVisitorTypes.find(
                            (vt) => parseInt(vt.id) === visit.visitType[0]
                        );
                        commit("pushToScannedCodes", {
                            device,
                            code: person.guid,
                            name: `${person.firstName} ${person.lastName}`,
                            company: Utils.getCompanyName(person),
                            info: `Check in als ${visitType?.title.toUpperCase() || "onbekend type".toUpperCase()}`,
                            isError: false,
                        });
                    }
                })
                .catch((e) => {
                    console.error("loginVisit error:", e);
                });
        },

        async getLastVisits(context, person) {
            // console.log("getLastVisits", person.id)

            return await window.ap.defaultClient
                .query({
                    query: GET_VISITS_FOR_PERSON_ID3,
                    variables: { personIds: [{ id: parseInt(person.id) }] },
                    fetchPolicy: "network-only",
                })
                .then((response) => {
                    console.log("GET_VISITS_FOR_PERSON_ID3", response);
                    if (response.data && response.data.entries) {
                        const visits = response.data.entries;
                        // console.log("visits", visits);
                        return visits;
                    } else {
                        throw new Error("GET_VISITS_FOR_PERSON_ID");
                    }
                })
                .catch((e) => {
                    console.error("Error getLastVisit", e);
                });
        },

        logoutVisit({ commit }, { visit, person, device, pushToScannedCodes = true }) {
            visit.manualCheckout = true;
            // visit.expiryDate = new Date();

            visit.expiryDate = new Date(new Date().getTime() - 1000 * 60).toISOString().substring(0, 19); //check out a minute ago, to make sure the logout works right away
            if (visit.dateCreated && new Date(visit.expiryDate) <= new Date(visit.dateCreated)) {
                //always make sure the post date is later than the expiry
                visit.expiryDate = visit.dateCreated;
            }

            window.ap.defaultClient
                .mutate({
                    mutation: CHECKOUT_VISIT,
                    variables: visit,
                })
                .then((r) => {
                    console.log("logoutVisit", r, person);
                    commit("removeFromScanningInProgress", person.guid);
                    if (pushToScannedCodes) {
                        commit("pushToScannedCodes", {
                            device,
                            code: person.guid,
                            name: person.firstName + " " + person.lastName,
                            company: Utils.getCompanyName(person),
                            info: "Check out als " + visit.visitType[0].title.toUpperCase(),
                            isError: false,
                        });
                    }
                })
                .catch((e) => {
                    console.error("logoutVisit", e);
                });
        },
    },

    mutations: {
        setDevices(state, devices) {
            state.devices = devices;
        },
        setScannedCodes(state, scannedCodes) {
            state.scannedCodes = scannedCodes;
        },
        pushToScannedCodes(state, { code, device, name = "", company = "", info = "", isError = false }) {
            //FIFO: truncate array: only keep the first 19 items and add a new on top.
            state.scannedCodes = state.scannedCodes.slice(0, 19);

            state.scannedCodes.splice(0, 0, {
                // state.scannedCodes.push({
                code,
                deviceName: device ? device.name : "?",
                date: new Date(),
                info,
                isError,
                name,
                company,
                key: Math.random(),
            });
        },
        updateDeviceError(state, { code, error }) {
            const foundDevice = state.devices.find((d) => d.code === code);
            if (foundDevice) {
                foundDevice.error = error;
            } else {
                console.warn("updateDeviceError", "Could not find device with code", code);
            }
        },

        [ACTION_SET_SCANNER_VISIT_TYPE](state, visitType) {
            state.scannerVisitType = visitType;
        },
        removeFromScanningInProgress(state, code) {
            let index = state.codesWithScanningInProgress.indexOf(code);
            if (index > -1) {
                // console.log(":-: REmOVING", code);
                state.codesWithScanningInProgress.splice(index, 1);
            } else {
                // console.log(":-: code NOT in Progress:", code);
            }
        },
        addToScanningInProgress(state, code) {
            // console.log(":-: ADDiNG:", code);
            state.codesWithScanningInProgress.push(code);
        },
    },
};
