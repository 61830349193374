<template>
    <div>
        <!--        <craft-users-list :craftUserTypeSlugs="[CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG]" />-->
        <craft-users-list :craftUserTypeSlugs="craftUserTypeSlugs" />
    </div>
</template>

<script>
import CraftUsersList from "../../components/CraftUsersList";
import { CRAFT_USER_TYPE } from "../../store/constants";

export default {
    name: "ContractorAdminUsers",
    components: { CraftUsersList },
    data() {
        return {
            CRAFT_USER_TYPE,
            craftUserTypeSlugs: [CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG],
        };
    },
};
</script>
