<template>
    <div class="">
        <h2 class="h2 text-primary-normal text-center" v-trans="'registration-unsuccessful--title'">
            Registratie nog niet voltooid
        </h2>
        <div class="text-center">
            <span class="text-6xl mr-4 -mt-3"><alert></alert></span>
        </div>

        <h3 class="h3 text-center" v-trans="'registration-unsuccessful--alert'">Let op!</h3>
        <p class="text-2xl flex-grow text-center" v-trans="'registration-unsuccessful--copy'">
            Je hebt de test om toegang te krijgen tot de fabriek nog niet succesvol afgelegd.<br />
            <strong>Meld je aan bij de receptie.</strong>
        </p>
        <button @click="backToLanguageSelect" class="btn btn--primary btn--lg mt-8 mx-auto block" v-trans="'ok'">
            OK
        </button>
    </div>
</template>
<script>
// import ExclamationMark from 'vue-material-design-icons/AlertCircleOutline';
import Alert from "vue-material-design-icons/BadgeAccountAlertOutline.vue";

export default {
    name: "RegistrationIncomplete",
    data() {
        return {
            timeoutRef: 0,
        };
    },
    components: {
        //ExclamationMark,
        Alert,
    },
    mounted() {
        this.timeoutRef = setTimeout(this.backToLanguageSelect, 60000);
    },
    methods: {
        backToLanguageSelect() {
            clearTimeout(this.timeoutRef);
            this.$router.push({ name: "onsite-languageSelect" });
        },
    },
};
</script>
