<template>
    <div>
        <div
            class="text-sm font-medium text-center border-b border-gray-500 uppercase mb-8 -mx-6 bg-white -mt-6 text-gray-500"
        >
            <ul class="flex flex-wrap -mb-px">
                <li class="mr-2">
                    <router-link
                        :to="{ name: 'CompanyAdminUsersDuffel' }"
                        class="inline-block p-4 border-b-4 border-transparent rounded-t-lg hover:text-gray-800 hover:border-gray-500"
                    >
                        Aluminium Duffel Admins
                    </router-link>
                </li>
                <li class="mr-2">
                    <router-link
                        :to="{ name: 'CompanyAdminUsersOther' }"
                        class="inline-block p-4 border-b-4 border-transparent rounded-t-lg hover:text-gray-800 hover:border-gray-500"
                    >
                        Admins van andere bedrijven
                    </router-link>
                </li>
            </ul>
        </div>

        <router-view />
    </div>
</template>

<script>
export default {
    name: "CompanyAdminUsers",
    components: {},
    data() {
        return {};
    },
};
</script>

<style scoped>
.router-link-active {
    //font-weight: bold; //border-bottom: darkblue 4px solid;
    @apply font-bold border-primary-dark border-b-4 text-primary-dark;
}
</style>
