<template>
    <div class="" id="app">
        <transition mode="out-in" name="fade-in" appear>
            <router-view :key="$store.state.activeLanguage" v-if="areVisitorTypesLoaded" />
        </transition>
    </div>
</template>

<script>
import {
    ACTION_CHANGE_LANGUAGE,
    ACTION_GET_LEARNING_TRACKS,
    ACTION_VISIT_SET_ALL_VISITOR_TYPES,
} from "./store/constants";
import { GET_ALL_VISITOR_TYPES } from "@/graphql/queries/Visit";
import { GET_LEARNING_TRACKS } from "@/graphql/queries/LearningTrack";

export default {
    name: "App",
    data() {
        return {
            areVisitorTypesLoaded: false,
        };
    },
    apollo: {
        visitTypes() {
            return {
                query: GET_ALL_VISITOR_TYPES,
                fetchPolicy: "cache-and-network",
                variables() {
                    return {};
                },
                update(data) {
                    /**
                     * example: {
                     *     "id": "114",
                     *     "slug": "visitor-office",
                     *     "title": "Visitor Office",
                     * }
                     */
                    // console.log("#######visitTypes", data);
                    const r = data.categories || [];
                    this.$store.commit(ACTION_VISIT_SET_ALL_VISITOR_TYPES, r);
                    this.areVisitorTypesLoaded = true;
                    return r;
                },
                watchLoading(isLoading) {
                    this.isLoading = isLoading;
                },
                error(e) {
                    if (e.networkError && parseInt(e.networkError.response.status) === 401) {
                        this.errorMsg = this.$trans.getTranslation("not-authorized-relogin");
                    } else {
                        this.errorMsg = JSON.stringify(e);
                    }
                },
            };
        },
        learningTracks() {
            return {
                query: GET_LEARNING_TRACKS,
                fetchPolicy: "cache-and-network",
                variables() {
                    return {
                        limit: 100,
                    };
                },
                update(data) {
                    if (data.entries) {
                        this.$store.commit(ACTION_GET_LEARNING_TRACKS, data.entries);
                        return data.entries;
                    } else {
                        return [];
                    }
                },
            };
        },
    },

    async created() {
        this.$store.dispatch(ACTION_CHANGE_LANGUAGE, this.$store.state.activeLanguage);
    },

    methods: {
        async checkUserIdentity() {
            // const response = await fetch(
            //     `${process.env.VUE_APP_REST_ENDPOINT}/actions/visit-registration-platform/session/user-identity`,
            //     {
            //         method: "GET",
            //         credentials: "include",
            //     }
            // );
            // const userIdentity = await response.json();
            // console.log("userIdentity:", userIdentity);
        },
    },

    async mounted() {
        console.log("check here with checkUserIdentity");
        const userIdentity = await this.checkUserIdentity();
        console.log("userIdentity:", userIdentity);
        this.$store.commit("setIsLoggedIn", userIdentity?.id > 0);

        setTimeout(() => {
            console.log("route:", document.location.search, this.$route.query.guid);
            //for some reason, this.$route does not yet return the query params
            if (document.location.search && document.location.search.includes("guid=")) {
                const guid = document.location.search.split("guid=")[1];
                this.$router.replace({ name: "onlineContractor", query: { guid } });
            }
        }, 100);
    },
};
</script>
