<template>
    <div>
        <h2 class="h2 text-primary-normal text-center" v-trans="'checkout-successful--title'">
            Checkout geregistreerd
        </h2>

        <p v-trans="'checkout-successful--copy'" class="text-2xl text-center flex-grow">Bedankt voor uw bezoek!</p>
        <button class="btn btn--primary btn--lg mt-5 mx-auto block" @click="backToLanguageSelect" v-trans="'ok'">
            OK
        </button>
    </div>
</template>
<script>
export default {
    name: "CheckOutComplete",
    data() {
        return {
            timeoutRef: 0,
        };
    },
    mounted() {
        this.timeoutRef = setTimeout(this.backToLanguageSelect, 10000);
    },
    methods: {
        backToLanguageSelect() {
            clearTimeout(this.timeoutRef);
            this.$router.push({ name: "onsite-languageSelect" });
        },
    },
};
</script>
