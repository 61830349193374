<template>
    <personEdit :person="person" @saveSucceeded="onEditPersonSucceeded" />
</template>

<script>
import { ACTION_SET_CURRENT_CONTRACTOR } from "../../store/constants";
import PersonEdit from "../../components/PersonEdit";

export default {
    name: "ContractorProfile",
    components: { PersonEdit },
    data() {
        return {
            person: {},
            storeSubscription: null,
        };
    },

    mounted() {
        this.person = { ...this.$store.state.persons.currentContractor };
        this.storeSubscription = this.$store.subscribe((mutation) => {
            if (mutation.type === ACTION_SET_CURRENT_CONTRACTOR && mutation.payload !== null) {
                this.person = { ...this.$store.state.persons.currentContractor };
            }
        });
    },

    beforeDestroy() {
        // remove the subscription
        this.storeSubscription();
    },

    methods: {
        onEditPersonSucceeded(person) {
            this.$store.dispatch(ACTION_SET_CURRENT_CONTRACTOR, person);
            this.$emit("saveSucceeded", person);
        },
    },
};
</script>
