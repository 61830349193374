import gql from "graphql-tag";

export const GET_ALL_LANGUAGES_QUERY = gql`
    query public_GetAllLanguages {
        categories(group: "Language") {
            ... on language_Category {
                id
                slug
                title
            }
        }
    }
`;

// query contractor_GetAllLanguagesCraft {
//     categories(group: "language") {
//         id
//         slug
//         title
//     }
// }
