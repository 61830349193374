<template>
    <div>
        <div class="alert alert--warning py-4" v-if="errorMsg">{{ errorMsg }}</div>

        <company-edit :company="company" :show-cancel-button="false" />
    </div>
</template>

<script>
import CompanyEdit from "../../components/CompanyEdit";
import { GET_COMPANY_WITH_ID } from "../../graphql/queries/Company";
export default {
    name: "ContractorAdminCompanyProfile",
    components: { CompanyEdit },
    data() {
        return {
            company: null,
            errorMsg: "",
        };
    },
    created() {
        this.company = { ...this.$store.state.companies.currentCompany };
        // console.log("Gert: this.company:", this.company);
    },
    apollo: {
        companies: {
            query: GET_COMPANY_WITH_ID,
            fetchPolicy: "cache-and-network",
            variables() {
                this.errorMsg = "";
                return {
                    entryId: this.$store.state.companies.currentCompany.id,
                };
            },
            update(data) {
                if (data.entries) {
                    this.errorMsg = "";
                    if (data.entries.length === 1) {
                        // console.log("Gert: data.entries[0]:", data.entries[0]);
                        this.company = data.entries[0];
                    } else {
                        console.log("Gert: error: company not found");
                    }
                    return data.entries;
                } else {
                    return [];
                }
            },
            watchLoading(isLoading) {
                this.isLoading = isLoading;
            },
            error(e) {
                if (e.networkError && parseInt(e.networkError.response.status) === 401) {
                    this.errorMsg = this.$trans.getTranslation("not-authorized-relogin");
                } else {
                    this.errorMsg = JSON.stringify(e);
                }
            },
        },
    },
};
</script>

<style scoped></style>
