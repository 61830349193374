var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"trans",rawName:"v-trans",value:('privacy-policy-body'),expression:"'privacy-policy-body'"}]},[_c('div',{staticClass:"h2"},[_vm._v("Privacy Agreement")]),_c('div',{staticClass:"h3 mt-4"},[_vm._v("What type of information do we store")]),_vm._m(0),_c('div',{staticClass:"h3 mt-4"},[_vm._v("We collect this data in the context of")]),_vm._m(1),_c('div',{staticClass:"h3 mt-4"},[_vm._v("Use of personal data")]),_vm._m(2),_c('div',{staticClass:"h3 mt-4"},[_vm._v("Data storage and retention")]),_vm._m(3),_c('div',{staticClass:"h3 mt-4"},[_vm._v("Disclosure to third parties")]),_vm._m(4),_c('div',{staticClass:"h3 mt-4"},[_vm._v("Your rights")]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Firstname")]),_c('li',[_vm._v("Lastname")]),_c('li',[_vm._v("Company")]),_c('li',[_vm._v("Mobile phone")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Notify the host of your arrival")]),_c('li',[_vm._v("Security and internal audits")]),_c('li',[_vm._v("In case of emergency situations")]),_c('li',[_vm._v("Draw up visitor statistics")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("To notify the host of your arrival")]),_c('li',[_vm._v("To notify you in case of an emergency")]),_c('li',[_vm._v("To invite you to a security test")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("The data is stored at Combell data centers in Belgium")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" We use a platform built and maintained by B.U.T Belgium for registering our visitors, with whom we have a data processing agreement ")]),_c('li',[_vm._v("We do not sell or share any of your personal information to other parties.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Right of access to personal data")]),_c('li',[_vm._v("Right to rectification, completion or update of your personal data")]),_c('li',[_vm._v("Right to delete your personal data (‘right to be forgotten’)")]),_c('li',[_vm._v("Right to limit the processing of your personal data")]),_c('li',[_vm._v("Right to transferability of your personal data")]),_c('li',[_vm._v("Right to object to oppose the processing of your personal data")]),_c('li',[_vm._v(" Contact "),_c('a',{attrs:{"href":"https://aluminiumduffel.com"}},[_vm._v("https://aluminiumduffel.com")]),_vm._v(" for exempting your rights. ")])])
}]

export { render, staticRenderFns }