import gql from "graphql-tag";

//done
export const SAVE_RESULT = gql`
    mutation contractor_SaveResult($id: ID, $sco: [Int], $scoStatus: Int, $score: Number, $suspendData: String) {
        save_scoResult_scoResult_Entry(
            id: $id
            scoStatus: [$scoStatus]
            score: $score
            sco: $sco
            suspendData: $suspendData
        ) {
            id
            score
            suspendData
            scoStatus {
                id
                slug
            }
        }
    }
`;

//done
export const UPDATE_LEARNING_TRACK_RESULT = gql`
    mutation contractor_UpdateLearningTrackResult($id: ID, $scoResults: [Int]) {
        save_learningTrackResult_learningTrackResult_Entry(id: $id, scoResults: $scoResults) {
            id
            learningTrackResultStatus {
                id
                title
            }
        }
    }
`;

//done
export const NOT_ATTEMPTED_lEARNING_TRACKS = gql`
    query onsite_GetNotAttemptedLearningTracks {
        entries(section: "learningTrackResult", relatedTo: [194, 199]) {
            ... on learningTrackResult_learningTrackResult_Entry {
                title
                person {
                    ... on person_person_Entry {
                        id
                        guid
                        title
                        firstName
                        lastName
                        companyName
                        company {
                            ... on company_company_Entry {
                                title
                            }
                        }
                    }
                }
            }
        }
    }
`;

//done
export const SAVE_LEARNING_TRACK = gql`
    mutation portaladministrator_SaveLearningTrack($id: ID, $expiryDate: DateTime, $postDate: DateTime) {
        save_learningTrackResult_learningTrackResult_Entry(id: $id, expiryDate: $expiryDate, postDate: $postDate) {
            ... on learningTrackResult_learningTrackResult_Entry {
                id
                expiryDate
            }
        }
    }
`;

//done
export const DELETE_LEARNING_TRACK = gql`
    mutation portaladministrator_DeleteLearningTrack($id: Int!) {
        deleteEntry(id: $id)
    }
`;

export const GET_LEARNING_TRACKS = gql`
    query public_GetLearningtracks {
        entries(section: "learningTrack") {
            ... on learningTrack_learningTrack_Entry {
                id
                slug
                title
            }
        }
    }
`;
