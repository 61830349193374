import LanguageSelection from "../views/test/LanguageSelection";
import TestHome from "../views/test/TestHome";
import Settings from "../views/test/Settings";
import TestComplete from "../views/test/TestComplete";
// import TestContent from "../views/test/TestContent";
import Overview from "../views/test/Overview";

export default [
    {
        path: "/test",
        name: "test",
        component: () => import(/* webpackChunkName: "onsite" */ "../views/Test.vue"),
        redirect: { name: "test-home" },
        children: [
            {
                name: "test-home",
                path: "/",
                component: TestHome,
            },
            {
                path: "settings",
                name: "test-settings",
                component: Settings,
            },
            {
                path: "language-select",
                name: "test-languageSelect",
                component: LanguageSelection,
            },
            {
                path: "overview",
                name: "test-overview",
                component: Overview,
            },

            {
                path: "complete",
                name: "test-complete",
                component: TestComplete,
            },
        ],
    },
];
