<template>
    <div class="text-real-black" id="print-badge" v-if="personToEdit" v-show="true">
        <img class="absolute w-24" src="img/css/logo.svg?3" style="top: 4.1cm" />
        <div class="float-right ml-1">
            <img
                :src="personToEdit.photo[0].url"
                class="w-20 rounded-lg rounded-b-none mb-1"
                v-if="personToEdit.photo && personToEdit.photo[0]"
            />
            <div class="w-20" v-html="qrCodeHtml"></div>
        </div>
        <h2 class="text-1xl text-real-black pt-6">{{ personToEdit.firstName }} {{ personToEdit.lastName }}</h2>
        <h2 class="text-1xl text-real-black font-bold" v-if="personToEdit.company.length > 0">
            {{ personToEdit.company[0].title }}
        </h2>
        <p
            class="text-real-black"
            v-if="Utils.getLatestValidLearningTrackExpiryDate(personToEdit.learningTrackResults, 'contractors')"
        >
            <span v-trans="'end-date'">Einddatum</span>:
            <IconContractor />

            <span class="text-1xl ml-1">{{
                Utils.getLatestValidLearningTrackExpiryDate(personToEdit.learningTrackResults, "contractors")
            }}</span>
        </p>

        <p
            class="text-real-black"
            v-if="Utils.getLatestValidLearningTrackExpiryDate(personToEdit.learningTrackResults, 'visitors-factory')"
        >
            <span v-trans="'end-date'">Einddatum</span>:
            <IconVisitor />
            <span class="text-1xl ml-1">{{
                Utils.getLatestValidLearningTrackExpiryDate(personToEdit.learningTrackResults, "visitors-factory")
            }}</span>
        </p>

        <p class="text-real-black" v-if="visits.length > 0 && visits[0].internalContactName">
            <span v-trans="'internal-contact'">Intern contact</span>: {{ visits[0].internalContactName }}
        </p>
    </div>
</template>
<script>
import { Utils } from "../utils/utils";
import QrCodeGenerator from "qrcode-generator";
import AccountHardHatIcon from "vue-material-design-icons/AccountHardHat";
import BriefcaseAccount from "vue-material-design-icons/BriefcaseAccount";

export default {
    name: "badgeToPrint",
    components: {
        IconContractor: AccountHardHatIcon,
        IconVisitor: BriefcaseAccount,
    },
    props: {
        personToEdit: {},
        visits: {},
    },
    data() {
        return {
            Utils,
        };
    },
    computed: {
        qrCodeHtml() {
            if (this.personToEdit && this.personToEdit.guid) {
                const typeNumber = 0;
                const errorCorrectionLevel = "M";
                const cellSize = 5;
                const margin = 0;
                const qr = QrCodeGenerator(typeNumber, errorCorrectionLevel);
                qr.addData(this.personToEdit.guid);
                qr.make();
                return qr.createImgTag(cellSize, margin);
            } else {
                return "";
            }
        },
    },
};
</script>
