import gql from "graphql-tag";

//done
export const GET_USERS = gql`
    query contractoradministrator_getUsers(
        $userIds: [QueryArgument]
        $search: String
        $companyId: [QueryArgument]
        $craftUserTypeSlugs: [QueryArgument]
    ) {
        users(
            search: $search
            craftUserTypeSlug: $craftUserTypeSlugs
            id: $userIds
            relatedToEntries: [{ id: $companyId, section: "company" }]
        ) {
            ... on User {
                id
                firstName
                lastName
                email
                company {
                    id
                    title
                }
                languageCode
                craftUserTypeSlug
            }
        }
    }
`;

// NOT USED ANYMORE! It’s currently not possible to mutate users with Craft’s GraphQL API.
// Mutating Users: https://craftcms.com/docs/4.x/graphql.html#mutating-users
export const SAVE_USER = gql`
    mutation contractoradministrator_saveUser(
        $id: Int
        $email: String
        $firstName: String
        $lastName: String
        $userName: String
        $companyIds: [Int]
        $craftUserTypeSlug: String
        $languageCode: String
        $permissions: [String]
    ) {
        upsertUser(
            id: $id
            email: $email
            firstName: $firstName
            lastName: $lastName
            username: $userName
            company: $companyIds
            craftUserTypeSlug: $craftUserTypeSlug
            permissions: $permissions
            languageCode: $languageCode
        ) {
            id
            username
            firstName
            lastName
            email
            company {
                id
                title
            }
            languageCode
            craftUserTypeSlug
        }
    }
`;
