<template>
    <label class="radio">
        <div>
            <slot></slot>
            <svg viewBox="0 0 172 172">
                <g
                    fill="none"
                    stroke-width="none"
                    stroke-miterlimit="10"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                >
                    <ellipse ry="75" rx="75" id="svg_1" cy="86" cx="86" fill="currentColor" stroke-width="1" />
                </g>
            </svg>
        </div>
        <span>{{ label }}</span>
    </label>
</template>
<script>
export default {
    name: "Radiobutton",
    props: {
        label: {
            type: String,
        },
    },
};
</script>
