<template>
    <!--Modal-->
    <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 left-0 top-0"></div>

        <div class="modal-container bg-white w-11/12 md:max-w-md rounded shadow-lg z-50 overflow-y-auto">
            <div
                class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
            >
                <svg
                    class="fill-current text-white"
                    height="18"
                    viewBox="0 0 18 18"
                    width="18"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                    ></path>
                </svg>
                <span class="text-sm">(Esc)</span>
            </div>

            <!-- Add margin if you want to see some of the overlay behind the modal-->
            <div class="modal-content py-4 text-left px-6">
                <!--Title-->
                <div class="flex justify-between items-center pb-3">
                    <p class="text-2xl font-bold" v-trans="'sign-in'">Aanmelden</p>
                </div>

                <!--Body-->
                <p class="pb-5" v-trans="'code-more-info'">Gebruik uw unieke code om toegang aan te melden.</p>
                <div class="form-group mb-2 mt-4">
                    <label for="code" v-trans="'unique-code'"> Unieke Code: (xxxx-xxxx) </label>
                    <input
                        :disabled="!craftUserLoggedIn"
                        @keydown="onKeyDown"
                        @keyup.enter="getUser"
                        class="form-control"
                        id="code"
                        placeholder="____-____"
                        ref="guid"
                        type="text"
                        v-model="formattedGuid"
                    />
                </div>

                <!--				<div v-if="isLoading" class="text-primary-normal pt-4">Even geduld ...</div>-->
                <div class="text-primary-normal pt-4 alert alert--danger" v-if="error">{{ error }}</div>

                <div class="c-preloader mt-4 c-preloader--fixed" v-if="isLoading"><i></i><i></i><i></i></div>

                <!--Footer-->
                <div class="flex justify-end pt-4">
                    <button
                        :disabled="!craftUserLoggedIn || nakedGuid.length !== 8"
                        @click="getUser"
                        class="btn btn--primary"
                        v-trans="'sign-in'"
                    >
                        Aanmelden
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ACTION_GET_PERSON_BY_GUID } from "../store/constants";
import Vue from "vue";

export default {
    name: "EnterCode",
    data() {
        return {
            craftUserLoggedIn: false,
            isLoading: false,
            guid: "",
            error: "",
            codeCaretToPosition: 0,
        };
    },

    mounted() {
        this.isLoading = true;
        console.log("BEFORE POST");
        this.$axios
            .post("/", {
                loginName: "DummyContractor",
                password: "password",
                action: "users/login",
                withCredentials: true, // If true, send cookie stored in jar
            })
            .then((response) => {
                console.log("response", response);
                if (response.data.error) {
                    this.error = response.data.error;
                    this.isLoading = false;
                } else {
                    this.isLoading = false;
                    this.craftUserLoggedIn = true;

                    if (this.$route.query && this.$route.query.guid) {
                        this.guid = this.$route.query.guid;
                        this.getUser();
                        setTimeout(() => {
                            //remove query string
                            this.$router.replace({ name: this.$route.name, query: {} });
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            if (this.$refs.guid) {
                                this.$refs.guid.focus();
                            }
                        }, 400);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.error = error.message;
                this.isLoading = false;
            });
    },

    computed: {
        nakedGuid() {
            return this.guid.split("_").join("").split("-").join("");
        },
        formattedGuid: {
            get() {
                return this.formatGuid(this.guid);
            },
            set(v) {
                this.guid = this.formatGuid(v);
            },
        },
    },

    methods: {
        onKeyDown(e) {
            //console.log("e:", this.codeCaretToPosition, e);
            if (e.code === "Backspace") {
                if (this.codeCaretToPosition === 5) {
                    e.preventDefault();
                    this.guid = this.guid.substr(0, 3); //+ this.guid.substr(4,4)
                }
            } else if (e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                if (this.nakedGuid.length === 8 && e.key.length === 1) {
                    this.guid =
                        this.guid.substr(0, e.target.selectionStart) +
                        e.key +
                        this.guid.substr(e.target.selectionStart + 1);
                    e.preventDefault();
                }
            }
        },

        setSelectionRange(inputElement, selectionStart, selectionEnd) {
            if (inputElement.setSelectionRange) {
                inputElement.focus();
                inputElement.setSelectionRange(selectionStart, selectionEnd);
            } else if (inputElement.createTextRange) {
                var range = inputElement.createTextRange();
                range.collapse(true);
                range.moveEnd("character", selectionEnd);
                range.moveStart("character", selectionStart);
                range.select();
            }
        },

        setCaretToPosition(inputElement, pos) {
            this.setSelectionRange(inputElement, pos, pos);
        },

        formatGuid(code) {
            let nakedGuid = code.split("_").join("").split("-").join("");
            let firstPart = nakedGuid.substr(0, 4);
            while (firstPart.length < 4) {
                firstPart = firstPart + "_";
            }
            let lastPart = nakedGuid.substr(4, 4);
            while (lastPart.length < 4) {
                lastPart = lastPart + "_";
            }
            let currentGuid = firstPart + "-" + lastPart;

            currentGuid = currentGuid.toUpperCase();
            let pos = currentGuid.indexOf("_") > -1 ? currentGuid.indexOf("_") : currentGuid.length;
            Vue.nextTick(() => {
                this.setCaretToPosition(this.$refs.guid, pos);
                this.codeCaretToPosition = pos;
            });
            return currentGuid;
        },

        getUser() {
            this.error = "";
            if (this.guid.length === 0) {
                const TRANS_ID = "invalid-code-length";
                this.error = this.$trans.hasEntry(TRANS_ID) ? this.$trans.getEntry(TRANS_ID).html : TRANS_ID;
                return;
            }
            this.isLoading = true;
            this.$store
                .dispatch(ACTION_GET_PERSON_BY_GUID, this.guid)
                .then((e) => {
                    console.log("ACTION_GET_PERSON_BY_GUID THEN", e);
                })
                .catch((e) => {
                    console.log("ACTION_GET_PERSON_BY_GUID ERROR", e);
                    console.log(this.$trans.getEntry(e.message));
                    this.error = this.$trans.hasEntry(e.message)
                        ? this.$trans.getEntry(e.message).html
                        : JSON.stringify(e);
                })
                .finally(() => {
                    this.isLoading = false;
                    if (this.$refs.guid) {
                        this.$refs.guid.focus();
                    }
                });
        },
    },
};
</script>

<style scoped></style>
