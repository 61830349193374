<template>
    <!--WARNING-->
    <!--this component is used at several places!-->

    <!--Modal-->
    <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 left-0 top-0"></div>

        <div class="modal-container bg-white w-11/12 md:max-w-xl rounded shadow-lg z-50 overflow-y-auto">
            <div
                @click="cancel"
                class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-sm z-50"
            >
                <svg class="fill-current" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                    ></path>
                </svg>
                <!--				<span class="text-sm">(Esc)</span>-->
            </div>

            <!-- Add margin if you want to see some of the overlay behind the modal-->
            <div class="modal-content py-4 text-left px-6">
                <!--Title-->
                <div class="flex justify-between items-center pb-3">
                    <p class="text-2xl font-bold">{{ caption }}</p>
                </div>

                <!--Body-->
                <craft-user-edit
                    :user="user"
                    :show-cancel-button="true"
                    :profile-is-editable="true"
                    @cancel="cancel"
                    :craft-user-type-slug="craftUserTypeSlug"
                    @saveSucceeded="saveSucceeded"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CraftUserEdit from "./CraftUserEdit";

export default {
    name: "AddOrEditUser",
    components: { CraftUserEdit },
    props: {
        caption: {},
        user: {
            type: Object,
            required: true,
        },
        craftUserTypeSlug: {
            type: String,
            required: true,
        },
    },

    methods: {
        saveSucceeded(user) {
            this.$emit("saveSucceeded", user);
        },

        cancel() {
            this.$emit("cancel");
        },
    },
};
</script>
