<template>
    <div class="form-wrap">
        <back-button></back-button>
        <div class="c-preloader absolute top-0 right-0" style="right: -17px" v-if="personsAreLoading">
            <i></i><i></i><i></i>
        </div>

        <ul class="list-reset flex mb-6" v-if="isVisitorOffice || isTruckDriver">
            <li class="flex-1 cursor-pointer">
                <a
                    :class="{ 'tab-btn--active': existingLookup, 'tab-btn': !existingLookup }"
                    @click="switchInputType(true)"
                    class="text-center block rounded py-4 px-4"
                    v-trans="'registered-visitor'"
                >
                    Geregistreerde bezoeker
                </a>
            </li>
            <li class="flex-1 mr-2 cursor-pointer">
                <a
                    :class="{ 'tab-btn--active': !existingLookup, 'tab-btn': existingLookup }"
                    @click="switchInputType(false)"
                    class="text-center block rounded py-4 px-4"
                    v-trans="'new-visitor'"
                >
                    New visitor
                </a>
            </li>
        </ul>
        <div class="inner-form">
            <div v-show="existingLookup">
                <div class="mb-4 form-group" v-show="this.person === null">
                    <label class="form-label" v-trans="'name-visitor-helper'">
                        Voer de eerste 3 letters van je familienaam of voornaam in.
                    </label>
                    <div class="c-preloader h-6">
                        <span v-if="personsAreLoading"><i></i><i></i><i></i></span>
                    </div>
                    <vue-suggestion
                        :setLabel="setPersonLabel"
                        :itemTemplate="dropdownItemTemplate"
                        :items="persons"
                        @onInputChange="personInputChange"
                        @onItemSelected="personItemSelected"
                        ref="searchField"
                        v-model="person"
                    >
                    </vue-suggestion>
                </div>

                <div v-if="registrationNotFound && (isContractor || isVisitorFactory) && !personsAreLoading">
                    <p v-trans="'registration-not-found'">We kunnen uw gegevens niet terugvinden.</p>
                    <p v-trans="'registration-contractor-not-found'">
                        Gelieve de baliemedewerker te contacteren om uw gegevens aan het systeem toe te voegen.
                    </p>
                </div>
                <div v-if="registrationNotFound && (isVisitorOffice || isTruckDriver) && !personsAreLoading">
                    <p v-trans="'registration-visitor-not-found'">
                        Selecteer <strong>nieuwe bezoeker</strong> om verder te gaan.
                    </p>
                </div>
            </div>

            <div v-if="showPersonDetailFields">
                <div
                    v-if="existingLookup"
                    class="border-0 opacity-75 border-b-2 mb-3 py-3 pt-0 text-center border-gray-400"
                >
                    <span v-trans="'not-your-data'" class="mr-3">Dit zijn niet uw gegevens?</span>
                    <button
                        class="btn"
                        v-if="existingLookup && this.person !== null"
                        @click="resetSearch"
                        v-trans="'search-again'"
                    >
                        Opnieuw zoeken
                    </button>
                </div>
                <div class="flex -mx-2">
                    <div class="mb-4 mx-2 form-group w-1/2">
                        <label class="form-label" for="first-name">
                            <span v-trans="'first-name'">Voornaam</span>
                            <asterisk-icon />
                        </label>
                        <input class="form-control" id="first-name" type="text" v-model="firstName" />
                    </div>
                    <div class="mb-4 mx-2 form-group w-1/2">
                        <label class="form-label" for="last-name">
                            <span v-trans="'last-name'">Familienaam</span>
                            <asterisk-icon />
                        </label>
                        <input class="form-control" id="last-name" type="text" v-model="lastName" />
                    </div>
                </div>

                <div class="flex -mx-2">
                    <div class="mb-4 mx-2 w-1/2 form-group">
                        <label class="form-label" for="company">
                            <span v-trans="'company'">Bedrijf</span>
                            <asterisk-icon v-show="isTruckDriver || isVisitorOffice" />
                        </label>

                        <vue-suggestion
                            :disabled="isContractor || isVisitorFactory"
                            :itemTemplate="dropdownItemTemplate"
                            :items="filteredCompanies"
                            :setLabel="setCompanyLabel"
                            @onInputChange="companyInputChange"
                            @onItemSelected="companyItemSelected"
                            id="company"
                            ref="companyField"
                            v-model="company"
                        >
                        </vue-suggestion>
                        <!--                    <input v-show="isContractor" class="form-control" type="text" disabled v-model="company.title">-->
                    </div>

                    <div class="mb-4 mx-2 w-1/2 form-group">
                        <label class="form-label" for="license-plate" v-show="!isTruckDriver" v-trans="'license-plate'">
                            Nummerplaat
                        </label>
                        <label class="form-label" for="license-plate" v-show="isTruckDriver">
                            <span v-trans="'license-plate-trucker'">Nummerplaat tractor</span>
                            <asterisk-icon />
                        </label>
                        <input class="form-control" id="license-plate" type="text" v-model="licensePlate" />
                    </div>
                </div>

                <div class="mb-4 form-group" v-if="isContractor || isVisitorFactory">
                    <label class="form-label" for="mobile-phone" v-trans="'mobile-phone'"> Mobiel nummer </label>

                    <input class="form-control" id="mobile-phone" type="text" v-model="mobilePhone" />
                </div>

                <div class="mb-4 form-group" v-if="!isTruckDriver">
                    <label class="form-label" for="internal-contact" v-trans="'visiting'"> Contactpersoon </label>
                    <vue-suggestion
                        :itemTemplate="dropdownItemTemplate"
                        :items="filteredContacts"
                        :setLabel="setInternalContactLabel"
                        @onInputChange="internalContactInputChange"
                        @onItemSelected="internalContactItemSelected"
                        id="internal-contact"
                        v-model="internalContact"
                    >
                    </vue-suggestion>
                </div>
            </div>
        </div>
        <div class="form-actions">
            <div class="form-group flex py-4" v-if="showPersonDetailFields && person && person.safetyViolation">
                <div class="rounded-full bg-primary-dark w-6 h-6 p-0 text-center p-0 my-1 animation-pulse ml-3 mr-6">
                    <alert-icon class="text-orange-200" />
                </div>
                <span class="text-lg font-bold" v-trans="'veiligheidsinstructies-overtreden-meld-je'"
                    ><span class="uppercase">Belangrijke mededeling:</span> Meld je aan voor meer info!</span
                >
            </div>

            <div class="form-group flex py-4" v-if="showPersonDetailFields">
                <label class="checkbox mr-3">
                    <div>
                        <input type="checkbox" v-model="agreedToPP" />
                        <svg viewBox="0 0 172 172">
                            <g
                                fill="none"
                                font-family="none"
                                font-size="none"
                                font-weight="none"
                                stroke-miterlimit="10"
                                stroke-width="none"
                                style="mix-blend-mode: normal"
                                text-anchor="none"
                            >
                                <path d="M0 172V0h172v172z" />
                                <path
                                    d="M145.433 37.933L64.5 118.7558 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                                    fill="currentColor"
                                    stroke-width="1"
                                />
                            </g>
                        </svg>
                    </div>
                    <span v-trans="'privacy-agreement'">Ik ga akkoord met de privacy verklaring.</span>
                </label>
                <span
                    @click="openPrivacyStatement"
                    class="text-secondary-light cursor-pointer"
                    v-trans="'privacy-clickToRead'"
                    >Privacy verklaring bekijken</span
                >
            </div>

            <div v-if="showPersonDetailFields">
                <asterisk-icon />
                <span v-trans="'required-field'">Verplicht veld</span>
            </div>
            <button
                :class="{
                    'btn--primary': formValid && this.agreedToPP,
                    'btn--secondary': !(formValid && this.agreedToPP),
                }"
                @click="preRegisterPerson"
                class="mobile-button block w-full md:mx-auto md:w-1/2 lg:w-1/3 xl:w-1/4 mt-8 btn btn--icon p-4 px-6"
            >
                <span v-trans="'next'">Volgende</span>
                <ArrowRightIcon />
            </button>
        </div>
        <dynamic-modal
            :header-text="modalHeader"
            :positiveText="$trans.getTranslation('ok', 'OK')"
            @close="onErrorModalClosed()"
            :buttons="1"
            v-show="errorModal"
        >
            <div class="mx-auto text-center" slot="content">
                <div v-html="modalMessage"></div>
            </div>
        </dynamic-modal>

        <dynamic-modal
            :header-text="$trans.getTranslation('checkin-error--header', 'Fout bij registratie')"
            :positiveText="$trans.getTranslation('yes', 'Ja')"
            :negative-text="$trans.getTranslation('no', 'Nee')"
            @close="alreadyLoggedInModal = false"
            @confirm="confirmHandler"
            @cancel="cancelHandler"
            :buttons="3"
            v-show="alreadyLoggedInModal"
        >
            <div class="mx-auto text-center" slot="content">
                <div v-html="$trans.getTranslation('checkin-error--body', 'U bent reeds aangemeld')"></div>
            </div>
        </dynamic-modal>

        <autograph @cancel="signatureModal = false" @confirm="saveSignature" v-if="signatureModal" />

        <dynamic-buttonless-modal
            v-show="privacyModal"
            @close="privacyModal = false"
            :header-text="$trans.getTranslation('privacy-policy', 'Privacy policy')"
            :limit-height="true"
        >
            <article slot="content">
                <privacy-notice />
            </article>
        </dynamic-buttonless-modal>

        <dynamic-buttonless-modal
            :header-text="$trans.getTranslation('registering', 'Bezig met aanmelden')"
            @cancel="registeringModal = false"
            @close="registeringModal = false"
            v-show="registeringModal"
        >
            <div slot="content">
                <div class="text-5xl text-center">
                    <div class="spinner inline-block">
                        <loading-icon />
                    </div>
                </div>
            </div>
        </dynamic-buttonless-modal>
    </div>
</template>
<script>
import ArrowRightIcon from "vue-material-design-icons/ArrowRight.vue";
import LoadingIcon from "vue-material-design-icons/Loading";
import { GET_ALL_COMPANIES_QUERY } from "../../graphql/queries/Company";

import BackButton from "../../components/BackButton";

import VueSuggestion from "../../components/SuggestionBox";
import dropdownItemTemplate from "../../components/DropdownItemTemplate";
import { ACTION_GET_INTERNAL_CONTACTS, COMPANY_TYPE, STORE_VISIT_SESSION } from "../../store/constants";
import {
    GET_INDIVIDUAL_PERSON_RESULT_STATUS,
    GET_PERSON_BY_GUID2,
    GET_PERSON_BY_NAME2,
    SAVE_PERSON2,
} from "../../graphql/queries/Person";
import DynamicModal from "../../components/DynamicModal";
import Autograph from "../../components/Autograph";

import { DOUBLE_BOOKING_CHECK, SAVE_VISIT } from "../../graphql/queries/Visit";
import { SAVE_INTERNAL_CONTACT } from "../../graphql/queries/InternalContact";
import DynamicButtonlessModal from "../../components/DynamicButtonlessModal";
import AsteriskIcon from "../../components/AsteriskIcon";
import PrivacyNotice from "../../components/PrivacyNotice";
import { GET_ALL_LANGUAGES_QUERY } from "../../graphql/queries/Language";
import AlertIcon from "vue-material-design-icons/Alert.vue";
import { Utils } from "@/utils/utils";

export default {
    name: "CheckIn",
    components: {
        PrivacyNotice,
        AsteriskIcon,
        DynamicButtonlessModal,
        Autograph,
        DynamicModal,
        ArrowRightIcon,
        VueSuggestion,
        BackButton,
        LoadingIcon,
        AlertIcon,
    },
    mounted() {
        // Refetch
        this.$store.dispatch(ACTION_GET_INTERNAL_CONTACTS);

        //we came here with a qr-code scan, so we have to get the Person from the guid
        if (this.$route.params.guid.length === 9) {
            this.$apollo
                .query({
                    query: GET_PERSON_BY_GUID2,
                    variables: { guid: this.$route.params.guid },
                    fetchPolicy: "network-only",
                })
                .then((response) => {
                    // console.log("Gert: response:", response);
                    if (response && response.data && response.data.entries && response.data.entries.length > 0) {
                        this.personItemSelected(response.data.entries[0]);
                    } else {
                        this.errorModalButtons = 1;
                        this.errorModal = true;
                        this.modalHeader = this.$trans.getTranslation("ongekende-gebruiker", "Ongekende gebruiker");
                        this.modalMessage = this.$trans.getTranslation(
                            "ongekende-gebruiker--body",
                            "Deze badge is ongeldig of de gebruiker is niet gekend. <br><br>Meld je bij de receptie."
                        );
                        this.afterModalCloseFunction = () => {
                            this.$router.push({ name: "onsite" });
                        };
                    }
                });
        } else {
            this.existingLookup = true;
        }

        setTimeout(() => {
            this.$el.getElementsByClassName("vue-suggestion-input")[0].focus();
        }, 200);
    },

    data() {
        return {
            // AUTOCOMPLETE SEARCH AUTOCOMPLETE FUNCTIONS
            companiesAreLoading: false,
            contactsAreLoading: false,
            personsAreLoading: false,
            status: "live",

            // V-MODEL FIELDS
            person: null, // REFERENCE TO PERSON DB OBJECT
            firstName: "",
            lastName: "",
            company: null, // REFERENCE TO COMPANY DB OBJECT
            companyName: "",
            internalContact: null, // REFERENCE TO INTERNAL CONTACT DB OBJECT
            internalContactName: "",
            agreedToPP: true,

            licensePlate: "",
            signature: 0, // REFERENCE TO SIGNATURE ASSET DB OBJECT

            filteredCompanies: [],
            filteredContacts: [],
            existingLookup: false,
            personQuery: "",

            // Contractor field
            mobilePhone: "",

            // TRUCKER FIELDS
            loadingOrUnloading: "undefined", // enum undefined, loading, unloading
            needsWeighing: false,
            orderNumber: "",

            // MODAL(S)
            errorModal: false,
            errorModalButtons: 1,
            signatureModal: false,
            modalMessage: "",
            modalHeader: "",
            privacyModal: false,
            registeringModal: false,
            alreadyLoggedInModal: false,
            afterModalCloseFunction: null,

            dropdownItemTemplate,
            iidInputChange: -1,
        };
    },
    computed: {
        registrationNotFound() {
            return (
                this.personQuery.length >= 3 &&
                ((this.persons !== undefined && this.persons.length === 0) || this.persons === undefined)
            );
        },
        contacts() {
            return this.$store.state.visit.internalContacts.filter(
                (thing, index, self) => index === self.findIndex((t) => t.title === thing.title)
            );
        },

        isContractor() {
            return this.$route.params.type === this.$store.getters.getVisitTypeIdForContractor;
        },
        isTruckDriver() {
            return this.$route.params.type === this.$store.getters.getVisitTypeIdForTruckDriver;
        },
        isVisitorFactory() {
            return this.$route.params.type === this.$store.getters.getVisitTypeIdForVisitorFactory;
        },
        isVisitorOffice() {
            return this.$route.params.type === this.$store.getters.getVisitTypeIdForVisitorOffice;
        },

        showPersonDetailFields() {
            return this.person !== null || !this.existingLookup;
        },

        formValid() {
            let basicRequirements =
                this.firstName !== "" && this.lastName !== "" && (this.company != null || this.companyName !== "");

            if (this.isTruckDriver) {
                basicRequirements = basicRequirements && this.licensePlate;
                //&& this.loadingOrUnloading
                //&& this.needsWeighing !== null;
            }

            return basicRequirements;
        },
    },
    apollo: {
        companies: {
            query: GET_ALL_COMPANIES_QUERY,
            fetchPolicy: "cache-and-network",
            variables() {
                return {
                    status: this.status,
                    companyTypes: [this.isTruckDriver ? COMPANY_TYPE.TRANSPORT : COMPANY_TYPE.CONTRACTOR],
                };
            },
            update(data) {
                if (data.entries) {
                    return data.entries;
                } else {
                    return [];
                }
            },
            watchLoading(isLoading) {
                this.companiesAreLoading = isLoading;
            },
            error(error) {
                if (error.gqlError !== null) {
                    if (error.message === "GraphQL error: Not authorized") {
                        this.$router.push({ name: "onsite-home" });
                    }
                }
            },
        },
        persons() {
            return {
                query: GET_PERSON_BY_NAME2,
                fetchPolicy: "cache-and-network",
                variables() {
                    return {
                        search: `title:*${this.personQuery}*`,
                        limit: 100,
                    };
                },
                update(data) {
                    if (data.entries) {
                        const r = Utils.sortArrayOfObjects(data.entries, ["lastName", "firstName"]);
                        // console.log("r", r);
                        return r;
                    } else {
                        return [];
                    }
                },
                watchLoading(isLoading) {
                    this.personsAreLoading = isLoading;
                },
                error(error) {
                    if (error.gqlError !== null) {
                        if (error.message === "GraphQL error: Not authorized") {
                            this.$router.push({ name: "onsite-home" });
                        }
                    }
                },
                // DON'T EXECUTE IF SEARCH QUERY IS LESS THAN 3 CHARACTERS
                skip() {
                    return this.personQuery.length < 3;
                },
            };
        },

        languages: {
            query: GET_ALL_LANGUAGES_QUERY,
            update(data) {
                if (data.categories) {
                    return data.categories;
                } else {
                    return [];
                }
            },
        },
    },
    methods: {
        switchInputType(newValue) {
            this.existingLookup = newValue;
            if (!this.existingLookup) {
                this.agreedToPP = false;
            }
            this.person = null;
            this.firstName = "";
            this.lastName = "";
            this.licensePlate = "";
            this.needsWeighing = null;
            this.companyName = "";
            this.company = null;
            this.orderNumber = null;

            if (newValue === true) {
                this.resetSearch();
            }
            // Force UI update on company field
            if (this.$refs["companyField"]) this.$refs["companyField"].reset();
        },
        // COMPANY SEARCH AUTOCOMPLETE FUNCTIONS
        setCompanyLabel(item) {
            if (typeof item === "object") return item.title;
            else return item;
        },
        companyItemSelected(item) {
            this.companyName = item.title;
        },
        companyInputChange(text) {
            this.companyName = text;
            this.company = null;
            if (text.length < 3) return;
            this.filteredCompanies = this.companies.filter((item) =>
                item.title.toLowerCase().includes(text.toLowerCase())
            );
        },
        // INTERNAL CONTACT SEARCH AUTOCOMPLETE FUNCTIONS
        setInternalContactLabel(item) {
            if (typeof item === "object") return item.title;
            else return item;
        },
        internalContactItemSelected(item) {
            this.internalContactName = item.title;
        },
        internalContactInputChange(text) {
            this.internalContactName = text;
            this.internalContact = null;
            if (text.length < 3) return;
            this.filteredContacts = this.contacts.filter((item) =>
                item.title.toLowerCase().includes(text.toLowerCase())
            );
        },
        // REGISTERED PERSON SEARCH AUTOCOMPLETE FUNCTIONS
        setPersonLabel(item) {
            return item;
        },
        personItemSelected(item) {
            this.person = item;

            this.firstName = item.firstName;
            this.lastName = item.lastName;
            this.licensePlate = item.licensePlate;
            this.mobilePhone = item.mobilePhone;
            this.needsWeighing = null;

            if (item.company && item.company.length > 0) {
                this.companyName = item.company[0].title;
                this.company = item.company[0];
            } else {
                this.company = this.companyName = item.companyName;
            }

            //  this.personQuery = item.title;
        },
        personInputChange(text) {
            if (text.length < 3) return;
            clearTimeout(this.iidInputChange);
            this.iidInputChange = setTimeout(() => {
                this.personQuery = text;
            }, 600);
        },
        cancelHandler() {
            this.$router.go(-1);
        },
        confirmHandler() {
            this.$router.push({ name: "onsite-checkOut", params: { id: -1 } });
        },

        // UI INTERACTIONS
        openPrivacyStatement() {
            this.privacyModal = true;
        },
        resetSearch() {
            this.person = null;
            this.personQuery = "";
            this.persons = [];

            this.$refs["searchField"].resetAndFocus();
        },
        preRegisterPerson() {
            if (!this.formValid) {
                this.errorModalButtons = 1;
                this.errorModal = true;
                this.modalHeader = this.$trans.getTranslation("validation-error--header", "Fout bij ingave");
                this.modalMessage = this.$trans.getTranslation(
                    "validation-error--body",
                    "Gelieve alle verplichte velden in te vullen"
                );
            } else if (!this.agreedToPP) {
                this.errorModalButtons = 1;
                this.errorModal = true;

                this.modalHeader = this.$trans.getTranslation("validation-error--header", "Fout bij ingave");
                this.modalMessage = this.$trans.getTranslation(
                    "validation-privacy-policy--body",
                    "Gelieve in te stemmen met de privacy voorwaarden"
                );
            } else {
                if (this.existingLookup) {
                    this.checkIfAlreadyInside();
                } else {
                    this.askForSignature();
                }
            }
        },
        checkIfAlreadyInside() {
            this.registeringModal = true;

            this.$apollo
                .query({
                    query: DOUBLE_BOOKING_CHECK,
                    variables: {
                        personId: [parseInt(this.person.id)],
                    },
                    fetchPolicy: "network-only",
                })
                .then(
                    (result) => {
                        this.registeringModal = false;

                        if (result.data.entries.length > 0) {
                            this.alreadyLoggedInModal = true;
                        } else {
                            this.askForSignature();
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },

        askForSignature() {
            if (this.existingLookup) {
                console.log("this.person", this.person);
                this.savePerson();
            } else {
                this.signatureModal = true;
            }
        },

        // ONLY REGISTRATION HANDLING FUNCTIONS BELOW
        saveSignature(data) {
            this.signatureModal = false;
            this.registeringModal = true;

            this.$axios({
                url: `rest/v1/asset/upload`,
                method: "post",
                responseType: "json",
                headers: {
                    Accept: "application/json",
                },
                data: {
                    content: data,
                    type: "signatures",
                    title: `${this.firstName}-${this.lastName}`,
                },
            })
                .then((response) => {
                    this.signature = response.data.id;
                    this.savePerson();
                })
                .catch((reject) => {
                    console.log("reject:", reject);
                });
        },

        savePerson() {
            this.registeringModal = true;
            const person = {};
            if (this.person !== null) {
                person.id = this.person.id;
            }

            person.firstName = this.firstName;
            person.lastName = this.lastName;
            person.licensePlate = this.licensePlate;
            person.companyIds = [];
            if (this.company !== null) {
                person.companyIds = [parseInt(this.company.id)];
            } else {
                person.companyName = this.companyName;
            }

            if (this.isContractor) person.mobilePhone = this.mobilePhone;

            if (person.languageCategorie && person.languageCategorie.length > 0 && person.languageCategorie[0].id) {
                //keep existing
                person.languageIds = [parseInt(person.languageCategorie[0].id)];
            } else {
                //no lang yet: choose active lang
                let activeLangId = this.languages.find((item) => item.slug === this.$store.state.activeLanguage);
                if (activeLangId) {
                    activeLangId = activeLangId.id;
                } else {
                    activeLangId = 248;
                }
                person.languageIds = [parseInt(activeLangId)];
            }

            this.$apollo
                .mutate({
                    mutation: SAVE_PERSON2,
                    variables: person,
                    //fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                })
                .then((e) => {
                    this.person = e.data.save_person_person_Entry;
                    // console.log(this.person);
                    this.registeringModal = false;
                    this.saveContact();
                })
                .catch((error) => {
                    console.log("error", error);
                    this.errorModalButtons = 1;
                    this.errorModal = true;
                    this.modalHeader = "Fout bij registratie";
                    this.modalMessage = error;

                    this.registeringModal = false;
                });
        },

        saveContact() {
            /* FLOW!

                    -> Check if internalContact is new

                    New entry?
                        -> Save internalContact
                    */
            // console.log("Gert: save Contact");
            if (this.internalContact === null && this.internalContactName !== "") {
                this.registeringModal = true;
                this.$apollo
                    .mutate({
                        mutation: SAVE_INTERNAL_CONTACT,
                        variables: {
                            title: this.internalContactName,
                        },
                        fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                        // pollInterval: 1000,
                    })
                    .then(
                        (result) => {
                            this.internalContact = result.data.save_internalContacts_internalContacts_Entry;
                            this.registeringModal = false;
                            this.saveVisit();
                        },
                        (error) => {
                            console.log(error);
                            this.registeringModal = false;
                        }
                    );
            } else {
                this.saveVisit();
            }
        },
        saveVisit() {
            this.registeringModal = true;
            let expiryDate = new Date();
            //IMPORTANT: expiry date is overwitten and set on the backend because we don't want a client date to set this!
            console.log("this.person", this.person);
            const visit = {
                title: this.person.firstName + " " + this.person.lastName,
                //slug: this.person.slug,
                person: [parseInt(this.person.id)],
                visitType: [parseInt(this.$route.params.type)],
                signature: this.signature ? [parseInt(this.signature)] : [],
                privacyPolicy: true,
                needsWeighing: this.needsWeighing,
                expiryDate: expiryDate,
            };
            if (this.internalContact) {
                visit.internalContact = [this.internalContact.id];
                visit.internalContactName = this.internalContact.title;
            }
            if (this.isTruckDriver) {
                visit.loadingType = this.loadingOrUnloading;
                visit.orderNumber = this.orderNumber;
            }

            console.log("visit", visit);

            this.$apollo
                .mutate({
                    mutation: SAVE_VISIT,
                    variables: visit,
                    //fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                })
                .then((e) => {
                    this.visit = e.data.save_visit_visit_Entry;

                    this.registeringModal = false;

                    // Yes
                    this.$store.commit(STORE_VISIT_SESSION, { person: this.visit, visit: this.person }); // Necessary to store?

                    if (
                        this.$route.params.type === this.$store.getters.getVisitTypeIdForVisitorOffice ||
                        this.$route.params.type === this.$store.getters.getVisitTypeIdForTruckDriver
                    ) {
                        this.registrationComplete();
                    }
                    // CHeck if use has access (completed the tests)
                    else {
                        this.$apollo
                            .query({
                                query: GET_INDIVIDUAL_PERSON_RESULT_STATUS,
                                variables: { id: [this.person.id] },
                            })
                            .then(
                                (e) => {
                                    // console.log("TEST GERT", e);
                                    console.log("GET_INDIVIDUAL_PERSON_RESULT_STATUS", e.data.entries);
                                    if (
                                        e.data.entries.length > 0 &&
                                        e.data.entries[0].learningTrackResults.length > 0
                                    ) {
                                        // Check status first
                                        const ltResults = e.data.entries[0].learningTrackResults;

                                        let allowedEntry = false;

                                        let learningTrackToCheck = null;
                                        if (
                                            this.$route.params.type === this.$store.getters.getVisitTypeIdForContractor
                                        ) {
                                            learningTrackToCheck = this.$store.state.persons.learningTracks.find(
                                                (lt) => lt.slug === "contractors"
                                            );
                                        } else if (
                                            this.$route.params.type ===
                                            this.$store.getters.getVisitTypeIdForVisitorFactory
                                        ) {
                                            learningTrackToCheck = this.$store.state.persons.learningTracks.find(
                                                (lt) => lt.slug === "visitors-factory"
                                            );
                                        }

                                        ltResults.every((ltr) => {
                                            //every is like forEach but stops when false is returned
                                            console.log("ltr", ltr, ltr.learningTrack[0].id, learningTrackToCheck);
                                            const isPassedOnLearningTrack =
                                                ltr.learningTrack[0].id === learningTrackToCheck?.id &&
                                                ltr.learningTrackResultStatus[0].slug === "passed";
                                            allowedEntry =
                                                ltr.expiryDate !== null &&
                                                new Date(ltr.expiryDate).getTime() > new Date().getTime() &&
                                                isPassedOnLearningTrack;
                                            if (allowedEntry) {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        });

                                        this.registeringModal = false;

                                        if (allowedEntry) {
                                            this.registrationComplete();
                                        } else {
                                            this.registrationIncomplete();
                                        }
                                    } else {
                                        this.registrationIncomplete();
                                    }
                                },
                                (err) => {
                                    console.log(err);
                                }
                            );
                    }
                })
                .catch((err) => {
                    console.log("saveVisit error ::", err);
                    this.registeringModal = false;
                    this.errorModalButtons = 1;
                    this.errorModal = true;
                    this.modalHeader = "Error Saving Visit";
                    this.modalMessage = err;
                });
        },
        registrationComplete() {
            let type;
            if (this.$route.params.type === this.$store.getters.getVisitTypeIdForTruckDriver && !this.needsWeighing) {
                //overrule chauffeur met visitor omdat er geen melding van auto-checkout moet zijn
                type = this.$store.getters.getVisitTypeIdForVisitorOffice;
            } else {
                type = this.$route.params.type;
            }
            console.log("this.person.safetyViolation", this.person.safetyViolation);
            this.$router.replace({
                name: "onsite-registrationComplete",
                params: { type: type, violation: this.person.safetyViolation },
            });
        },
        registrationIncomplete() {
            this.$router.replace({ name: "onsite-registrationIncomplete" });
        },
        onErrorModalClosed() {
            this.errorModal = false;
            if (this.afterModalCloseFunction) {
                this.afterModalCloseFunction.apply();
            }
        },
    },
};
</script>

<style scoped>
.animation-pulse {
    /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
    transform: scale(0.8);*/
    animation: pulse 3s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1.9);
        box-shadow: 0 0 0 0 rgb(42, 173, 199);
    }

    70% {
        transform: scale(2);
        box-shadow: 0 0 0 15px rgba(42, 173, 199, 0);
    }

    100% {
        transform: scale(1.9);
    }
}
</style>
