<template>
    <div>
        <form v-if="userToEdit && !needsConfirmation && !showPasswordInput">
            <div class="flex">
                <div class="form-group mb-5 w-full mr-5">
                    <label class="form-label">
                        <span v-trans="'first-name'">Voornaam</span>
                        <asterisk-icon />
                    </label>
                    <input
                        :readonly="isLoading"
                        class="form-control"
                        ref="firstName"
                        required
                        type="text"
                        v-model="userToEdit.firstName"
                    />
                </div>
                <div class="form-group mb-5 w-full">
                    <label class="form-label">
                        <span v-trans="'last-name'">Familienaam</span>
                        <asterisk-icon />
                    </label>
                    <input
                        :readonly="isLoading"
                        class="form-control"
                        required
                        type="text"
                        v-model="userToEdit.lastName"
                    />
                </div>
            </div>

            <div class="form-group mb-5">
                <label class="form-label">
                    <span v-trans="'email'">Email</span>
                    <asterisk-icon />
                </label>
                <input
                    :readonly="isLoading"
                    class="form-control"
                    type="email"
                    required
                    v-model="userToEdit.email"
                    @change="emailChanged = true"
                />
                <!--				<small class="form-text text-muted">We delen nooit emails met derden.</small>-->
            </div>

            <div class="flex">
                <div class="form-group mb-5 w-full mr-5">
                    <label class="form-label">
                        <span v-trans="'language'">Taal</span>
                        <asterisk-icon />
                    </label>

                    <select class="form-control" v-model="userToEdit.languageCode">
                        <option :key="language.slug" :value="language.slug" v-for="language in languages">
                            {{ language.title }}
                        </option>
                    </select>
                </div>

                <div class="form-group mb-5 w-full" v-if="userToEdit">
                    <label class="form-label">
                        <span v-trans="'profile'">Profiel</span>
                        <asterisk-icon />
                    </label>

                    <select
                        class="form-control"
                        v-model="userToEdit.craftUserTypeSlug"
                        v-if="$store.getters.isPortalAdmin && profileIsEditable"
                    >
                        <option
                            :key="profile.slug"
                            :value="profile.slug"
                            v-for="profile in filterProfiles(Utils.profiles)"
                        >
                            {{ profile.title }}
                        </option>
                    </select>

                    <div v-else>
                        <div class="form-control">{{ Utils.titleOfCraftUserType(userToEdit.craftUserTypeSlug) }}</div>
                        <div
                            class="text-xs text-gray-500"
                            v-if="
                                !userToEdit.id ||
                                $store.state.persons.currentContractorAdmin.id !== userToEdit.id.toString()
                            "
                        >
                            Enkel Portal Admins kunnen dit aanpassen.
                        </div>
                        <div class="text-xs text-gray-500" v-else>Je kan je eigen profiel niet aanpassen.</div>
                    </div>
                </div>
            </div>

            <div class="form-group mb-5" v-show="userToEdit.id">
                <a
                    href="#"
                    v-trans="'send-mail-to-rest-password'"
                    class="text-primary-normal text-sm cursor-pointer underline"
                    @click.prevent="sentResetPasswordMail"
                    >Verzend een e-mail om een nieuw wachtwoord te kiezen.</a
                >
            </div>

            <!--			<div class="form-group mb-5"-->
            <!--			     v-show="isCompanyAdministrator && showCancelButton && $store.state.persons.currentCompanyAdmin.id">-->
            <!--				<label>Bedrijf</label>-->
            <!--				<select class="form-control"-->
            <!--				        v-model="userToEdit.company[0].id">-->
            <!--					<option :key="company.id" v-bind:value="company.id" v-for="company in companies">-->
            <!--						{{ company.title }}-->
            <!--					</option>-->
            <!--				</select>-->
            <!--			</div>-->

            <div class="alert alert--info my-4 p-2 text-sm" v-show="!userToEdit.id">
                Nieuwe admins krijgen automatisch een mail met een uitnodiging om een wachtwoord aan te maken.
            </div>

            <p class="alert--success my-2 p-4 rounded" v-show="successMsg">{{ successMsg }}</p>
            <div class="text-primary-normal pt-4 alert alert--danger" v-if="error">
                {{ error }}
            </div>

            <div class="flex justify-between">
                <button @click="cancel" class="btn" v-show="showCancelButton" v-trans="'cancel'">Annuleren</button>
                <button @click="deleteUser" class="btn" v-show="showDeleteButton">
                    <span v-trans="'delete'">Wissen</span>
                </button>
                <button @click.prevent="saveUser" class="btn btn--primary btn--icon" type="submit">
                    <span v-trans="'save'">Opslaan</span>
                    <arrow-right-icon />
                </button>
                <button @click.prevent="logout" class="btn btn--secondary btn--icon" v-if="!showCancelButton">
                    <span v-trans="'logout'">logout</span>
                    <logout-icon />
                </button>
            </div>
        </form>
        <div v-if="userToEdit && needsConfirmation">
            <p v-trans="'delete-admin-confirmation'">Bent u zeker dat u deze gebruiker wil verwijderen?</p>
            <div class="mt-4 flex justify-between">
                <button @click="needsConfirmation = false" class="btn px-8">
                    <span v-trans="'no'">Nee</span>
                </button>
                <button @click="executeDeleteUser" class="btn btn--primary px-8">
                    <span v-trans="'yes'">Ja</span>
                </button>
            </div>
            <div class="text-primary-normal pt-4 mt-4 alert alert--danger" v-if="error">
                {{ error }}
            </div>
        </div>
        <div v-if="showPasswordInput" class="m-auto w-80 border-2 p-4 shadow-xl">
            <p v-trans="'validate-password-to-continue'">
                Voer je wachtwoord in om deze wijziging te kunnen doorvoeren.
            </p>
            <div class="mt-4">
                <input
                    ref="password"
                    type="password"
                    v-model="password"
                    class="form-control w-full px-2 py-1"
                    @keyup.enter="onPasswordInputDone"
                    required
                    :placeholder="$trans.getTranslation('password', 'Wachtwoord')"
                />
            </div>
            <div class="mt-4 flex justify-around">
                <button @click="onPasswordInputDone" class="btn px-8" :disabled="!password">
                    <span v-trans="'ok'">Ok</span>
                </button>
                <button @click="showPasswordInput = false" class="btn px-8">
                    <span v-trans="'cancel'">Annuleren</span>
                </button>
            </div>
            <div class="c-preloader mt-4" v-if="isLoading"><i></i><i></i><i></i></div>
        </div>
        <div class="c-preloader mt-4 c-preloader--fixed" v-if="isLoading"><i></i><i></i><i></i></div>
    </div>
</template>

<script>
import ArrowRightIcon from "vue-material-design-icons/ArrowRight.vue";
import LogoutIcon from "vue-material-design-icons/Logout.vue";

import { GET_ALL_LANGUAGES_QUERY } from "../graphql/queries/Language";
import { GET_USERS } from "../graphql/queries/User";
import AsteriskIcon from "./AsteriskIcon";

import { ACTION_LOG_OUT, CRAFT_USER_TYPE } from "../store/constants";
import { Utils } from "../utils/utils";

export default {
    name: "CraftUserEdit",
    components: {
        ArrowRightIcon,
        AsteriskIcon,
        LogoutIcon,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        showCancelButton: {
            type: Boolean,
            required: true,
        },
        profileIsEditable: {
            type: Boolean,
            required: true,
        },
        //used to know if a company admin or an contractor admin should be shown
        craftUserTypeSlug: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            userToEdit: null,
            isLoading: false,
            error: "",
            successMsg: "",
            needsConfirmation: false,
            Utils,
            emailChanged: false,
            showPasswordInput: false,
            password: "",
        };
    },

    computed: {
        isCompanyAdministrator() {
            return this.craftUserTypeSlug === CRAFT_USER_TYPE.COMPANY_ADMINISTRATOR_SLUG;
        },
        showDeleteButton() {
            // console.log("Gert: this.$store.state.persons.currentCompanyAdmin.id:", this.$store.state.persons.currentCompanyAdmin.id);
            // console.log("Gert: this.$store.state.persons.currentContractorAdmin.id:", this.$store.state.persons.currentContractorAdmin.id);
            // console.log("Gert: this.userToEdit.id:", this.userToEdit.id);

            if (!this.userToEdit.id) return false;

            if (this.userToEdit.id == this.$store.state.persons.currentContractorAdmin.id) return false;
            if (this.userToEdit.id == this.$store.state.persons.currentCompanyAdmin.id) return false;

            return true;
        },
    },

    watch: {
        //get lastest data from craft
        user(newUser) {
            this.initUser(newUser);
        },
    },

    apollo: {
        languages: {
            query: GET_ALL_LANGUAGES_QUERY,
            update(data) {
                if (data.categories) {
                    return data.categories;
                } else {
                    return [];
                }
            },
        },
    },

    mounted() {
        this.initUser(this.user);
    },

    methods: {
        filterProfiles(profiles) {
            // console.log("this.craftUserTypeSlug", this.craftUserTypeSlug);
            if (this.craftUserTypeSlug === CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG) {
                return profiles.filter((p) => p.slug === CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG);
            } else {
                return profiles.filter((p) => p.slug !== CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG);
            }
        },

        initUser(newUser) {
            // console.log("Gert: watching user:", newUser.firstName, newUser.id);
            if (this.$refs.firstName) {
                this.$refs.firstName.focus();
            }
            this.userToEdit = { ...newUser };

            // if (!this.userToEdit.language) {
            // 	this.userToEdit.language = [];
            // }
            if (!this.userToEdit.languageCode || this.userToEdit.languageCode.length !== 2) {
                this.userToEdit.languageCode = "nl";
            }
            this.userToEdit.craftUserTypeSlug = this.craftUserTypeSlug;
            // this.userToEdit.craftUserType.push({"id": 185, "title": "Contractor administrator"});
            //this.userToEdit.craftUserType.push({"id": 186, "title": "Company Administrator"});

            if (newUser.id) {
                let query = GET_USERS;

                this.$apollo
                    .query({
                        query: query,
                        variables: {
                            userIds: [parseInt(newUser.id)],
                            companyIds: null,
                        },
                        fetchPolicy: "network-only",
                    })
                    .then((response) => {
                        // console.log("Gert: response:", response);

                        if (response.data && response.data.users.length > 0) {
                            this.userToEdit = { ...response.data.users[0] };
                        } else {
                            throw new Error("GET_USER_BY_ID", response);
                        }
                    });
            }
            this.emailChanged = false;
        },

        saveUser() {
            // console.log("saveUser START");
            console.log("this.userToEdit.id = ", this.userToEdit.id, this.emailChanged);
            this.error = "";

            if (!this.showPasswordInput && this.emailChanged) {
                this.showPasswordInput = true;
                this.$nextTick(() => {
                    this.$refs.password.focus();
                });
                return;
            }

            this.isLoading = true;

            if (!this.userToEdit.firstName || !this.userToEdit.lastName || !this.userToEdit.email) {
                this.error = this.$trans.getTranslation(
                    "validation-error--body",
                    "Gelieve alle verplichte velden in te vullen."
                );
                this.isLoading = false;
                return false;
            }

            this.$axios
                .post("/", {
                    fields: {
                        languageCode: this.userToEdit.languageCode,
                        company: [parseInt(this.userToEdit.company[0].id)],
                        craftUserTypeSlug: this.userToEdit.craftUserTypeSlug,
                    },
                    userId: this.userToEdit.id === undefined ? null : parseInt(this.userToEdit.id),
                    firstName: this.userToEdit.firstName,
                    lastName: this.userToEdit.lastName,
                    userName: this.getUserName(),
                    email: this.userToEdit.email,

                    action: "users/save-user",
                    withCredentials: true, // If true, send cookie stored in jar
                })
                .then((response) => {
                    console.log("response:", response);
                    // console.log("jar:", this.$axios.defaults.jar);
                    if (response.data.errorCode) {
                        this.error = this.$trans.getTranslation(response.data.errorCode, response.data.error);
                    } else {
                        // console.log("parent", this.$parent);
                        this.$emit("saveSucceeded");
                    }
                })
                .catch((error) => {
                    console.log("err:", error);
                    if (error?.response?.data?.errors) {
                        let str = "";
                        let errors = error?.response?.data?.errors;
                        for (let key in errors) {
                            str += `${key}: `;
                            if (Array.isArray(errors[key])) {
                                str += errors[key].join(" - ");
                            } else {
                                str += errors[key];
                            }
                            str += "\n";
                        }
                        this.error = str;
                    } else if (error?.response?.data?.errorCode) {
                        this.error = this.$trans.getTranslation(
                            error.response.data.errorCode,
                            error.response.data.error
                        );
                    } else {
                        this.error = JSON.stringify(error.response);
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
            return true;
        },

        getUserName() {
            //only provide user name for new users (because existing ones would generate a new insert)
            if (this.userToEdit.id) {
                return undefined;
            } else {
                return (this.userToEdit.firstName + "_" + this.userToEdit.lastName + "_" + Math.random()).replace(
                    /[^A-Z0-9]+/gi,
                    "_"
                );
            }
        },

        cancel() {
            this.$emit("cancel");
        },
        deleteUser() {
            this.needsConfirmation = true;
        },
        executeDeleteUser() {
            this.isLoading = true;
            this.$axios({
                url: `rest/v1/user/delete-user`,
                // url: `${process.env.VUE_APP_REST_ENDPOINT}/actions/visit-registration-platform/user/delete-user`,
                method: "post",
                data: {
                    userId: this.userToEdit.id,
                },
            })
                .then((response) => {
                    console.log("response:", response);
                    this.$emit("saveSucceeded", response);
                    this.isLoading = false;
                })
                .catch((reject) => {
                    console.log("reject:", reject.response);
                    this.error = reject.response.data.error;
                    this.isLoading = false;
                });
        },

        sentResetPasswordMail() {
            this.isLoading = true;

            this.$axios
                .post("rest/v1/user/reset-password", {
                    email: this.userToEdit.email,
                    withCredentials: true, // If true, send cookie stored in jar
                })
                .then((response) => {
                    console.log("response:", response);
                    // console.log("jar:", this.$axios.defaults.jar);
                    if (response.data.errorCode) {
                        this.error = this.$trans.getTranslation(response.data.errorCode, response.data.error);
                    } else {
                        this.successMsg = this.$trans.getTranslation(
                            "password-reset-successful",
                            "Wachtwoord reset. Check uw email"
                        );
                        // this.flipCardBack();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.error = JSON.stringify(error.message);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        logout() {
            this.$store.dispatch(ACTION_LOG_OUT);
        },

        onPasswordInputDone() {
            this.isloading = true;

            this.$axios
                .post("/", {
                    action: "users/start-elevated-session",
                    withCredentials: true,
                    password: this.password,
                })

                .then(() => {
                    this.showPasswordInput = false;
                    this.emailChanged = false;
                    this.saveUser();
                })
                .catch((error) => {
                    console.log(error);
                    this.showPasswordInput = false;
                    this.error = this.$trans.getTranslation("Invalid password", "Ongeldig wachtwoord");
                })
                .finally(() => {
                    this.isloading = false;
                });
        },
    },
};
</script>
