<template>
    <div>
        <form>
            <div class="form-group mb-5" v-show="isNew">
                <label for="title"
                    ><span v-trans="'upload-new-file'">Upload nieuw bestand</span>
                    <asterisk-icon v-show="!certificate.url" />
                </label>
                <input
                    :readonly="isLoading"
                    @change="onFileChange"
                    accept=".xlsx,.xls,.jpg,.jpeg,.png,.gif,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                    class="form-control"
                    id="file"
                    ref="file"
                    type="file"
                />

                <div v-show="progress > 0"><span v-trans="'voortgang'">Voortgang</span>: {{ progress }}%</div>
            </div>

            <div class="form-group mb-5" v-show="false">
                <label for="title"
                    ><span v-trans="'name'">Naam</span>
                    <asterisk-icon />
                </label>
                <input
                    :readonly="isLoading"
                    class="form-control"
                    id="title"
                    ref="title"
                    required
                    type="text"
                    v-model="certificate.title"
                />
            </div>

            <div class="form-group mb-5" v-show="false">
                <label for="expiry-date" v-trans="'expiry-date'">Vervaldatum</label>
                <input
                    :readonly="isLoading"
                    class="form-control"
                    id="expiry-date"
                    type="text"
                    v-model="certificate.expiryDate"
                />
            </div>

            <div class="text-primary-normal pt-4 alert alert--danger" v-if="error">{{ error }}</div>

            <!--			<div class="mb-4">-->
            <!--				<asterisk-icon/>-->
            <!--				<span v-trans="'required-field'">Verplicht veld</span>-->
            <!--			</div>-->

            <div class="flex justify-between">
                <button @click="cancel" class="btn" v-show="showCancelButton" v-trans="cancel">Annuleren</button>
                <button @click="onDeleteClicked" class="btn" v-show="!isNew" v-trans="'delete'">Wissen</button>
                <button
                    :disabled="this.isLoading"
                    @click.prevent="saveCertificate"
                    class="btn btn--primary btn--icon"
                    type="submit"
                >
                    <span v-trans="'save'">Opslaan</span>
                    <arrow-right-icon />
                </button>
            </div>
        </form>
        <div class="c-preloader mt-4 c-preloader--fixed" v-if="isLoading"><i></i><i></i><i></i></div>
    </div>
</template>

<script>
import ArrowRightIcon from "vue-material-design-icons/ArrowRight.vue";
import AsteriskIcon from "./AsteriskIcon";

import { GET_COMPANY_CERTIFICATES, SAVE_COMPANY } from "@/graphql/queries/Company";
import { GET_PERSON_CERTIFICATES, SAVE_PERSON2 } from "@/graphql/queries/Person";
import { DELETE_CERTIFICATE, UPLOAD_CERTIFICATE } from "@/graphql/queries/Asset";

export default {
    name: "CertificateEdit",
    components: {
        ArrowRightIcon,
        AsteriskIcon,
    },
    props: {
        certificate: {
            type: Object,
            required: true,
        },
        isCompany: {
            type: Boolean,
            required: true,
        },
        showCancelButton: {
            type: Boolean,
            default: false,
        },
        isNew: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            file: null,
            progress: 0,
            error: "",
            newAssetId: null,
        };
    },

    apollo: {
        certificates() {
            return {
                query: this.isCompany ? GET_COMPANY_CERTIFICATES : GET_PERSON_CERTIFICATES,
                fetchPolicy: "cache-and-network",
                variables() {
                    if (this.isCompany) {
                        return {
                            companyId: this.$store.state.companies.currentCompany.id,
                        };
                    } else {
                        return {
                            personId: this.$store.state.persons.currentContractor.id,
                        };
                    }
                },
                update(data) {
                    if (data.entry.certificates) {
                        return data.entry.certificates;
                    } else {
                        return [];
                    }
                },
                watchLoading(isLoading) {
                    this.isLoading = isLoading;
                },
            };
        },
    },

    mounted() {
        this.$refs.title.focus();
    },

    methods: {
        async uploadFile() {
            this.error = "";
            this.isLoading = true;
            this.newAssetId = null;
            return new Promise((resolve, reject) => {
                if (this.file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(this.file);
                    reader.onloadend = () => {
                        //console.log("RESULT", this.file, reader.result);
                        this.$apollo
                            .mutate({
                                mutation: UPLOAD_CERTIFICATE,
                                variables: {
                                    file: {
                                        fileData: reader.result,
                                        //path
                                        //filename
                                    },
                                    title: this.file.name.split(".")[0],
                                },
                            })
                            .then((result) => {
                                // console.log("upload done", result);
                                if (result.data && result.data.error) {
                                    let errObj = JSON.parse(result.data.error);
                                    if (Array.isArray(errObj)) {
                                        this.error =
                                            errObj[0].filename +
                                            ": " +
                                            this.$trans.getTranslation(errObj[0].error, errObj[0].error);
                                    } else {
                                        this.error = JSON.stringify(result.data);
                                    }
                                    reject(this.error);
                                } else {
                                    this.newAssetId = result.data.save_certificates_Asset.id;
                                    resolve(this.newAssetId);
                                    // console.log("this.newAssetId", this.newAssetId);
                                }
                            })
                            .catch((e) => {
                                console.log("Error: e:", e);
                                this.error = JSON.stringify(e);
                                reject(this.error);
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    };
                } else {
                    reject("no file");
                }
            });
        },

        onFileChange() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files[0];
                if (!this.certificate.title) {
                    this.certificate.title = this.file.name;
                }
            } else {
                this.file = null;
            }
        },

        async saveCertificate() {
            this.isLoading = true;
            if (this.file) {
                await this.uploadFile();
            }

            if (this.error) return;

            return await this.$apollo
                .mutate({
                    mutation: this.isCompany ? SAVE_COMPANY : SAVE_PERSON2,
                    variables: {
                        id: this.isCompany
                            ? parseInt(this.$store.state.companies.currentCompany.id)
                            : parseInt(this.$store.state.persons.currentContractor.id),
                        certificateIds: this.getCertificateObjects(),
                        companyIds: [parseInt(this.$store.state.companies.currentCompany.id)],
                    },
                })
                .then((e) => {
                    if (this.certificateToDelete) {
                        setTimeout(() => {
                            //now also delete the asset to delete
                            this.isLoading = true;
                            this.$apollo
                                .mutate({
                                    mutation: DELETE_CERTIFICATE,
                                    variables: {
                                        id: parseInt(this.certificate.id),
                                        companyId: parseInt(this.$store.state.persons.currentContractor.id), //required for user validation at server
                                    },
                                })
                                .then((response) => {
                                    console.log("onDeleteClicked: response:", response);
                                    this.$emit("saveSucceeded");
                                    this.isLoading = false;
                                })
                                .catch((reject) => {
                                    console.log("onDeleteClicked: reject:", reject);
                                    this.error = JSON.stringify(reject);
                                    this.isLoading = false;
                                });
                        }, 5000);
                    } else {
                        this.$emit("saveSucceeded", e.data);
                        this.isLoading = false;
                    }
                })
                .catch((e) => {
                    let err;
                    if (e.networkError && e.networkError.result && e.networkError.result.error) {
                        err = e.networkError.result.error.split("\r\n").join("");
                    } else if (
                        e.networkError &&
                        e.networkError.result &&
                        e.networkError.result.errors &&
                        e.networkError.result.errors[0] &&
                        e.networkError.result.errors[0].message
                    ) {
                        err = e.networkError.result.errors[0].message;
                    } else {
                        err = e.message;
                    }
                    this.error = this.$trans.getTranslation(err, err);
                    this.isLoading = false;
                });
        },

        cancel() {
            this.$emit("cancel");
        },

        getCertificateObjects() {
            let newArray = this.certificates.map((asset) => {
                return parseInt(asset.id);
            });
            // console.log("Gert: newArray:", newArray, this.certificate.title);
            if (this.newAssetId) {
                newArray.push(parseInt(this.newAssetId));
                this.newAssetId = null;
            }
            if (this.certificateToDelete) {
                newArray = newArray.filter((id) => parseInt(id) !== parseInt(this.certificateToDelete));
            }

            return newArray;
        },

        async onDeleteClicked() {
            this.isLoading = true;
            this.file = null;

            //1. detatach asset
            this.certificateToDelete = this.certificate.id;
            await this.saveCertificate();
        },
    },
};
</script>
