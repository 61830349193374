<template>
    <div class="bg-gray-300">
        <h3 class="h4 text-center mb-3 uppercase text-primary-normal">QR Code scanners</h3>

        <div v-if="$store.getters.isInvalidBrowser" class="alert--warning p-2 rounded mb-6">
            Deze browser ondersteunt geen connectie met QR-code scanners. Gebruik een moderne browser zoals Chrome of
            Edge.
        </div>

        <div v-else>
            <div class="mb-4 flex items-center alert alert--warning">
                <InformationOutline class="text-2xl mr-3 mb-1" />
                <div>
                    <p class="font-bold mb-2 uppercase">Belangrijk</p>
                    <p>Deze site moet open blijven staan om de aangesloten QR-code scanner(s) te laten werken!</p>
                    <p>Naar andere pagina's navigeren is geen probleem.</p>
                </div>
            </div>

            <div class="flex pt-2 but-gap">
                <button
                    class="flex-grow px-3 py-2 focus:outline-none rounded-t bg-gray-400"
                    :class="{
                        'font-bold but-bg-tab': selectedTab === 1,
                    }"
                    @click="selectedTab = 1"
                >
                    Gescande codes
                </button>
                <button
                    class="flex-grow px-3 py-2 focus:outline-none rounded-t bg-gray-400"
                    :class="{
                        'font-bold but-bg-tab': selectedTab === 2,
                    }"
                    @click="selectedTab = 2"
                >
                    Settings
                </button>
            </div>

            <div class="but-bg-tab pt-4 p-4">
                <div v-if="selectedTab === 1">
                    <div class="flex mb-4 items-end">
                        <p class="flex-grow font-bold">Laatste 20 gescande codes via deze PC:</p>
                        <button class="btn" @click="$store.commit('setScannedCodes', [])">Wis lijst</button>
                    </div>

                    <table class="w-full text-sm">
                        <thead>
                            <th>Datum</th>
                            <th>Device</th>
                            <th>QR Code</th>
                            <th>Naam</th>
                            <th>Bedrijf</th>
                            <th>Info</th>
                        </thead>
                        <tr v-for="record in $store.state.qrCodeScanners.scannedCodes" :key="record.key">
                            <td>
                                {{ new Date(record.date).toLocaleString("nl-NL") }}
                            </td>
                            <!--                        <td :key="record.key + '-' + eachSecond">-->
                            <!--                            {{ moment(new Date(record.date), "X").fromNow() }}-->
                            <!--                        </td>-->
                            <td>{{ record.deviceName }}</td>
                            <td>{{ record.code }}</td>
                            <td>{{ record.name }}</td>
                            <td>{{ record.company }}</td>
                            <td
                                class="text-sm"
                                :class="{
                                    'text-orange-700 font-bold': record.isError,
                                }"
                            >
                                {{ record.info }}
                            </td>
                        </tr>
                        <tr v-if="$store.state.qrCodeScanners.scannedCodes.length === 0">
                            <td colspan="5">Nog geen gescande codes</td>
                        </tr>
                    </table>
                </div>

                <div v-if="selectedTab === 2">
                    <div class="mt-2">
                        <p class="font-bold mb-4">
                            Gebruikers die gebruik maken van de QR code scanner worden ingecheckt als:
                        </p>

                        <div
                            class="mb-2 ml-4"
                            v-for="visitType in $store.state.visit.allVisitorTypes"
                            :key="visitType.id"
                        >
                            <label class="radio">
                                <div>
                                    <input type="radio" :value="visitType.id" v-model="selectedVisitType" />
                                    <svg viewBox="0 0 172 172">
                                        <g
                                            fill="none"
                                            font-family="none"
                                            font-size="none"
                                            font-weight="none"
                                            stroke-miterlimit="10"
                                            stroke-width="none"
                                            style="mix-blend-mode: normal"
                                            text-anchor="none"
                                        >
                                            <path d="M0 172V0h172v172z" />
                                            <path
                                                d="M145.433 37.933L64.5 118.7558 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                                                fill="currentColor"
                                                stroke-width="1"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                <p>{{ visitType.title }}</p>
                            </label>
                        </div>
                    </div>
                    <div class="flex flex-1 justify-between mt-8">
                        <p class="font-bold">Aangesloten QR-code scanner(s):</p>
                        <button class="btn" @click="addNewPort()">Scanners zoeken</button>
                        <button class="btn" @click="document.location.reload()">Herladen</button>
                    </div>
                    <table>
                        <tr v-for="(device, index) in $store.state.qrCodeScanners.devices" :key="device.key">
                            <td>{{ device.name }}</td>
                            <td class="text-sm text-orange-700">{{ device.error }}</td>
                            <td>
                                <button class="ml-6 underline text-primary-normal" @click="forgetDevice(index)">
                                    Wissen
                                </button>
                            </td>
                        </tr>
                        <p v-if="$store.state.qrCodeScanners.devices.length === 0">
                            <i>Geen scanners gevonden:</i> <br />
                            Druk op "Scanners zoeken en connecteer 1 of meerdere scanners."
                        </p>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InformationOutline from "vue-material-design-icons/InformationOutline.vue";
import { ACTION_INIT_QR_CODE_SCANNERS, ACTION_SET_SCANNER_VISIT_TYPE } from "@/store/constants";
import moment from "moment";

export default {
    name: "QRCodeHardwareScanner",
    components: { InformationOutline },
    props: {},

    data() {
        return {
            document,
            moment,
            eachSecond: 0,
            iid: -1,
            selectedTab: 1,
        };
    },
    computed: {
        selectedVisitType: {
            get() {
                return (
                    this.$store.state.qrCodeScanners.scannerVisitType ||
                    this.$store.state.visit.allVisitorTypes.find((vt) => vt.slug === "contractor")
                );
            },
            set(v) {
                this.$store.commit(ACTION_SET_SCANNER_VISIT_TYPE, v);
            },
        },
    },

    mounted() {
        clearInterval(this.iid);
        this.iid = setInterval(() => {
            this.eachSecond = new Date().getTime();
        }, 1000);
    },

    methods: {
        addNewPort() {
            //ask permission to user to connect to a serial port
            navigator.serial
                .requestPort()
                .then((newPort) => {
                    console.log("newPort", newPort);
                    setTimeout(() => {
                        this.$store.dispatch(ACTION_INIT_QR_CODE_SCANNERS);
                    }, 2000);
                })
                .catch(() => {
                    console.log("init port cancelled");
                });
        },

        forgetDevice(index) {
            this.$store.state.qrCodeScanners.devices[index].port.forget();
            document.location.reload();
        },
    },

    beforeDestroy() {},
};
</script>

<style scoped>
li {
    @apply list-disc ml-6;
}

.but-bg-tab {
    @apply bg-white;
}

.but-gap {
    gap: 0.25rem;
}
</style>
