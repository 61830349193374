<template>
    <div>
        <div class="flex justify-between w-full">
            <div class="form-group w-1/4">
                <input
                    :placeholder="$trans.getTranslation('filter', 'Filter')"
                    autofocus
                    class="form-control"
                    type="text"
                    v-model="searchTerm"
                />
                <div class="filter-clear">
                    <button @click.prevent="searchTerm = ''">
                        <CloseIcon />
                    </button>
                </div>
            </div>
        </div>

        <hr />

        <div class="flex">
            <div :key="visitorType.slug" class="mr-4" v-for="visitorType in $store.state.visit.allVisitorTypes">
                <checkbox
                    class="mr-1 text-sm"
                    :label="$trans.getTranslation('visit-type-' + visitorType.slug, visitorType.title)"
                >
                    <input
                        :id="visitorType.id"
                        :value="visitorType.id"
                        class="mr-1"
                        type="checkbox"
                        v-model="visitorTypesToSelect"
                    />
                </checkbox>
            </div>

            <div class="ml-auto">
                <checkbox class="mr-1 text-sm" :label="$trans.getTranslation('auto-refresh', 'Automatisch verversen')">
                    <input class="mr-1" id="autoRefresh" type="checkbox" v-model="autoRefresh" />
                </checkbox>
            </div>
        </div>

        <div class="c-preloader mt-4 c-preloader--fixed-visits" v-show="isLoading"><i></i><i></i><i></i></div>

        <div v-if="isCompanyAdminLoggedIn" class="alert alert--warning my-2" v-show="errorMsg">{{ errorMsg }}</div>

        <hr />

        <!--	CARD
		-------------------------------------------------------------------------- -->

        <div class="flex-grid-thirds flex-wrap">
            <div
                :class="canEnterClass(visit)"
                :key="visit.id"
                @click.prevent="updateEmployee(visit.person)"
                class="user-card col cursor-pointer"
                v-for="visit in visits"
            >
                <div class="flex flex-col w-full pb-1">
                    <div class="flex justify-between">
                        <div>
                            <div class="font-bold" v-if="visit.person">
                                {{ visit.person.firstName }}
                                {{ visit.person.lastName }}
                            </div>
                            <div v-if="visit.person.company && visit.person.company.length > 0">
                                {{ visit.person.company[0].title }}
                            </div>
                            <div v-else>{{ visit.person.companyName }}</div>

                            <div v-if="visit.internalContactName" class="text-sm">
                                <span class="text-sm">t.a.v.</span>
                                {{ visit.internalContactName }}
                            </div>

                            <div
                                class="rounded-full bg-primary-dark w-6 h-6 p-0 text-center p-0 my-1 animation-pulse"
                                v-if="visit.person.safetyViolation"
                            >
                                <alert-icon class="text-orange-200" />
                            </div>

                            <licence-plate v-if="visit.person.licensePlate" :visit="visit" />
                        </div>
                        <div class="pl-2">
                            <div v-if="visit.person.photo">
                                <img :src="visit.person.photo.url" alt="" class="w-16 xl:w-20 rounded-sm" />
                            </div>
                        </div>
                    </div>

                    <div class="tags visiting-list my-2">
                        <div
                            v-if="
                                visit.visitType[0].slug === 'contractor' ||
                                visit.visitType[0].slug === 'visitor-factory'
                            "
                        >
                            <learning-track-pills :person="visit.person" :small-pills="panelWidth < 850" />
                        </div>
                    </div>
                    <div class="flex-grow"></div>
                    <div class="flex justify-between gap-1 items-end">
                        <div :class="'pill--' + visit.visitType[0].slug" class="pill text-center">
                            <span v-trans="visit.visitType[0].slug">{{ visit.visitType[0].title }}</span>
                        </div>
                        <div class="text-xs text-right">
                            {{ humanCreationDate(visit) }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="visits && visits.length === 0">
                <p class="text-sm">Er is niemand ingechecked die voldoet aan je filter.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { GET_ACTIVE_VISITS_FULL2 } from "../../graphql/queries/Visit";
import { Utils } from "../../utils/utils";
import {
    ACTION_SET_CURRENT_COMPANY,
    ACTION_SET_CURRENT_CONTRACTOR,
    ACTION_SET_PERSON_SEARCH_TERM,
    ACTION_VISIT_SET_AUTO_REFRESH,
    ACTION_VISIT_SET_VISITOR_TYPES_TO_SELECT,
} from "../../store/constants";
import CloseIcon from "vue-material-design-icons/Close";
import AlertIcon from "vue-material-design-icons/Alert.vue";
import moment from "moment";
import LearningTrackPills from "../../components/LearningTrackPills";
import Checkbox from "../../components/Checkbox";
import LicencePlate from "../../components/LicencePlate";

export default {
    name: "CompanyAdminVisitingPersons",
    components: { LicencePlate, LearningTrackPills, CloseIcon, Checkbox, AlertIcon },
    data() {
        return {
            isLoading: false,
            Utils,
            errorMsg: "",
            forceMomentUpdate: "",
            forceUpdateIndex: null,
            forceRefresh: 0,
            panelWidth: window.innerWidth,
        };
    },
    apollo: {
        visits() {
            return {
                query: GET_ACTIVE_VISITS_FULL2,
                /*pollInterval() {
                        return this.autoRefresh ? 5000 : null;
                    },*/
                fetchPolicy: "cache-and-network",
                skip: !this.$store.state.persons.currentCompanyAdmin.id,
                variables() {
                    return {
                        visitTypes: this.visitorTypesToSelect,
                        search: this.searchTerm,
                        forceRefresh: this.forceRefresh,
                    };
                },
                update(data) {
                    //console.log(data);
                    if (data.entries) {
                        const newData = [];
                        data.entries.forEach((entry) => {
                            // we want these fields not deeply nested for easy sorting!
                            if (this.normalizeField(entry)) {
                                newData.push(entry);
                            }
                        });
                        // console.log("Gert: newData:", newData);
                        this.errorMsg = "";
                        return newData;
                    } else {
                        return [];
                    }
                },
                watchLoading(isLoading) {
                    this.isLoading = isLoading;
                },
                error(e) {
                    console.log("e:", e);
                    if (e.networkError && parseInt(e.networkError.response.status) === 401) {
                        this.errorMsg = this.$trans.getTranslation("not-authorized-relogin");
                        // this.$store.dispatch(ACTION_LOG_OUT, this.$axios);
                    } else {
                        this.errorMsg = JSON.stringify(e);
                    }
                },
            };
        },
    },

    created() {
        clearInterval(this.forceUpdateIndex);
        this.forceUpdateIndex = setInterval(() => {
            if (this.forceMomentUpdate === " ") {
                this.forceMomentUpdate = "";
            } else {
                this.forceMomentUpdate = " ";
            }

            if (this.autoRefresh && !this.isLoading) {
                this.forceRefresh = Date.now();
            }
            this.panelWidth = this.$el.clientWidth;
        }, 5000);
    },
    mounted() {
        this.panelWidth = this.$el.clientWidth;
        if (this.$store.state.visit.visitorTypesToSelect.length === 0) {
            this.$store.dispatch(ACTION_VISIT_SET_VISITOR_TYPES_TO_SELECT, [
                this.$store.state.visit?.visitorTypes?.map((v) => v.id),
            ]);
        }

        console.log(
            "LoginCraftUser: mounted:",
            this.$store.state?.persons.currentCompanyAdmin.id,
            this.$store.state?.persons.currentContractor.id,
            this.$store.state?.persons.currentContractorAdmin.id,
            "---",
            this.$store.state?.currentCompanyAdmin?.id,
            this.$store.state?.currentContractor?.id,
            this.$store.state?.currentContractorAdmin?.id
        );
    },

    beforeDestroy() {
        clearInterval(this.forceUpdateIndex);
    },

    computed: {
        isCompanyAdminLoggedIn() {
            return this.$store.state.persons.currentCompanyAdmin.id;
        },
        searchTerm: {
            set(value) {
                this.$store.dispatch(ACTION_SET_PERSON_SEARCH_TERM, value);
            },
            get() {
                return this.$store.state.persons.personSearchTerm;
            },
        },
        visitorTypesToSelect: {
            set(value) {
                this.$store.dispatch(ACTION_VISIT_SET_VISITOR_TYPES_TO_SELECT, value);
            },
            get() {
                return this.$store.state.visit.visitorTypesToSelect;
            },
        },

        autoRefresh: {
            set(value) {
                if (value) {
                    this.forceRefresh = Date.now();
                }
                this.$store.dispatch(ACTION_VISIT_SET_AUTO_REFRESH, value);
            },
            get() {
                return this.$store.state.visit.autoRefresh;
            },
        },
    },

    methods: {
        canEnterClass(visit) {
            if (visit.visitType[0].slug === "visitor-office") {
                return "visitor-office";
            }

            if (visit.visitType[0].slug === "truck-driver") {
                return "trucker";
            }

            if (visit.visitType[0].slug === "contractor" || visit.visitType[0].slug === "visitor-factory") {
                return Utils.ContractorCanEnter(visit.person) ? "contractor-allowed" : "contractor-not-allowed";
            }

            return "";
        },

        humanCreationDate(visit) {
            // console.log("visit.dateCreated", visit.dateCreated, "this.forceMomentUpdate", this.forceMomentUpdate, "v:", visit);
            return moment(this.forceMomentUpdate + visit.dateCreated).fromNow();
        },

        normalizeField(entry) {
            //console.log("entry", entry);
            if (entry.person && Array.isArray(entry.person)) {
                //do we have a visit with a disabled person? [yes]
                if (entry.person.length === 0) {
                    return null;
                }

                entry.person = { ...entry.person[0] };

                //if (entry.person.company && entry.person.company.length > 0) {
                //    entry.person.company = entry.person.company[0];
                //}

                if (!entry.person.learningTrackResults) {
                    entry.person.learningTrackResults = [];
                }

                if (entry.person.photo && entry.person.photo.length > 0) {
                    entry.person.photo = entry.person.photo[0];
                } else {
                    entry.person.photo = { thumb: "/img/css/user.png" };
                }
            }
            return entry;
        },

        updateEmployee(person) {
            this.$store.dispatch(ACTION_SET_CURRENT_COMPANY, person.company[0]);
            this.$store.dispatch(ACTION_SET_CURRENT_CONTRACTOR, person);
            this.$router.push({ name: "onlineContractorProfile" });
        },

        createVisitorLists() {},
    },
};
</script>

<style scoped>
.contractor-allowed {
    background-color: #dbf9e3;
    /*border-color: #418c00;*/
    /*border-width: 2px;*/
}

.contractor-not-allowed {
    background-color: #fef4dd;
    /*border-color: #9f002a;*/
    /*border-width: 2px;*/
}

.animation-pulse {
    /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
        transform: scale(0.8);*/
    animation: pulse 3s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgb(42, 173, 199);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 15px rgba(42, 173, 199, 0);
    }

    100% {
        transform: scale(0.9);
    }
}
</style>
