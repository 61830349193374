<template>
    <div class="license-plate">
        <div class="country-sign">XX</div>
        <div class="number">{{ visit.person.licensePlate }}</div>
    </div>
</template>
<script>
export default {
    name: "licence-plate",
    props: {
        visit: {},
    },
};
</script>
