<template>
    <div>
        <h1 class="h3"><span v-trans="'hallo'">Hallo</span> {{ person.firstName }} {{ person.lastName }},</h1>
        <div class="mb-4" v-trans="'start--intro'">
            <p>Welkom op het digitaal portaal van Aluminimum Duffel.</p>
            <p>
                Dit platform dient om uw toegang tot het bedrijf vlotter te laten verlopen zodat u minder tijd verspeelt
                aan de receptie.
            </p>
        </div>
        <h2 class="h4 text-primary-normal mb-2">
            <span
                class="bg-white shadow rounded border-2 border-primary-normal text-white rounded-full px-3 text-primary-normal mr-2"
                >1</span
            >
            <span v-trans="'start--subtitle1'">Controleer uw persoonlijke gegevens</span>
        </h2>
        <div class="mb-6" v-trans="'start--body1'">
            <p>
                De gegevens die in ons systeem zijn opgeslagen zijn aangeleverd door uw werkgever of tussenpersoon.
                <br />
                Controleer deze zeker en breng waar nodig aanpassingen aan.
            </p>
        </div>

        <h2 class="h4 text-primary-normal mb-2">
            <span
                class="bg-white shadow rounded border-2 border-primary-normal text-white rounded-full px-3 text-primary-normal mr-2"
                >2</span
            >
            <span v-trans="'start--subtitle2'">Bezorg ons uw certificaten</span>
        </h2>
        <div class="mb-6" v-trans="'start--body2'">
            <p>Voor sommige werken is het vereist dat u bepaalde certificaten kan voorleggen.</p>
            <p>
                Klik door op <strong><span class="text-primary-normal text-bold">certificaten</span></strong> om de
                nodige documenten aan te leveren.
            </p>
        </div>

        <h2 class="h4 text-primary-normal mb-2">
            <span
                class="bg-white shadow rounded border-2 border-primary-normal text-white rounded-full px-3 text-primary-normal mr-2"
                >3</span
            >
            <span v-trans="'start--subtitle3'">Voltooi de veiligheidstest(en)</span>
        </h2>
        <div class="mb-6" v-trans="'start--body3'">
            <p>
                Om toegang te krijgen tot de site van Aluminimum Duffel moet u voor de nodige veiligheidstesten geslaagd
                zijn.
            </p>
            <p>Om geen tijd te verspelen aan de receptie kan u deze test op voorhand reeds uitvoeren.</p>
            <p>
                Klik door op <strong><span class="text-primary-normal text-bold">Test</span></strong> om de nodige
                testen uit te voeren.
            </p>
        </div>
        <div class="shadow rounded bg-white p-5 font-bold max-w-xl">
            <p v-trans="'start--support'">
                Indien u nog bijkomstige vragen heeft of technische ondersteuning vereist, kan u ons contacteren op
            </p>
            <p class="h2 text-primary-normal mb-0" v-trans="'start--support2'">015/12.23.34</p>
        </div>
    </div>
</template>

<script>
import { ACTION_SET_CURRENT_CONTRACTOR } from "../../store/constants";

export default {
    name: "ContractorStart",
    data() {
        return {
            person: {},
            storeSubscription: null,
        };
    },

    mounted() {
        this.person = { ...this.$store.state.persons.currentContractor };
        this.storeSubscription = this.$store.subscribe((mutation) => {
            if (mutation.type === ACTION_SET_CURRENT_CONTRACTOR && mutation.payload !== null) {
                this.person = { ...this.$store.state.persons.currentContractor };
            }
        });
    },

    beforeDestroy() {
        // remove the subscription
        this.storeSubscription();
    },

    methods: {},
};
</script>
