//
// export const VISIT_TYPE = {
//     // --> deprecated: use gql GET_ALL_VISITOR_TYPES
//     CONTRACTOR: "116",
//     VISITOR_OFFICE: "114",
//     VISITOR_FACTORY: "307297",
//     CHAUFFEUR: "184",
// };

export const COMPANY_TYPE = {
    CONTRACTOR: "119",
    TRANSPORT: "120",
};

export const CRAFT_USER_TYPE = {
    PORTAL_ADMINISTRATOR_SLUG: "portaladministrator",
    COMPANY_ADMINISTRATOR_SLUG: "companyadministrator",
    CONTRACTOR_ADMINISTRATOR_SLUG: "contractoradministrator",
    VIEWER_SLUG: "viewer",
};

export const REGISTRATION_COMPLETE_MESSAGE = {
    DONT_FORGET_TO_UNREGISTER: "1",
    AUTO_UNREGISTER: "2",
};

export const LEARNING_TRACK_STATUS = {
    NOT_ATTEMPTED: 194,
    PASSED: 196,
};

export const CONTRACTORS_LEARNINGTRACK_ID = 28;
export const VISITORS_FACTORY_LEARNINGTRACK_ID = 25;

export const ALERIS_COMPANY_ID = 19056;

export const ACTION_LOG_OUT = "ACTION_LOG_OUT";
export const ACTION_CHANGE_LANGUAGE = "change language";
export const ACTION_SET_CRAFTQL_ERROR = "ACTION_SET_CRAFTQL_ERROR";
export const ACTION_SET_PERSON_SEARCH_TERM = "ACTION_SET_PERSON_SEARCH_TERM";

export const ACTION_GET_PERSON_BY_GUID = "ACTION_GET_PERSON_BY_GUID";
export const ACTION_SET_CURRENT_COMPANY_ADMIN = "ACTION_SET_CURRENT_COMPANY_ADMIN";
export const ACTION_SET_CURRENT_CONTRACTOR_ADMIN = "ACTION_SET_CURRENT_CONTRACTOR_ADMIN";
export const ACTION_SET_CURRENT_CONTRACTOR = "ACTION_SET_CURRENT_CONTRACTOR";
export const ACTION_SET_CURRENT_COMPANY = "ACTION_SET_CURRENT_COMPANY";

export const ACTION_GET_INTERNAL_CONTACTS = "ACTION_GET_INTERNAL_CONTACTS";
export const SET_INTERNAL_CONTACTS = "ACTION_SET_INTERNAL_CONTACTS";
export const STORE_VISIT_SESSION = "STORE_VISIT_SESSION";

export const ACTION_VISIT_SET_AUTO_REFRESH = "ACTION_VISIT_SET_AUTO_REFRESH";
export const ACTION_VISIT_SET_VISITOR_TYPES_TO_SELECT = "ACTION_VISIT_SET_VISITOR_TYPES_TO_SELECT";
export const ACTION_SET_ANONYMOUS_LOGIN_INFO = "ACTION_SET_ANONYMOUS_LOGIN_INFO";
export const ACTION_SET_ONSITE_SELECTION = "ACTION_SET_ONSITE_SELECTION";

export const ACTION_SET_COMPANY_TYPES_TO_SELECT = "ACTION_SET_COMPANY_TYPES_TO_SELECT";

export const ACTION_SET_USER_SEARCH_TERM = "ACTION_SET_USER_SEARCH_TERM";
export const ACTION_SET_COMPANY_SEARCH_TERM = "ACTION_SET_COMPANY_SEARCH_TERM";
export const ACTION_INIT_QR_CODE_SCANNERS = "ACTION_INIT_QR_CODE_SCANNERS";

export const ACTION_SET_SCANNER_VISIT_TYPE = "ACTION_SET_SCANNER_VISIT_TYPE";

export const ACTION_VISIT_SET_ALL_VISITOR_TYPES = "ACTION_VISIT_SET_ALL_VISITOR_TYPES";
export const ACTION_GET_LEARNING_TRACKS = "ACTION_GET_LEARNING_TRACKS";
