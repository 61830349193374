<template>
    <div v-if="learningTrack">
        <div class="flex gap-2">
            <IconContractor v-if="learningTrack.learningTrack[0].slug === 'contractors'" class="mb-2" />
            <IconVisitor v-if="learningTrack.learningTrack[0].slug === 'visitors-factory'" class="mb-2" />
            <div class="text-md mb-1" v-trans="'courseTitle-' + learningTrack.learningTrack[0].slug">
                {{ learningTrack.learningTrack[0].title }}
            </div>
        </div>

        <p class="text-sm mb-2 whitespace-normal" v-trans="'courseDescription-' + learningTrack.learningTrack[0].slug">
            {{ learningTrack.learningTrack[0].description }}
        </p>

        <div v-if="!canTakeOrContinueCourse(learningTrack)">
            <span class="text-md" v-trans="'score'">Score</span>:
            {{ learningTrack.scoResults.length > 0 ? learningTrack.scoResults[0].score + "%" : "N/A" }}
        </div>

        <div v-if="learningTrack.status !== 'expired'" :class="getClass(learningTrack)" class="px-2 py-1 rounded">
            <span class="status" v-trans="learningTrack.learningTrackResultStatus[0].slug">{{
                learningTrack.learningTrackResultStatus[0].slug
            }}</span>
            &nbsp;
            <span v-html="getDate(learningTrack)"></span>
        </div>
        <div v-if="learningTrack.status === 'expired'" :class="getClass(learningTrack)" class="px-2 py-1 rounded">
            <span class="status" v-trans="'expired'">Vervallen op</span>
            &nbsp;
            <span v-html="Utils.toLocaleDateString(learningTrack.expiryDate)"></span>
        </div>
    </div>
</template>

<script>
import AccountHardHatIcon from "vue-material-design-icons/AccountHardHat";
import BriefcaseAccount from "vue-material-design-icons/BriefcaseAccount";
import { Utils } from "@/utils/utils";
export default {
    name: "LearningTrackInfoPanel",
    components: {
        IconContractor: AccountHardHatIcon,
        IconVisitor: BriefcaseAccount,
    },
    props: {
        learningTrack: {},
    },
    data() {
        return {
            Utils,
        };
    },
    methods: {
        canTakeOrContinueCourse(learningTrack) {
            // console.log("canTakeOrContinueCourse", learningTrack);
            return (
                learningTrack.status !== "expired" &&
                learningTrack.learningTrackResultStatus[0].slug !== "passed" &&
                learningTrack.learningTrackResultStatus[0].slug !== "completed" &&
                learningTrack.learningTrackResultStatus[0].slug !== "failed"
            );
        },
        getDate(learningTrackResult) {
            // console.log("Gert: learningTrackResult:", learningTrackResult);
            if (learningTrackResult.expiryDate > 0) {
                return Utils.toLocaleDateString(learningTrackResult.expiryDate);
            } else {
                if (learningTrackResult.dateUpdated) {
                    return Utils.toLocaleDateString(learningTrackResult.dateUpdated);
                } else {
                    return "";
                }
            }
        },

        getClass(learningTrack) {
            if (learningTrack.status === "expired") {
                return "lt-status status-expired";
            }

            if (learningTrack.learningTrackResultStatus && learningTrack.learningTrackResultStatus.length > 0) {
                return "lt-status status-" + learningTrack.learningTrackResultStatus[0].slug.split(" ").join("-");
            } else {
                return "";
            }
        },
    },
};
</script>
