<template>
    <a @click.prevent="openPopup()" class="relative" href="#" v-if="!$store.getters.isViewer"
        ><span class="btn btn--primary mr-3">Uitnodigen</span>

        <div
            v-if="isPopupOpen"
            class="absolute z-50 p-4 bg-white shadow-xl border border-2 rounded"
            :style="{
                left: popupLeft ? '0' : 'auto',
                right: popupLeft ? 'auto' : '0',
                bottom: '2.2rem',
            }"
            id="invite-persons-popup"
        >
            <!--  only show btns if there is a currentCompanyAdmin-->
            <div class="flex flex-col gap-2 mb-3" v-if="$store.state.persons.currentCompanyAdmin.id">
                <button
                    v-for="lt in $store.state.persons.learningTracks"
                    :key="lt.slug"
                    @click.prevent="invite(lt)"
                    class="block rounded p-2 bg-gray-200 hover:bg-gray-300 text-left pl-4"
                    style="width: 270px"
                    href="#"
                >
                    <Component :is="`Icon_${lt.slug.replaceAll('-', '_')}`" class="mr-2" />
                    {{ lt.title }}
                </button>
            </div>
            <div class="c-preloader mt-4 c-preloader--fixed" v-show="isLoading"><i></i><i></i><i></i></div>
            <div class="alert alert--warning" v-show="errorMsg">{{ errorMsg }}</div>
        </div>
    </a>
</template>
<script>
import { CREATE_LEARNING_TRACK_RESULT } from "@/graphql/queries/Person";
import { LEARNING_TRACK_STATUS } from "@/store/constants";
import { Utils } from "@/utils/utils";
import AccountHardHatIcon from "vue-material-design-icons/AccountHardHat";
import BriefcaseAccount from "vue-material-design-icons/BriefcaseAccount";

export default {
    name: "InvitePersons",
    components: {
        Icon_contractors: AccountHardHatIcon,
        Icon_visitors_factory: BriefcaseAccount,
    },
    data() {
        return {
            isPopupOpen: false,
            isLoading: false,
            errorMsg: "",
        };
    },
    props: {
        persons: {
            type: Array,
            required: true,
        },
        popupLeft: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        handleClickOutside(event) {
            if (this.isPopupOpen && !this.$el.contains(event.target)) {
                this.closePopup();
            }
        },
        closePopup() {
            this.isPopupOpen = false;
        },
        openPopup() {
            //check if there is a currentCompanyAdmin, if not, we don't need to show the popup, as we can directly invite contractors
            if (!this.$store.state.persons.currentCompanyAdmin.id) {
                this.invite(this.$store.state.persons.learningTracks.find((lt) => lt.slug === "contractors"));
            }
            this.isPopupOpen = true;
        },

        async invite(learningTrack) {
            console.log("invite ", learningTrack.slug);

            this.isLoading = true;
            this.errorMsg = this.$trans.getTranslation("Uitnodigen gestart", "Uitnodigen gestart");
            const promiseWait = new Promise((resolve) => {
                setTimeout(resolve, 1200, "dummmy");
            });
            let checkedResultPromises = []; //add at least one to make sure we trigger the Promise.all()!

            //1. remove earlier invites ( = learning tracks with slug "not-"attempted")
            this.persons.forEach((person) => {
                // console.log("learningTrackResults", person.learningTrackResults);
                //if already a LT not-attempted: remove it and create a new, to trigger re-inviting
                person.learningTrackResults.forEach((result) => {
                    if (
                        result.learningTrack[0].slug === learningTrack.slug &&
                        result.status === "live" &&
                        result.learningTrackResultStatus[0].slug === "not-attempted"
                    ) {
                        const restCall = this.$axios({
                            url: `rest/v1/entry/remove`,
                            method: "post",
                            responseType: "json",
                            data: {
                                entryId: result.id,
                                force: 1, //to delete even if linked
                            },
                        });
                        checkedResultPromises.push(restCall);
                        console.log("remove earlier invite:", result);
                    }
                });
                checkedResultPromises.push({ invitedPerson: person });
            });

            //2. start adding new learning tracks again
            Promise.all(checkedResultPromises)
                .then((promiseValues) => {
                    let createResultPromises = [promiseWait]; //add at least one to make sure we trigger the Promise.all()!

                    promiseValues.forEach((result) => {
                        if (result.invitedPerson) {
                            console.log("Add LT for", result.invitedPerson.firstName);
                            createResultPromises.push(
                                this.$apollo.mutate({
                                    mutation: CREATE_LEARNING_TRACK_RESULT, //this also triggers the Craft plugin to attach this LT to the Person
                                    variables: {
                                        id: parseInt(result.invitedPerson.id),
                                        learningTrack: parseInt(learningTrack.id), //CONTRACTORS_LEARNINGTRACK_ID, // hardcoded "Contractors learningTrack" in the db
                                        statusId: LEARNING_TRACK_STATUS.NOT_ATTEMPTED,
                                    },
                                    fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                                    // pollInterval: 1000,
                                })
                            ); /*.then((val) => {
								console.log("INNER PROM", val.data.upsertLearningTrackResult);
								this.errorMsg = `${person.firstName} ${person.lastName} ` + this.$trans.getTranslation("is uitgenodigd", "is uitgenodigd");
								person.learningTrackResultStatus = "not-attempted";
								person.checked = false;
							}));*/
                        }
                    });

                    //3. check processed persons
                    Promise.all(createResultPromises)
                        .then((promiseValues) => {
                            //loop all resolved promises to update status
                            promiseValues.forEach((promise) => {
                                console.log("added new LT:", promise);
                            });
                            this.errorMsg = "";
                            this.closePopup();
                        })
                        .catch((e) => {
                            let err;
                            if (e.networkError) {
                                if (e.networkError.result && e.networkError.result.error) {
                                    err = e.networkError.result.error.split("\r\n").join("");
                                } else if (
                                    e.networkError &&
                                    e.networkError.result &&
                                    e.networkError.result.errors &&
                                    e.networkError.result.errors[0] &&
                                    e.networkError.result.errors[0].message
                                ) {
                                    err = e.networkError.result.errors[0].message;
                                } else if (e.networkError.bodyText) {
                                    err = Utils.stripHtml(e.networkError.bodyText.split("<body>")[1]);
                                } else {
                                    err = JSON.stringify(e);
                                }
                            } else {
                                err = e.message;
                            }
                            const error = this.$trans.getTranslation(err, err);
                            console.log("e:", e);
                            this.errorMsg = error;
                        })
                        .finally(() => {
                            this.$emit("refetchPersons");
                        });
                })
                .catch((e) => {
                    this.errorMsg = JSON.stringify(e);
                });
        },
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },
};
</script>
