<template>
    <div class="container-50">
        <div class="form-group mb-2">
            <checkbox :label="$trans.getTranslation('visitor-office', 'visitor office')">
                <input type="checkbox" v-model="preferences[0]" value="1" />
            </checkbox>
        </div>
        <div class="form-group mb-2">
            <checkbox :label="$trans.getTranslation('contractor', 'contractor')">
                <input type="checkbox" v-model="preferences[1]" value="1" />
            </checkbox>
        </div>
        <div class="form-group mb-4">
            <checkbox :label="$trans.getTranslation('truck-driver', 'Chauffeur')">
                <input type="checkbox" v-model="preferences[2]" value="1" />
            </checkbox>
        </div>
        <button @click="savePreferences()" class="mobile-button block w-full btn btn--secondary">
            <span v-trans="'save'">Opslaan</span>
        </button>

        <div class="form-group mt-4">
            <button @click="clearSession()" class="mobile-button block w-full btn btn--secondary">
                <span v-trans="'clear-session'">Clear session</span>
            </button>
        </div>
    </div>
</template>
<script>
import Checkbox from "../../components/Checkbox";
import { ACTION_SET_ANONYMOUS_LOGIN_INFO, ACTION_SET_ONSITE_SELECTION } from "../../store/constants";

export default {
    name: "Settings",
    components: {
        Checkbox,
    },
    mounted() {
        if (localStorage.onsiteselectionV3) this.preferences = JSON.parse(localStorage.onsiteselectionV3);
    },
    data() {
        return {
            preferences: [0, 0, 0],
        };
    },
    methods: {
        savePreferences() {
            this.$store.commit(ACTION_SET_ONSITE_SELECTION, { ...this.preferences });
            localStorage.onsiteselectionV3 = JSON.stringify(this.$store.state.visit.onsiteSelection);
        },
        clearSession() {
            this.$store.commit(ACTION_SET_ANONYMOUS_LOGIN_INFO, null);
            delete localStorage.onsitelogin;

            this.$router.replace({
                name: "onsite-home",
            });
        },
    },
};
</script>
