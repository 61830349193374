<template>
    <div>
        <transition mode="out-in" name="fade-in" appear>
            <router-view />
        </transition>
    </div>
</template>

<script>
export default {
    name: "ContractorLearningPath",
};
</script>
