import {
    ACTION_GET_PERSON_BY_GUID,
    ACTION_LOG_OUT,
    //ACTION_SET_CRAFTQL_ERROR,
    ACTION_SET_CURRENT_COMPANY_ADMIN,
    ACTION_SET_CURRENT_CONTRACTOR,
    ACTION_SET_CURRENT_CONTRACTOR_ADMIN,
    ACTION_SET_PERSON_SEARCH_TERM,
    CRAFT_USER_TYPE,
    ACTION_GET_LEARNING_TRACKS,
    ACTION_SET_CRAFTQL_ERROR,
} from "../constants";
import { GET_PERSON_BY_GUID2 } from "../../graphql/queries/Person";
import { apolloProvider } from "../../graphql/apollo";
import { Utils } from "../../utils/utils";
import Vue from "vue";

export default {
    state: {
        isLoggedIn: false,
        currentCompanyAdmin: {
            id: null,
            firstName: "",
            lastName: "",
            email: "",
            company: [{ id: null, title: "" }],
            userType: "",
        },
        currentContractor: {
            id: null,
            firstName: "",
            lastName: "",
            email: "",
            company: [{ id: null, title: "" }],
            userType: "",
        },
        currentContractorAdmin: {
            id: null,
            firstName: "",
            lastName: "",
            email: "",
            company: [{ id: null, title: "" }],
            userType: "",
        },
        personSearchTerm: "",

        //all learning track records
        learningTracks: [],
    },

    getters: {
        currentUserId(state) {
            if (state.currentCompanyAdmin.id !== null) return parseInt(state.currentCompanyAdmin.id);
            else if (state.currentContractorAdmin.id !== null) return parseInt(state.currentContractorAdmin.id);
            else return 0;
        },
        currentUserFirstName(state) {
            /*if (state.currentCompanyAdmin.id !== null)
				return state.currentCompanyAdmin.firstName;
			else*/ if (state.currentContractorAdmin.id !== null) return state.currentContractorAdmin.firstName;
            else return 0;
        },
        isContractorAdmin(state) {
            return state.currentContractorAdmin.userType === CRAFT_USER_TYPE.CONTRACTOR_ADMINISTRATOR_SLUG;
        },
        isPortalAdmin(state) {
            return state.currentCompanyAdmin.userType === CRAFT_USER_TYPE.PORTAL_ADMINISTRATOR_SLUG;
        },
        isCompanyAdmin(state) {
            return state.currentCompanyAdmin.userType === CRAFT_USER_TYPE.COMPANY_ADMINISTRATOR_SLUG;
        },
        isViewer(state) {
            return state.currentCompanyAdmin.userType === CRAFT_USER_TYPE.VIEWER_SLUG;
        },
        isContractor(state) {
            return !state.currentCompanyAdmin.id && !state.currentContractorAdmin.id;
        },
    },

    actions: {
        [ACTION_LOG_OUT]({ commit, dispatch }) {
            Vue.prototype.$axios
                .get("actions/users/logout")
                .then((response) => {
                    if (response.data.errorCode) {
                        console.error("Logout error:", response.data);
                    } else {
                        commit("setIsLoggedIn", false);
                        // force a not authorized error, so the store will handle redirections
                        dispatch(ACTION_SET_CRAFTQL_ERROR, "Not authorized");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    //this.errorMsg = JSON.stringify(error);
                })
                .finally(() => {
                    //this.isLoading = false;
                });
        },

        async [ACTION_GET_PERSON_BY_GUID]({ dispatch }, guid) {
            const response = await apolloProvider.defaultClient.query({
                query: GET_PERSON_BY_GUID2,
                variables: { guid: guid },
                fetchPolicy: "network-only",
            });
            console.log("response:", response);
            if (response.data && response.data.entries.length > 0) {
                dispatch(ACTION_SET_CURRENT_CONTRACTOR, response.data.entries[0]);
            } else {
                throw new Error("No user found with this code");
            }
        },

        [ACTION_SET_CURRENT_CONTRACTOR_ADMIN]({ commit }, payload) {
            commit(ACTION_SET_PERSON_SEARCH_TERM, "");
            commit(ACTION_SET_CURRENT_CONTRACTOR_ADMIN, payload);
        },

        [ACTION_SET_CURRENT_CONTRACTOR]({ commit }, payload) {
            commit(ACTION_SET_CURRENT_CONTRACTOR, payload);
        },

        [ACTION_SET_CURRENT_COMPANY_ADMIN]({ commit }, payload) {
            commit(ACTION_SET_PERSON_SEARCH_TERM, "");
            commit(ACTION_SET_CURRENT_COMPANY_ADMIN, payload);
        },

        [ACTION_SET_PERSON_SEARCH_TERM]({ commit }, searchTerm) {
            console.log("search item");
            commit(ACTION_SET_PERSON_SEARCH_TERM, searchTerm);
        },
        /*
				[ACTION_SAVE_PERSON]({commit}, person) {
					apolloProvider.defaultClient.query({
						query: SAVE_PERSON,
						variables: {
							id: this.person.id,
							firstName: this.personToEdit.firstName,
							lastName: this.personToEdit.lastName,
							email: this.personToEdit.email,
							languageIds: [{id: this.personToEdit.languageCategorie[0].id}],
							companyIds: [this.personToEdit.company[0].id]
						},
					});
				}
		*/
    },

    mutations: {
        setIsLoggedIn(state, payload) {
            state.isLoggedIn = payload;
        },

        [ACTION_GET_PERSON_BY_GUID](state, payload) {
            payload = Utils.initUser(payload);
            state.currentContractor = { ...payload };
        },

        [ACTION_SET_CURRENT_CONTRACTOR_ADMIN](state, payload) {
            payload = Utils.initUser(payload);
            state.currentContractorAdmin = { ...payload };
        },

        [ACTION_SET_CURRENT_CONTRACTOR](state, payload) {
            payload = Utils.initUser(payload);
            state.currentContractor = { ...payload };
        },

        [ACTION_SET_CURRENT_COMPANY_ADMIN](state, payload) {
            console.log("ACTION_SET_CURRENT_COMPANY_ADMIN 1", payload);
            payload = Utils.initUser(payload);
            console.log("ACTION_SET_CURRENT_COMPANY_ADMIN 2", payload);
            state.currentCompanyAdmin = { ...payload };
        },

        [ACTION_SET_PERSON_SEARCH_TERM](state, searchTerm) {
            state.personSearchTerm = searchTerm;
        },
        [ACTION_GET_LEARNING_TRACKS](state, payload) {
            state.learningTracks = payload;
        },
    },
};
