import gql from "graphql-tag";

//done
export const UPLOAD_CERTIFICATE = gql`
    mutation contractor_UploadCertificate($file: FileInput, $title: String) {
        save_certificates_Asset(_file: $file, title: $title) {
            id
            title
        }
    }
`;

//done
export const DELETE_CERTIFICATE = gql`
    mutation contractor_DeleteCertificate($id: Int!) {
        deleteAsset(id: $id)
    }
`;
