<template>
    <div>
        <div class="flex justify-between">
            <h3 class="page-title">
                <template v-if="$store.state.persons.currentCompanyAdmin.id">
                    <router-link :to="{ name: 'onlineAdmin' }" v-trans="'admin'"> Admin </router-link>
                    <span class="mx-2 text-gray-500">•</span>
                </template>

                <router-link
                    :to="{ name: getBackButtonComponentName }"
                    v-if="$store.state.companies.currentCompany.title"
                >
                    {{ $store.state.companies.currentCompany.title }}
                    <span class="mx-2 text-gray-500">•</span>
                </router-link>

                <span
                    v-if="
                        !$store.state.companies.currentCompany.title &&
                        $store.state.persons.currentContractor &&
                        $store.state.persons.currentContractor.company[0].title
                    "
                >
                    {{ $store.state.persons.currentContractor.company[0].title }}
                    <span class="mx-2 text-gray-500">•</span>
                </span>

                {{ $store.state.persons.currentContractor.firstName }}
                {{ $store.state.persons.currentContractor.lastName }}
            </h3>

            <!--<h3 class="page-title" v-if="$store.state.persons.currentContractor">-->
            <!--<router-link :to="{name:'onlineHome'}"><ArrowLeftIcon/></router-link>-->
            <!--Contractor Portal-->
            <!--</h3>-->
            <button @click="isMenuOpen = !isMenuOpen" class="page-menu-icon md:hidden">
                <MenuIcon v-if="!isMenuOpen" />
                <CloseIcon v-if="isMenuOpen" />
            </button>
        </div>

        <div class="flex">
            <ul :class="{ 'is-open': isMenuOpen }" class="page-menu absolute md:static w-full md:w-3/12 xl:w-2/12">
                <li>
                    <router-link :to="{ name: 'onlineContractorStart' }" class="sidemenu-item" v-trans="'start'">
                        Start
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{ name: 'onlineContractorProfile' }"
                        class="sidemenu-item"
                        v-trans="'person-info'"
                    >
                        Persoonsinformatie
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{ name: 'onlineContractorCertificates' }"
                        class="sidemenu-item"
                        v-trans="'certificates'"
                    >
                        Certificaten
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'onlineContractorLearningPath' }" class="sidemenu-item" v-trans="'tests'">
                        Testen
                    </router-link>
                </li>
                <li v-if="$store.state.persons.currentCompanyAdmin.id">
                    <router-link
                        :to="{ name: 'onlineContractorEntryPermit' }"
                        class="sidemenu-item"
                        v-trans="'entry-permit'"
                    >
                        Inrijvergunning
                    </router-link>
                </li>
            </ul>
            <transition mode="out-in" name="fade-in" appear>
                <router-view class="md:ml-6 bg-gray-100 p-6 w-full md:w-9/12 xl:w-10/12 rounded" />
            </transition>
        </div>

        <EnterCode class="z-50" v-if="!contractorLoggedIn" />
    </div>
</template>

<script>
import MenuIcon from "vue-material-design-icons/Menu.vue";
import EnterCode from "../../components/EnterCode";
import CloseIcon from "vue-material-design-icons/Close.vue";

export default {
    name: "ContractorPortal",
    components: {
        MenuIcon,
        EnterCode,
        CloseIcon,
    },
    data() {
        return {
            isMenuOpen: false,
        };
    },
    watch: {
        $route() {
            this.isMenuOpen = false;
        },
    },
    computed: {
        contractorLoggedIn() {
            // here we want to check if the person is "logged in" (has provided the code)
            return this.$store.state.persons.currentContractor.id && !this.$route.query.guid;
        },
        getBackButtonComponentName() {
            return this.$store.state.persons.currentContractorAdmin.id
                ? "ContractorAdminCompanyList"
                : "CompanyAdminCompanies";
        },
    },

    methods: {
        toggle: function () {
            this.isMenuOpen = !this.isMenuOpen;
        },
    },
};
</script>
