import Vue from "vue";
import sanitizeHtml from "sanitize-html";
import axios from "axios";

var apiKey = "AIzaSyB2srSht6_aXZzLeloyaDljfRWNkVizXN8";
var options = {
    concurrentLimit: 20,
    // requestOptions: {
    // 	proxy: 'http://123.123.123.123:8080',
    // 	ca: fs.readFileSync('/usr/share/ca-certificates/company/company.crt'),
    // },
};

function protectAgainstXss(html) {
    // safeguard against XSS attacks.
    // remove all tags in html except for the ones that we need.
    // https://www.npmjs.com/package/sanitize-html
    return sanitizeHtml(html, {
        allowedTags: [
            "br",
            "div",
            "p",
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "h6",
            "br",
            "span",
            "strong",
            "b",
            "i",
            "ul",
            "ol",
            "li",
        ],
        allowedAttributes: {
            div: ["class"],
        },
        allowedClasses: {
            div: ["h1", "h2", "h3", "h4", "h5", "mt-4", "uppercase"],
            span: ["uppercase"],
        },
        allowedIframeHostnames: [],
    });
}

const googleTranslate = require("google-translate")(apiKey, options);

export default class ButTranslator {
    consoleCss = "background-color: #228822; color: #eee; border-radius: 3px; padding: 0 5px";

    /**
	 *
	 * How to save the new texts:
	 * Run ButTranslator.saveJson() in the Dev console to save the updated json (including new entries) of the current locale.
	 *
	 * How to sync and translate all missing keys in all languages?
	 * Run syncAndTranslateLocales() in the Dev console.
	 *
	 * How to instanciate ButTranslator:
	 * Options contain:
	 * const translatorOptions = {
	 		jsonClientPath: "./i18n/CatLocalisation-${locale}",
			jsonButPath: "./i18n/BUTLocalisation-${locale}",
			butFallbackLanguage: "en",
			keyNotFoundShowConsoleWarning: true,
			keyNotFoundShowKeyTooltip: true,
			keyNotFoundShowAsterix: true,
			showLocale: true,
	 *	};
	 *
	 */

    options = {};

    locales = {};

    currentLocaleObject = {};

    currentLocale = "";

    newEntries = {};

    NOT_FOUND_STRING = "¿?";

    inBrowser = typeof window !== "undefined";
    UA = this.inBrowser && window.navigator.userAgent.toLowerCase();
    isIE = this.UA && /msie|trident/.test(this.UA);

    constructor(options) {
        this.options = options;

        Vue.use(this.directive, this.options);
        window.ButTranslator = this;

        //makes $trans globally available in all Vue components
        Vue.prototype.$trans = this;
    }

    //Vue directive
    get directive() {
        return {
            install(Vue) {
                init();

                function init() {
                    //makes the directive v-trans globally available in all tags (in Vue templates)
                    Vue.directive("trans", {
                        //called only once, when the directive is first bound to the element. This is where you can do one-time setup work.
                        bind: bindTransDirective,
                        // Called after the containing component’s VNode has updated, but possibly before its children have updated.
                        // The directive’s value may or may not have changed, but you can skip unnecessary updates by comparing the binding’s
                        // current and old values (see below on hook arguments).
                        //update: updateTransDirective,
                        componentUpdated: updateTransDirective,

                        //called only once, when the directive is unbound from the element.
                        //unbind: unbindTransDirective
                    });
                }

                function bindTransDirective(el, binding, vnode) {
                    updateTransDirective(el, binding, vnode);
                }

                function updateTransDirective(el, binding, vnode) {
                    let key = binding.value;
                    let replaceFunc = null;

                    if (typeof key === "object") {
                        replaceFunc = key.func;
                        key = key.key;
                    }

                    if (!key) {
                        if (vnode.children) {
                            if (!vnode.children[0].elm) {
                                key = vnode.children[0].text;
                            } else {
                                key =
                                    vnode.children[0].elm.nodeName === "#text"
                                        ? vnode.children[0].elm.data
                                        : vnode.children[0].elm.innerHTML;
                            }
                            if (vnode.children.length > 1) {
                                console.info(
                                    "BUTTranslator",
                                    `HTML tags found in key ${vnode.elm.innerHTML}. Only first HTML element (${key}) will be used as key!`
                                );
                            }
                        }
                    }

                    const found = window.ButTranslator.translateElement(el, key, replaceFunc);

                    if (!found) {
                        let origHtml = "";
                        if (vnode.children) {
                            vnode.children.forEach((child) => {
                                origHtml += child.elm.nodeName === "#text" ? child.elm.data : child.elm.outerHTML;
                            });
                        } else {
                            origHtml += vnode.elm.nodeName === "#text" ? vnode.elm.data : vnode.elm.innerHTML;
                        }

                        //if (!this.isIE) {
                        origHtml = protectAgainstXss(origHtml);
                        //}
                        el.innerHTML = origHtml;
                    }
                }
            },
        };
    }

    async loadLocale(newLocale) {
        if (!this.locales[newLocale]) {
            const path = this.options.jsonPath.replace("${locale}", newLocale);
            const file = await axios.get(path);

            let mergedData = {};
            //convert string value to object
            for (let key in file.data) {
                if (file.data.hasOwnProperty(key)) {
                    mergedData[key] = { html: file.data[key], source: "client" };
                }
            }

            this.locales[newLocale] = mergedData;
        }
        this.currentLocaleObject = this.locales[newLocale];
        console.info("%cBUTTranslator", this.consoleCss, "Locale loaded:", newLocale);

        this.currentLocale = newLocale;

        return this.currentLocaleObject;
    }

    hasEntry(key) {
        if (this.locales && this.currentLocaleObject) {
            return !!this.currentLocaleObject[key];
        } else {
            return false;
        }
    }

    getEntry(key) {
        if (this.locales && this.currentLocaleObject) {
            // console.log(this.currentLocaleObject);
            return this.currentLocaleObject[key];
        } else {
            return null;
        }
    }

    getTranslation(key, defaultValue) {
        return this.getTranslatedHtml(key, defaultValue);
    }

    getTranslatedHtml(key, defaultHtml) {
        if (!this.currentLocale) return;

        const entry = this.getEntry(key);
        let prefix = "";
        let newHtml = "";
        if (!entry) {
            if (defaultHtml.substr(0, this.NOT_FOUND_STRING.length) === this.NOT_FOUND_STRING) {
                defaultHtml = defaultHtml.substr(this.NOT_FOUND_STRING.length);
            }

            this.newEntries[key] = defaultHtml;

            if (this.options.keyNotFoundShowConsoleWarning) {
                // console.warn('%cBUTTranslator', this.consoleCss, 'Key not found:', key);
            }
            if (this.options.keyNotFoundShowAsterix) {
                prefix = this.NOT_FOUND_STRING;
            }

            newHtml = defaultHtml;
        } else {
            newHtml = entry.html;
        }

        if (this.options.showLocale && entry) {
            return `${this.currentLocale} ${prefix}${newHtml}`;
        } else {
            return `${prefix}${newHtml}`;
        }
    }

    translateElement(el, key, replaceFunction = null) {
        if (!this.currentLocale) return;

        let html = this.getTranslatedHtml(key, el.innerHTML);

        if (replaceFunction) {
            html = replaceFunction(html);
        }
        html = protectAgainstXss(html);
        el.innerHTML = html;
        return html;
    }

    sortCaseInsensitive(a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    }

    /**
     * Run this command in the console (ButTranslator.saveJson()) to create a JSON file of the current locale.
     */
    saveJson() {
        let json = {};
        for (let [key, value] of Object.entries(this.locales[this.currentLocale])) {
            json[key] = value.html;
        }

        //merge json with new entries object
        json = Object.assign({}, json, this.newEntries);

        const orderedJson = {};
        Object.keys(json)
            .sort(this.sortCaseInsensitive)
            .forEach(function (key) {
                orderedJson[key] = json[key];
            });

        //return json
        const jsonString = JSON.stringify(orderedJson, null, "\t");

        let bl = new Blob([jsonString]);
        let a = document.createElement("a");
        a.href = URL.createObjectURL(bl);

        //file name
        a.download = this.currentLocale + ".json";

        a.hidden = true;
        document.body.appendChild(a);
        a.innerHTML = "someinnerhtml";
        a.click();

        console.info(
            "%cBUTTranslator",
            this.consoleCss,
            "New entries added to Json:",
            Object.keys(this.newEntries).length,
            "Total entries:",
            Object.keys(orderedJson).length
        );
        if (Object.keys(this.newEntries).length > 0) {
            console.log("New entries:");
            console.table(this.newEntries);
        }
        return json;
    }

    /**
     * Iterate all locales in memory and translate all existing keys too all other locales using Google Translate.
     */
    syncAndTranslateLocales() {
        const defaultLocale = "en";
        const syncedEntries = {};
        const locales = Object.keys(this.locales);
        const newEntriesPerLocale = {};
        let nrOfTranslationsToDo = 0;

        //clear new entries, because we will fill them with new entries from an other language
        this.newEntries = {};

        if (locales.length < 2) {
            console.warn(
                "No syncing possible - You need at least 2 locales loaded. Make sure to load all languages you want to sync!"
            );
            return;
        }

        //1. iterate all loaded locales to merge all keys
        locales.forEach((locale) => {
            Object.keys(this.locales[locale]).forEach((key) => {
                //console.log("Gert: entry:", locale, key);
                if (!syncedEntries[key]) {
                    syncedEntries[key] = {};
                }
                syncedEntries[key][locale] = this.locales[locale][key].html;
            });
        });

        console.table(syncedEntries);
        console.log("Translating ...");

        //2. iterate the merged keys to fill the empty locales with the value of the default locale or an other locale
        Object.keys(syncedEntries).forEach((key) => {
            const entryObj = syncedEntries[key];
            locales.forEach((localeToFix) => {
                //empty value? [yes]
                if (typeof entryObj[localeToFix] === "undefined") {
                    entryObj[localeToFix] = googleTranslate.translate();

                    //do we have a value for the default locale? [yes]
                    if (typeof entryObj[defaultLocale] !== "undefined") {
                        //take the value of the default locale
                        //entryObj[localeToFix] = entryObj[defaultLocale];
                        nrOfTranslationsToDo++;
                        googleTranslate.translate(
                            entryObj[defaultLocale],
                            defaultLocale,
                            localeToFix,
                            (err, translation) => {
                                if (!err) {
                                    entryObj[localeToFix] = translation.translatedText;
                                    newEntriesPerLocale[key] = {
                                        original: translation.originalText,
                                        translated: translation.translatedText,
                                    };
                                    this.locales[localeToFix][key] = {
                                        html: translation.translatedText,
                                        source: "client",
                                    };
                                } else {
                                    return "Translation Error: " + JSON.stringify(err);
                                }
                                nrOfTranslationsToDo--;
                                if (nrOfTranslationsToDo === 0) {
                                    this.syncDone(newEntriesPerLocale);
                                }
                            }
                        );
                    } else {
                        //iterate other locales to find existing entry
                        Object.keys(entryObj).forEach((existingLocale) => {
                            //value found and noy yet filled in?
                            if (
                                typeof entryObj[localeToFix] === "undefined" &&
                                typeof entryObj[existingLocale] !== "undefined"
                            ) {
                                //entryObj[localeToFix] = entryObj[existingLocale];
                                nrOfTranslationsToDo++;
                                googleTranslate.translate(
                                    entryObj[existingLocale],
                                    existingLocale,
                                    localeToFix,
                                    (err, translation) => {
                                        if (!err) {
                                            entryObj[localeToFix] = translation.translatedText;
                                            newEntriesPerLocale[key] = {
                                                original: translation.originalText,
                                                translated: translation.translatedText,
                                            };
                                            this.locales[localeToFix][key] = {
                                                html: translation.translatedText,
                                                source: "client",
                                            };
                                        } else {
                                            return "Translation Error: " + JSON.stringify(err);
                                        }
                                        nrOfTranslationsToDo--;
                                        if (nrOfTranslationsToDo === 0) {
                                            this.syncDone(newEntriesPerLocale);
                                        }
                                    }
                                );
                            }
                        });
                    }
                }
            });
        });
    }

    syncDone(newEntriesPerLocale) {
        console.info("%cBUTTranslator", this.consoleCss, "New entries per locale:");
        console.table(newEntriesPerLocale);
        console.log("To save the entries, use 'ButTranslator.saveJson()' as normal.");
    }
}
