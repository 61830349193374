//import axios from "axios";

// import {ACTION_GET_PERSON_BY_GUID2} from "../store/constants";
// import {apolloProvider} from "../graphql/apollo";
// import {GET_PERSON_BY_GUID2} from "../graphql/queries/Person";

import { SAVE_RESULT, UPDATE_LEARNING_TRACK_RESULT } from "../graphql/queries/LearningTrack";

export default class ButScormServerWrapper {
    /*
					attemptsMade: this.SCOResult.attempts,
	*/

    options = {
        SCOResult: null,
        SCOResults: null,
        suspendData: null,
        status: null,
        scoId: null,
        firstName: "",
        lastName: "",
        lesson_location: "",
        minScore: 0,
        maxScore: NaN,
        rawScore: NaN,
        learningTrackId: null,
        $apollo: null,
    };

    _stillSaving = false;

    destroying = false;
    _score_timeout_id = null;
    _findAPITries = 0;
    g_dtmInitialized = new Date(); // will be adjusted after initialize

    scoFinishedCallback = null;
    scoErrorCallback = null;

    constructor(options_, scoFinishedCallback_, scoErrorCallback_) {
        if (options_) {
            this.options = options_;
        }

        this.scoFinishedCallback = scoFinishedCallback_;
        this.scoErrorCallback = scoErrorCallback_;

        window.API = this;
    }

    destroy() {
        this.destroying = true;
        this.scoFinishedCallback = null;
        this.scoErrorCallback = null;
        window.API = null;
    }

    /**
     * private functions
     */

    /*******************************************************************************
     **
     ** Function: doLMSInitialize()
     ** Inputs:  None
     ** Return:  CMIBoolean true if the initialization was successful, or
     **          CMIBoolean false if the initialization failed.
     **
     ** Description:
     ** Initialize communication with LMS by calling the LMSInitialize
     ** function which will be implemented by the LMS.
     **
     *******************************************************************************/
    LMSInitialize() {
        this.g_dtmInitialized = new Date();
        console.log("LMS INIT");
        return true;
    }

    /*******************************************************************************
     **
     ** Function doLMSFinish()
     ** Inputs:  None
     ** Return:  CMIBoolean true if successful
     **          CMIBoolean false if failed.
     **
     ** Description:
     ** Close communication with LMS by calling the LMSFinish
     ** function which will be implemented by the LMS
     **
     *******************************************************************************/
    LMSFinish() {
        console.log("LMSFinish", this);

        // the sco is finished
        if (this.scoFinishedCallback) {
            this.scoFinishedCallback();
        }
        /*
		var ajaxObj = new Object();
		ajaxObj.type = "POST";
		ajaxObj.async = false;
		ajaxObj.dataType = "json";
		ajaxObj.url = "{exp:but_mini_lms:Get_ACTION_url method='LMSFinish'}";
		ajaxObj.data = { result_entry_id: _result_entry_id, status:_status, percent: Math.floor((_rawScore/_maxScore)*100) };

		var ajaxRequest = $.ajax(ajaxObj);

		ajaxRequest.done(function( msg ) {
			console.log("_LMSFinish done: " + msg.success + " : " + msg.type);
		});

		ajaxRequest.fail(function(jqXHR, textStatus, errorThrown) {
			console.log("_LMSFinish fail: " + jqXHR.responseText);
		});
		*/
        return true;
    }

    /*******************************************************************************
     **
     ** Function doLMSGetValue(name)
     ** Inputs:  name - string representing the cmi data model defined category or
     **             element (e.g. cmi.core.student_id)
     ** Return:  The value presently assigned by the LMS to the cmi data model
     **       element defined by the element or category identified by the name
     **       input value.
     **
     ** Description:
     ** Wraps the call to the LMS LMSGetValue method
     **
     *******************************************************************************/
    LMSGetValue(name) {
        switch (name) {
            case "cmi.core.lesson_mode":
                /*
				 var MODE_NORMAL = 1;
				 var MODE_BROWSE = 2;
				 var MODE_REVIEW = 3;
				 */
                return 1;
            case "cmi.core.lesson_status":
                /*
				 *var LESSON_STATUS_PASSED        = 1;	//the user completed the content with a score sufficient to pass
				 var LESSON_STATUS_COMPLETED     = 2;	//the user completed the content
				 *var LESSON_STATUS_FAILED        = 3;	//the user completed the content but his/her score was not sufficient to pass
				 *var LESSON_STATUS_INCOMPLETE    = 4;	//the user began the content but did not complete it
				 var LESSON_STATUS_BROWSED       = 5;	//the user looked at the content but was not making a recorded attempt at it
				 *var LESSON_STATUS_NOT_ATTEMPTED = 6;	//the user has not started the content
				 */

                if (this.options.status === "not-attempted") {
                    console.log("GET " + name + " return: 6");
                    return 6;
                } else if (this.options.status === "incomplete") {
                    console.log("GET " + name + " return: 4");
                    return 4;
                } else if (this.options.status === "failed") {
                    console.log("GET " + name + " return: 3");
                    return 3;
                } else if (this.options.status === "passed") {
                    console.log("GET " + name + " return: 1");
                    return 1;
                } else if (this.options.status === "completed") {
                    console.log("GET " + name + " return: 2");
                    return 2;
                } else if (this.options.status === "browsed") {
                    console.log("GET " + name + " return: 5");
                    return 5;
                }
                console.log("GET " + name + " return: 6**: " + this.options.status);
                return 6;

            case "cmi.suspend_data":
                console.log("GET suspendData: " + this.options.suspendData);
                return this.options.suspendData;
            case "cmi.core.student_name":
                console.log("GET cmi.core.student_name: " + this.options.firstName + " " + this.options.lastName);
                return this.options.lastName + ", " + this.options.firstName;
            default:
                console.log("unknown GET value name: " + name);
                return null;
        }
        //return null;
    }

    _trySavingScore() {
        if (!isNaN(this.options.maxScore) && !isNaN(this.options.minScore) && !isNaN(this.options.rawScore)) {
            clearTimeout(this._score_timeout_id);
            this._score_timeout_id = setTimeout(() => {
                this.saveScoreAfterTimeout();
            }, 100);
        }
    }

    async saveScoreAfterTimeout() {
        console.log("saveScoreAfterTimeout: ", this.options);

        if (this.destroying) {
            return;
        }

        if (this._stillSaving) {
            return;
        }

        let percent = Math.round((this.options.rawScore / this.options.maxScore) * 100);

        console.log("percent:", percent);

        const statusCategoriesSlugIDKeyValuePairs = {
            "not-attempted": 187,
            incomplete: 188,
            passed: 189,
            failed: 190,
            completed: 191,
            "in-progress": 192,
            browsed: 193,
        };

        const variables = {
            id: this.options.SCOResult.id,
            scoStatus: statusCategoriesSlugIDKeyValuePairs[this.options.status],
            suspendData: this.options.suspendData,
            sco: [parseInt(this.options.scoId)],
            score: percent,
        };

        this._stillSaving = true;

        let noId = false;
        if (isNaN(this.options.SCOResult.id)) {
            console.log("there is no SCOResult.id yet");
            noId = true;
        }

        console.log("VAR TO UPSERT", variables);

        await this.options.apollo
            .mutate({
                mutation: SAVE_RESULT,
                variables: variables,
                fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                // pollInterval: 1000,
            })
            .then(async (e) => {
                console.log("saveScore THEN", e.data);
                if (!isNaN(e.data.save_scoResult_scoResult_Entry.id)) {
                    this.options.status = e.data.save_scoResult_scoResult_Entry.scoStatus[0].slug;
                    this.options.SCOResult.id = e.data.save_scoResult_scoResult_Entry.id;

                    if (noId) {
                        // we created a new SCOResult id, add it to the list of LearningTrackResult
                        this.options.SCOResults.push(parseInt(this.options.SCOResult.id));
                        console.log(
                            "We upserted a NEW SCOResult, so update the learningTrack to include this SCOResult id"
                        );
                        await this.updateLearningTrack();
                    } else {
                        this._stillSaving = false;
                    }

                    /*if(this.options.status !== "not-attempted" &&
					this.options.status !== "incomplete" &&
					this.options.status !== "in-progress")
				{
					// the sco is finished
					this.scoFinishedCallback();
				}*/
                }
            })
            .catch((e) => {
                console.log("saveScore Catch", e);
                const error = this.$trans.hasEntry(e.message) ? this.$trans.getEntry(e.message).html : e.message;
                this.scoErrorCallback(error);
                console.log(error);
                this._stillSaving = false;
            })
            .finally(() => {});
    }

    async updateLearningTrack() {
        await this.options.apollo
            .mutate({
                mutation: UPDATE_LEARNING_TRACK_RESULT,
                variables: { id: this.options.learningTrackId, scoResults: this.options.SCOResults },
                fetchPolicy: "no-cache", //warning: only 'no-cache' allows mutations!
                // pollInterval: 1000,
            })
            .then((e) => {
                console.log("updateLearningTrack THEN", e.data);
                this._stillSaving = false;
            })
            .catch((e) => {
                console.log("updateLearningTrack Catch", e);
                const error = this.$trans.hasEntry(e.message) ? this.$trans.getEntry(e.message).html : e.message;
                console.log(error);
                this.scoErrorCallback(error);
            })
            .finally(() => {
                this._stillSaving = false;
            });
    }

    /*******************************************************************************
     **
     ** Function doLMSSetValue(name, value)
     ** Inputs:  name -string representing the data model defined category or element
     **          value -the value that the named element or category will be assigned
     ** Return:  CMIBoolean true if successful
     **          CMIBoolean false if failed.
     **
     ** Description:
     ** Wraps the call to the LMS LMSSetValue function
     **
     *******************************************************************************/
    LMSSetValue(name, value) {
        console.log("LMSSET: " + name + " : " + value);

        switch (name) {
            case "cmi.core.score.raw":
                console.log("setting score.raw to: " + value);
                this.options.rawScore = parseFloat(value);
                this._trySavingScore();
                return true;
            case "cmi.core.score.max":
                console.log("setting score.max to: " + value);
                this.options.maxScore = parseFloat(value);
                this._trySavingScore();
                return true;
            case "cmi.core.score.min":
                console.log("setting score.min to: " + value);
                this.options.minScore = parseFloat(value);
                this._trySavingScore();
                return true;
            case "cmi.core.session_time":
                /*
				 0000:00:00.11
				 */
                return true;
            case "cmi.core.lesson_status":
                /*
				 incomplete
				 failed
				 */
                this.options.status = value;
                this._trySavingScore();
                return true;
            case "cmi.suspend_data":
                this.options.suspendData = value;
                this._trySavingScore();
                return true;
            case "cmi.core.lesson_location":
                this.options.lesson_location = value;
                this._trySavingScore();
                return true;
            case "cmi.core.exit":
                /*
				 "suspend"
				 */
                this._trySavingScore();
                return true;
            default:
                console.log("unknown set value name: " + name);
        }
        return null;
    }

    /*******************************************************************************
     **
     ** Function doLMSCommit()
     ** Inputs:  None
     ** Return:  None
     **
     ** Description:
     ** Call the LMSCommit function
     **
     *******************************************************************************/
    LMSCommit() {
        console.log("LMSCommit");
    }

    /*******************************************************************************
     **
     ** Function doLMSGetLastError()
     ** Inputs:  None
     ** Return:  The error code that was set by the last LMS function call
     **
     ** Description:
     ** Call the LMSGetLastError function
     **
     *******************************************************************************/
    LMSGetLastError() {
        /*
		 var NO_ERROR = 0;
		 var ERROR_LMS = 1;
		 var ERROR_INVALID_PREFERENCE = 2;
		 var ERROR_INVALID_NUMBER = 3;
		 var ERROR_INVALID_ID = 4;
		 var ERROR_INVALID_STATUS = 5;
		 var ERROR_INVALID_RESPONSE = 6;
		 var ERROR_NOT_LOADED = 7;
		 var ERROR_INVALID_INTERACTION_RESPONSE = 8;
		 */
        return 0;
    }

    /*******************************************************************************
     **
     ** Function doLMSGetErrorString(errorCode)
     ** Inputs:  errorCode - Error Code
     ** Return:  The textual description that corresponds to the input error code
     **
     ** Description:
     ** Call the LMSGetErrorString function
     **
     ********************************************************************************/
    LMSGetErrorString(errorCode) {
        console.log(errorCode);
        return "error String";
    }

    /*******************************************************************************
     **
     ** Function doLMSGetDiagnostic(errorCode)
     ** Inputs:  errorCode - Error Code(integer format), or null
     ** Return:  The vendor specific textual description that corresponds to the
     **          input error code
     **
     ** Description:
     ** Call the LMSGetDiagnostic function
     **
     *******************************************************************************/
    LMSGetDiagnostic(errorCode) {
        console.log(errorCode);
        return "BUT mini LMS, all okay!";
    }

    /*******************************************************************************
     **
     ** Function LMSIsInitialized()
     ** Inputs:  none
     ** Return:  true if the LMS API is currently initialized, otherwise false
     **
     ** Description:
     ** Determines if the LMS API is currently initialized or not.
     **
     *******************************************************************************/
    LMSIsInitialized() {
        // there is no direct method for determining if the LMS API is initialized
        // for example an LMSIsInitialized function defined on the API so we'll try
        // a simple LMSGetValue and trap for the LMS Not Initialized Error
        return true;
    }

    /*******************************************************************************
     **
     ** Function ErrorHandler()
     ** Inputs:  None
     ** Return:  The current value of the LMS Error Code
     **
     ** Description:
     ** Determines if an error was encountered by the previous API call
     ** and if so, displays a message to the user.  If the error code
     ** has associated text it is also displayed.
     **
     *******************************************************************************/
    ErrorHandler() {
        return "0";
    }

    /******************************************************************************
     **
     ** Function getAPIHandle()
     ** Inputs:  None
     ** Return:  value contained by APIHandle
     **
     ** Description:
     ** Returns the handle to API object if it was previously set,
     ** otherwise it returns null
     **
     *******************************************************************************/
    getAPIHandle() {
        if (this.apiHandle == null) {
            this.apiHandle = this.getAPI();
        }

        return this.apiHandle;
    }

    /*******************************************************************************
     **
     ** Function findAPI(win)
     ** Inputs:  win - a Window Object
     ** Return:  If an API object is found, it's returned, otherwise null is returned
     **
     ** Description:
     ** This function looks for an object named API in parent and opener windows
     **
     *******************************************************************************/
    findAPI(win) {
        while (win.API == null && win.parent != null && win.parent != win) {
            this._findAPITries++;
            // Note: 7 is an arbitrary number, but should be more than sufficient
            if (this._findAPITries > 7) {
                alert("Error finding API -- too deeply nested.");
                return null;
            }

            win = win.parent;
        }
        return win.API;
    }

    /*******************************************************************************
     **
     ** Function getAPI()
     ** Inputs:  none
     ** Return:  If an API object is found, it's returned, otherwise null is returned
     **
     ** Description:
     ** This function looks for an object named API, first in the current window's
     ** frame hierarchy and then, if necessary, in the current window's opener window
     ** hierarchy (if there is an opener window).
     **
     *******************************************************************************/
    getAPI() {
        var theAPI = this.findAPI(window);
        if (theAPI == null && window.opener != null && typeof window.opener != "undefined") {
            theAPI = this.findAPI(window.opener);
        }
        if (theAPI == null) {
            //if (_Debug == true){
            alert("Unable to find an API adapter");
            //}
        }
        return theAPI;
    }

    SCORM_GetLessonMode() {
        /*
		 var MODE_NORMAL = 1;
		 var MODE_BROWSE = 2;
		 var MODE_REVIEW = 3;
		 */
        return 1;
    }

    /*******************************************************************************
     **
     ** Function MillisecondsToCMIDuration()
     ** Return:  Convert duration from milliseconds to 0000:00:00.00 format
     **
     *******************************************************************************/
    _MillisecondsToCMIDuration(n) {
        var hms = "";
        var dtm = new Date();
        dtm.setTime(n);
        var h = "000" + Math.floor(n / 3600000);
        var m = "0" + dtm.getMinutes();
        var s = "0" + dtm.getSeconds();
        var cs = "0" + Math.round(dtm.getMilliseconds() / 10);
        hms = h.substr(h.length - 4) + ":" + m.substr(m.length - 2) + ":";
        hms += s.substr(s.length - 2) + "." + cs.substr(cs.length - 2);
        return hms;
    }
}
