<template>
    <div>
        <back-button />
        <div class="container-70">
            <h1 class="text-center h3 mb-6" v-trans="'check-in'">Meld je aan</h1>

            <router-link
                v-for="visitType in selectedVisitTypes"
                :key="visitType.slug"
                :to="{ name: 'onsite-checkIn', params: { type: visitType.id, guid: '-' } }"
                class="flex btn btn--primary btn--lg btn--icon w-full mb-5 text-left"
                tag="button"
            >
                <component :is="'Icon_' + visitType.slug.replaceAll('-', '_')" class="left-0 ml-4" />

                <span class="ml-10" v-trans="visitType.i18n">{{ visitType.i18n }}</span>
            </router-link>

            <router-link
                :to="{ name: 'onsite-scan' }"
                class="flex btn btn--primary btn--lg btn--icon w-full mb-5 text-left"
                tag="button"
            >
                <q-r-code-icon class="left-0 ml-4" />
                <span class="ml-10" v-trans="'badge-met-qr-code'">Badge met QR-code</span>
            </router-link>
        </div>
    </div>
</template>
<script>
import BackButton from "../../components/BackButton";
import QRCodeIcon from "vue-material-design-icons/Qrcode";
import TruckIcon from "vue-material-design-icons/Truck";
import OfficeIcon from "vue-material-design-icons/OfficeBuilding";
import FactoryIcon from "vue-material-design-icons/Factory";
import AccountHardHat from "vue-material-design-icons/AccountHardHat";

import { GET_ALL_VISITOR_TYPES } from "@/graphql/queries/Visit";

export default {
    name: "VisitTypeSelection",
    components: {
        BackButton,
        QRCodeIcon,
        Icon_truck_driver: TruckIcon,
        Icon_visitor_office: OfficeIcon,
        Icon_visitor_factory: FactoryIcon,
        Icon_contractor: AccountHardHat,
    },
    mounted() {
        if (
            !this.$store.state.visit.onsiteSelection ||
            this.$store.state.visit.onsiteSelection.length < 2 ||
            !localStorage.onsiteselectionV3
        ) {
            this.$router.push({ name: "onsite" });
        }
    },
    apollo: {
        visitTypes() {
            return {
                query: GET_ALL_VISITOR_TYPES,
                fetchPolicy: "cache-and-network",
                variables() {
                    return {};
                },
                update(data) {
                    /**
                     * example: {
                     *     "id": "114",
                     *     "slug": "visitor-office",
                     *     "title": "Visitor Office",
                     * }
                     */
                    return data.categories || [];
                },
                watchLoading(isLoading) {
                    this.isLoading = isLoading;
                },
                error(e) {
                    if (e.networkError && parseInt(e.networkError.response.status) === 401) {
                        this.errorMsg = this.$trans.getTranslation("not-authorized-relogin");
                    } else {
                        this.errorMsg = JSON.stringify(e);
                    }
                },
            };
        },
    },
    computed: {
        selectedVisitTypes() {
            //get visit types that are enabled (value = 1)
            //console.log("selectedVisitTypes", this.$store.state.visit?.onsiteSelection);
            return (
                this.$store.state.visit?.onsiteSelection
                    ?.filter((v) => v.value)
                    .map((v) => {
                        return {
                            ...v,
                            i18n: `visit-type-${v.slug}`,
                            id: this.visitTypes?.find((vt) => vt.slug === v.slug).id,
                        };
                    }) || []
            );
        },
    },
};
</script>
