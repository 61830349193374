<template>
    <div class="relative">
        <div class="flex justify-between">
            <h3 class="page-title">
                <router-link :to="{ name: 'onlineAdmin' }" v-trans="'admin'"> Admin </router-link>
            </h3>
            <button @click="isMenuOpen = !isMenuOpen" class="page-menu-icon md:hidden">
                <MenuIcon v-if="!isMenuOpen" />
                <CloseIcon v-if="isMenuOpen" />
            </button>
        </div>

        <div class="flex">
            <ul :class="{ 'is-open': isMenuOpen }" class="page-menu absolute md:static w-full md:w-3/12 xl:w-2/12">
                <li>
                    <router-link
                        :to="{ name: 'CompanyAdminCompaniesList' }"
                        class="sidemenu-item"
                        v-trans="'companies'"
                    >
                        Bedrijven
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'CompanyAdminContractorList' }" class="sidemenu-item" v-trans="'persons'">
                        Personen
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{ name: 'CompanyAdminVisitingPersons' }"
                        class="sidemenu-item"
                        v-if="$store.getters.isPortalAdmin || $store.getters.isCompanyAdmin"
                        v-trans="'nu-aanwezig'"
                    >
                        Nu aanwezig
                    </router-link>
                    <span class="sidemenu-item opacity-50" v-trans="'nu-aanwezig'" :title="noRightsLabel" v-else
                        >Nu aanwezig</span
                    >
                </li>
                <li>
                    <router-link
                        :to="{ name: 'CompanyAdminVisitLogs' }"
                        class="sidemenu-item"
                        v-if="$store.getters.isPortalAdmin"
                        v-trans="'bezoekerslijst'"
                    >
                        Bezoekerslijst
                    </router-link>
                    <span class="sidemenu-item opacity-50" v-trans="'bezoekerslijst'" :title="noRightsLabel" v-else
                        >Bezoekerslijst</span
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'CompanyAdminUsers' }" class="sidemenu-item" v-trans="'admins'">
                        Administrators
                    </router-link>
                </li>
                <li>
                    <router-link
                        :to="{ name: 'QRCodeHardwareScanner' }"
                        class="sidemenu-item"
                        v-if="!$store.getters.isViewer"
                    >
                        QR-Code scanners
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'CompanyAdminProfile' }" class="sidemenu-item" v-trans="'my-profile'">
                        Mijn profiel
                    </router-link>
                </li>
            </ul>
            <transition mode="out-in" name="fade-in" appear :key="$route.fullPath">
                <transition mode="out-in" name="fade-in" appear>
                    <router-view class="md:ml-6 bg-gray-100 p-6 w-full md:w-9/12 xl:w-10/12 rounded" />
                </transition>
            </transition>
        </div>

        <login-craft-user @onLoginCompleted="onLoginCraftUserCompleted" ref="login" v-if="!isCompanyAdminLoggedIn" />
    </div>
</template>

<script>
import MenuIcon from "vue-material-design-icons/Menu.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import LoginCraftUser from "../../components/LoginCraftUser";
//import axios from "axios";
import { ACTION_INIT_QR_CODE_SCANNERS } from "@/store/constants";

export default {
    name: "CompanyAdminPortal",
    data() {
        return {
            isMenuOpen: false,
        };
    },

    components: {
        LoginCraftUser,
        // LoginCraftUser,
        MenuIcon,
        CloseIcon,
    },
    watch: {
        $route() {
            this.isMenuOpen = false;
        },
    },
    computed: {
        isCompanyAdminLoggedIn() {
            return this.$store.state.persons.currentCompanyAdmin.id;
        },
        noRightsLabel() {
            return this.$trans.getTranslation("Not authorized", "Not authorized");
        },
    },

    methods: {
        toggle: function () {
            this.isMenuOpen = !this.isMenuOpen;
        },

        onLoginCraftUserCompleted(user) {
            console.log("onLoginCraftUserCompleted", user);
            this.$store.dispatch(ACTION_INIT_QR_CODE_SCANNERS);
        },
    },
};
</script>
